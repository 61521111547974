import { POSTULANTS } from '../../../api/endpoints';
import { requestAuth } from '../../../api/requests/tuitionProcess';
import { PostulationDocumentationReview } from '../types';

export function getPostulationDocumentsReview(postulationDetailId: number) {
  let url = POSTULANTS.GET_DOCUMENTS(postulationDetailId);
  return requestAuth<PostulationDocumentationReview>(url, {
    method: 'GET',
  });
}
