import AcademicOffersCreate from '../academicOffers/AcademicOffersCreate';
import AcademicOffersEdit from '../academicOffers/AcademicOffersEdit';
import AcademicOffersList from '../academicOffers/AcademicOffersList';
import AcademicOffersView from '../academicOffers/AcademicOffersView';
import { AuthorizedLayout } from '../layouts/AuthorizedLayout';
import NotAuthorizedLayout from '../layouts/NotAuthorizedLayout';
import Maintainers from '../maintainers/Maintainers';
import PeriodRecordCreate from '../maintainers/periods/PeriodRecordCreate';
import PeriodRecordEdit from '../maintainers/periods/PeriodRecordEdit';
import ScheduleRecordCreate from '../maintainers/schedule/ScheduleRecordCreate';
import ScheduleRecordEdit from '../maintainers/schedule/ScheduleRecordEdit';
import StudyPlanRecordCreate from '../studyPlans/StudyPlanRecordCreate';
import StudyPlanRecordEdit from '../studyPlans/StudyPlanRecordEdit';
import StudyPlansList from '../studyPlans/StudyPlansList';
import CurriculumCreate from '../studyPlans/curriculum/views/CurriculumCreate';
import CurriculumCreateMention from '../studyPlans/curriculum/views/CurriculumCreateMention';
import CurriculumEdit from '../studyPlans/curriculum/views/CurriculumEdit';
import CurriculumEditMetion from '../studyPlans/curriculum/views/CurriculumEditMetion';
import CurriculumListMention from '../studyPlans/curriculum/views/CurriculumListMention';
import CurriculumView from '../studyPlans/curriculum/views/CurriculumView';
import CurriculumViewMetion from '../studyPlans/curriculum/views/CurriculumViewMetion';
import VersionRecordCreate from '../studyPlans/version/VersionRecordCreate';
import TuitionProcessOnSite from '../views/OnSiteEnrollment/TuitionProcessOnSite';
import TuitionProcessSearchStudent from '../views/OnSiteEnrollment/TuitionProcessSearchStudent';
import { SearchStudentOnSite } from '../views/OnSiteEnrollment/onSite/SearchStudentOnSite';
import { PermissionName } from '../types/Auth';
import AcademicRecord from '../views/AcademicRecord';
import AdmissionsList from '../views/Admissions/AdmissionsList';
import AdmissionsListWithTabs from '../views/Admissions/AdmissionsListWithTabs';
import AdmissionsPendingList from '../views/Admissions/AdmissionsPendingList';
import ChangePassword from '../views/ChangePassword/ChangePassword';
import ClosingPeriod from '../views/ClosingPeriod/ClosingPeriod';
import Configurations from '../views/Configurations/Configurations';
import UpdateCourseRegistration from '../views/CourseRegistration/CourseRegistration';
import DetailCourseRegistration from '../views/CourseRegistration/DetailCourseRegistration';
import CourseValidationInternal from '../views/CourseValidationInternal';
import CourseValidationInternalStudent from '../views/CourseValidationInternalStudent';
import CourseUpdate from '../views/CoursesMaintainer/CourseUpdate';
import CoursesMaintainer from '../views/CoursesMaintainer/CoursesMaintainer';
import CurriculumProgress from '../views/CurriculumProgress';
import CurriculumProgressStudent from '../views/CurriculumProgressStudent';
import GraduatesList from '../views/GraduatesList/GraduatesList';
import GraduatesListProcessMassive from '../views/GraduatesListProcessMassive/GraduatesListProcessMassive';
import GraduationProcessMasive from '../views/GraduationProcessMasive/GraduationProcessMasive';
import { GraduationProcessSingle } from '../views/GraduationProcessSingle/GraduationProcessSingle';
import GraduationProcessSinglePendingRequirerments from '../views/GraduationProcessSinglePendingRequirerments/GraduationProcessSinglePendingRequirerments';
import Login from '../views/Login/Login';
import PackagesList from '../views/Packages/PackagesList';
import PackagesPendingStudents from '../views/Packages/PackagesPendingStudents';
import PackagesView from '../views/Packages/PackagesView';
import SectionsAndPackagesList from '../views/PackagesMaintainer/SectionsAndPackagesList';
import SectionsAndPackagesView from '../views/PackagesMaintainer/SectionsAndPackagesView';
import Reports from '../views/Reports/Reports';
import SectionAttributesList from '../views/SectionAttributes/SectionAttributesList';
import SectionAttributesView from '../views/SectionAttributes/SectionAttributesView';
import SectionUpdate from '../views/SectionsMaintainer/SectionUpdate';
import SectionsMaintainer from '../views/SectionsMaintainer/SectionsMaintainer';
import CreateSectionForm from '../views/SectionsMaintainer/parts/CreateSectionForm';
import StudentStatus from '../views/StudentStatus/StudentStatus';
import StudentsDegree from '../views/StudentsDegree';
import StudentsDegreeStudent from '../views/StudentsDegreeStudent';
import StudentsPetitionsDetail from '../views/StudentsPetitionsDetail';
import StudentPetitions from '../views/StudentsPetitionsList';
import { StudentsPostCloseDetail } from '../views/StudentsPostCloseDetail/StudentsPostCloseDetail';
import { StudentsPostCloseSearch } from '../views/StudentsPostCloseSearch/StudentsPostCloseSearch';
import TeacherActivation from '../views/TeacherActivation/TeacherActivation';
import TuitionContinuityProcessDocumentReview from '../views/TuitionContinuityProcessDocumentReview';
import TuitionContinuityProcessDocumentReviewDetail from '../views/TuitionContinuityProcessDocumentReviewDetail';
import TuitionContinuityProcessDocumentReviewDocuments from '../views/TuitionContinuityProcessDocumentReviewDocuments';
import TuitionContinuityProcessManagement from '../views/TuitionContinuityProcessManagement/TuitionContinuityProcessManagement';
import TuitionContinuityProcessManagementDetail from '../views/TuitionContinuityProcessManagementDetail/TuitionContinuityProcessManagementDetail';
import TuitionContinuityTariffAndTuition from '../views/TuitionContinuityTariffAndTuition';
import { DocumentsGridCards } from '../views/documents/DocumentsGridCards';
import { DocumentsList } from '../views/documents/DocumentsList';
import PeriodEndReview from '../views/periodEndReview/PeriodEndReview';
import TariffAndTuition from '../views/tariffAndTuition/TariffAndTuition';
import PaymentHub from '../views/PaymentHub';

export enum PathsLayouts {
  auth = '/auth',
  maintainers = '/maintainers',
  studyPlans = '/study-plans',
  academicOffers = '/academic-offers',
  tuitionProcess = '/tuition-process',
  tariffAndTuiton = '/tariff-tuiton',
  admissions = '/admissions',
  documents = '/documents',
  packages = '/packages',
  packagesMaintainer = '/packages-maintainer',
  reports = '/reports',
  courseRegistration = '/course-registration',
  sectionAttributes = '/section-attributes',
  sectionsMaintainer = '/sections-maintainer',
  teacherActivation = '/teacher-activation',
  studentStatus = '/student-status',
  curriculumProgress = '/avance-curricular',
  closingPeriod = '/closing-period',
  coursesMaintainer = '/courses-maintainer',
  configurations = '/config',
  studentsPostClose = '/students-post-close',
  tuitionContinuityProcessManagement = '/tuition-continuity-process-management',
  graduationProcessMassive = '/graduation-process-massive',
  graduationProcessSingle = '/graduation-process-single',
  graduatesList = '/graduates-list',
  tuitionContinuityTariffAndTuition = '/tuition-continuity-tariff-tuition',
  tuitionContinuityDocumentReview = '/tuition-continuity-document-review',
  courseValidationInternal = '/convalidacion-interna',
  academicRecord = '/record-academico',
  paymentHub = '/centro-pagos',
  studentPetitions = '/student-petitions',
  studentsDegree = '/students-degree',
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
};

export type RoutesByLayout = {
  // this keys are the paths of every layout
  [key in PathsLayouts]?: {
    layoutComponent: () => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
    requiredPermissions?: PermissionName[];
    allPermissionsRequired?: boolean;
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
export const NOT_AUTHORIZED_ROUTES: RoutesByLayout = {
  [PathsLayouts.auth]: {
    layoutComponent: NotAuthorizedLayout,
    defaultPath: '/login',
    views: [
      {
        path: '/login',
        component: Login,
      },
      {
        path: '/activate-account',
        component: ChangePassword,
      },
      {
        path: '/reset-password',
        component: ChangePassword,
      },
    ],
  },
};

export const AUTHORIZED_ROUTES: RoutesByLayout = {
  [PathsLayouts.maintainers]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/periods',
    requiredPermissions: [
      PermissionName.CREATE_PERIOD,
      PermissionName.CREATE_MODALITY,
      PermissionName.CREATE_SCHEDULE,
      PermissionName.CREATE_FACULTY,
      PermissionName.CREATE_CAMPUS,
      PermissionName.EDIT_PERIOD,
      PermissionName.EDIT_MODALITY,
      PermissionName.EDIT_SCHEDULE,
      PermissionName.EDIT_FACULTY,
      PermissionName.EDIT_CAMPUS,
    ],
    allPermissionsRequired: false,
    views: [
      {
        path: '/periods',
        component: Maintainers,
      },
      {
        path: '/periods/record',
        component: PeriodRecordCreate,
      },
      {
        path: '/periods/record/:id',
        component: PeriodRecordEdit,
      },
      {
        path: '/modalities',
        component: Maintainers,
      },
      {
        path: '/schedules',
        component: Maintainers,
      },
      {
        path: '/schedules/record',
        component: ScheduleRecordCreate,
      },
      {
        path: '/schedules/record/:id',
        component: ScheduleRecordEdit,
      },
      {
        path: '/schools',
        component: Maintainers,
      },
      {
        path: '/campus',
        component: Maintainers,
      },
    ],
  },
  [PathsLayouts.studyPlans]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    allPermissionsRequired: false,
    requiredPermissions: [
      PermissionName.CREATE_STUDY_PLAN,
      PermissionName.EDIT_STUDY_PLAN,
      PermissionName.CREATE_STUDY_PLAN_VERSION,
      PermissionName.EDIT_STATUS_VERSION,
      PermissionName.CREATE_MALLA,
      PermissionName.EDIT_MALLA,
      PermissionName.EDIT_STATUS_MENTION,
    ],
    views: [
      {
        path: '/',
        component: StudyPlansList,
      },
      {
        path: '/record',
        component: StudyPlanRecordCreate,
      },
      {
        path: '/record/:id',
        component: StudyPlanRecordEdit,
      },
      {
        path: '/record/:id/version/create',
        component: VersionRecordCreate,
      },
      {
        path: '/record/:id/version/:versionId/curriculum/create', //Create
        component: CurriculumCreate,
      },
      {
        path: '/record/:id/version/:versionId/curriculum/:curriculumId', //Edit
        component: CurriculumEdit,
      },
      {
        path: '/record/:id/version/:versionId/curriculum/:curriculumId/view', //View
        component: CurriculumView,
      },
      {
        path: '/record/:id/version/:versionId/mention/create', //Create
        component: CurriculumCreateMention,
      },
      {
        path: '/record/:id/version/:versionId/mention/list', //List
        component: CurriculumListMention,
      },
      {
        path: '/record/:id/version/:versionId/mention/:mentionId', //Edit
        component: CurriculumEditMetion,
      },
      {
        path: '/record/:id/version/:versionId/mention/:mentionId/:action', //View
        component: CurriculumViewMetion,
      },
    ],
  },
  [PathsLayouts.academicOffers]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.CREATE_ACADEMIC_OFFER,
      PermissionName.EDIT_ACADEMIC_OFFER,
      PermissionName.DELETE_ACADEMIC_OFFER,
    ],
    views: [
      {
        path: '/',
        component: AcademicOffersList,
      },
      {
        path: '/record',
        component: AcademicOffersCreate,
      },
      {
        path: '/record/:id',
        component: AcademicOffersEdit,
      },
      {
        path: '/info/:id',
        component: AcademicOffersView,
      },
    ],
  },
  [PathsLayouts.tuitionProcess]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.ENROLLMENT_PERSON],
    views: [
      {
        path: '/search',
        component: TuitionProcessSearchStudent,
      },
      {
        path: '/postulation/:postulantId/:postulationDetailId/:admissionType/:fromStart',
        component: TuitionProcessOnSite,
      },
      {
        path: '/',
        component: SearchStudentOnSite,
      },
    ],
  },
  [PathsLayouts.tariffAndTuiton]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/tuitions',
    requiredPermissions: [
      PermissionName.CREATE_DISCOUNTS,
      PermissionName.EDIT_DISCOUNTS,
      PermissionName.SHOW_TUITIONS_PERIOD,
      PermissionName.CREATE_TUITIONS_PERIOD,
      PermissionName.EDIT_TUITIONS_PERIOD,
    ],
    views: [
      {
        path: '/tuitions',
        component: TariffAndTuition,
      },
      {
        path: '/tariff',
        component: TariffAndTuition,
      },
      {
        path: '/discounts',
        component: TariffAndTuition,
      },
    ],
  },
  [PathsLayouts.admissions]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.SHOW_ADMISSIONS,
      PermissionName.EDIT_ADMISIONS,
    ],
    views: [
      {
        path: '/',
        component: AdmissionsList,
      },
      {
        path: '/postulans-pending',
        component: AdmissionsPendingList,
      },
      {
        path: '/:id/in-process',
        component: AdmissionsListWithTabs,
      },
      {
        path: '/:id/enrolled',
        component: AdmissionsListWithTabs,
      },
      {
        path: '/:id/dropped',
        component: AdmissionsListWithTabs,
      },
      {
        path: '/:id/waiting-list',
        component: AdmissionsListWithTabs,
      },
      {
        path: '/:id/retracted',
        component: AdmissionsListWithTabs,
      },
    ],
  },
  [PathsLayouts.documents]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SHOW_DOCUMENT_REVIEW],
    views: [
      {
        path: '/',
        component: DocumentsGridCards,
      },
      {
        path: '/:academicOfferStudyPlanVersionOffersId',
        component: DocumentsList,
      },
    ],
  },
  [PathsLayouts.packages]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.SHOW_PACKAGES,
      PermissionName.CREATE_PACKAGES,
      PermissionName.EDIT_PACKAGES,
    ],
    views: [
      {
        path: '/pending-students/:id',
        component: PackagesPendingStudents,
      },
      {
        path: '/:id',
        component: PackagesView,
      },
      {
        path: '/',
        component: PackagesList,
      },
    ],
  },
  [PathsLayouts.packagesMaintainer]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.SHOW_SECTIONS_AND_PACKAGES,
      PermissionName.CREATE_SECTIONS_AND_PACKAGES,
      PermissionName.EDIT_SECTIONS_AND_PACKAGES,
    ],
    views: [
      {
        path: '/',
        component: SectionsAndPackagesList,
      },
      { path: '/:periodId', component: SectionsAndPackagesView },
    ],
  },
  [PathsLayouts.courseRegistration]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SHOW_COURSE_REGISTRATION],
    views: [
      { path: '/', component: UpdateCourseRegistration },
      {
        path: '/:accountId/:studyPlanEnrollmentId',
        component: DetailCourseRegistration,
      },
    ],
  },
  [PathsLayouts.reports]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SHOW_REPORTS],
    views: [
      {
        path: '/',
        component: Reports,
      },
    ],
  },
  [PathsLayouts.sectionAttributes]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.SHOW_SECTION_ATTRIBUTES_LIST,
      PermissionName.GET_SECTION_ATTRIBUTES,
      PermissionName.DOWNLOAD_SECTION_ATTRIBUTES_BASE_INFO,
    ],
    views: [
      {
        path: '/',
        component: SectionAttributesList,
      },
      { path: '/:periodId', component: SectionAttributesView },
    ],
  },
  [PathsLayouts.sectionsMaintainer]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SHOW_SECTION_MAINTAINER],
    views: [
      { path: '/create', component: CreateSectionForm },
      { path: '/:sectionId', component: SectionUpdate },
      { path: '/', component: SectionsMaintainer },
    ],
  },
  [PathsLayouts.teacherActivation]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SEARCH_TEACHER],
    views: [
      {
        path: '/',
        component: TeacherActivation,
      },
    ],
  },
  [PathsLayouts.studentStatus]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.SHOW_STUDENT_STATUS,
      PermissionName.CHANGE_STUDENT_STATUS,
    ],
    views: [
      {
        path: '/',
        component: StudentStatus,
      },
    ],
  },
  [PathsLayouts.curriculumProgress]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.STUDENT_CURRICULUM_PROGRESS_LIST],
    views: [
      {
        path: '/',
        component: CurriculumProgress,
      },
      {
        path: '/:studyPlanEnrollmentId',
        component: CurriculumProgressStudent,
      },
    ],
  },
  [PathsLayouts.closingPeriod]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SHOW_LIST_PERIOD_CLOSE_ADMIN_PORTAL],
    views: [
      {
        path: '/',
        component: ClosingPeriod,
      },
      {
        path: '/:id',
        component: PeriodEndReview,
      },
    ],
  },
  [PathsLayouts.coursesMaintainer]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SHOW_COURSES],
    views: [
      { path: '/', component: CoursesMaintainer },
      { path: '/create', component: CourseUpdate },
      { path: '/watch/:id', component: CourseUpdate },
      { path: '/update/:id', component: CourseUpdate },
    ],
  },
  [PathsLayouts.configurations]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.UPDATE_SETTINGS_TIME_LESSON,
      PermissionName.CREATE_SETTINGS_GRADE_ENTRY,
      PermissionName.UPDATE_SETTINGS_FINANTIAL,
    ],
    views: [{ path: '/', component: Configurations }],
  },
  [PathsLayouts.studentsPostClose]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.STUDENTS_POST_CLOSE],
    views: [
      { path: '/', component: StudentsPostCloseSearch },
      { path: '/detail', component: StudentsPostCloseDetail },
    ],
  },
  [PathsLayouts.tuitionContinuityProcessManagement]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.TUITION_CONTINUITY_PROCESS_MANAGEMENT_LIST,
    ],
    views: [
      { path: '/', component: TuitionContinuityProcessManagement },
      {
        path: '/:studyPlanVersionOfferId/period/:periodId',
        component: TuitionContinuityProcessManagementDetail,
      },
    ],
  },
  [PathsLayouts.graduationProcessMassive]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.SHOW_GRADUATION_PROCESS_MASSIVE,
      PermissionName.LAUNCH_GRADUATION_PROCESS_MASSIVE,
    ],
    views: [
      { path: '/', component: GraduationProcessMasive },
      { path: '/graduates-list', component: GraduatesListProcessMassive },
    ],
  },
  [PathsLayouts.graduationProcessSingle]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.SEARCH_GRADUATE_GRADUATION_PROCESS_SINGLE,
      PermissionName.LAUNCH_GRADUATION_PROCESS_SINGLE,
    ],
    views: [
      { path: '/', component: GraduationProcessSingle },
      {
        path: '/pending-requirerments',
        component: GraduationProcessSinglePendingRequirerments,
      },
    ],
  },
  [PathsLayouts.graduatesList]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    // requiredPermissions: [PermissionName.SHOW_GRADUATES_LIST],
    views: [{ path: '/', component: GraduatesList }],
  },
  [PathsLayouts.tuitionContinuityTariffAndTuition]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/tuitions',
    requiredPermissions: [PermissionName.TUITION_CONTINUITY_VALUES_LIST],
    views: [
      { path: '/tuitions', component: TuitionContinuityTariffAndTuition },
      {
        path: '/tariff',
        component: TuitionContinuityTariffAndTuition,
      },
    ],
  },
  [PathsLayouts.tuitionContinuityDocumentReview]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    views: [
      { path: '/', component: TuitionContinuityProcessDocumentReview },
      {
        path: '/student-list/:periodId/:offerId/:status',
        component: TuitionContinuityProcessDocumentReviewDetail,
      },
      {
        path: '/documents/:tcProcessId',
        component: TuitionContinuityProcessDocumentReviewDocuments,
      },
    ],
  },
  [PathsLayouts.courseValidationInternal]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.STUDENT_COURSE_VALIDATION_INTERNAL_LIST,
    ],
    views: [
      {
        path: '/',
        component: CourseValidationInternal,
      },
      {
        path: '/:studyPlanEnrollmentId',
        component: CourseValidationInternalStudent,
      },
    ],
  },
  [PathsLayouts.academicRecord]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.ACADEMIC_RECORD_LOAD],
    views: [
      {
        path: '/',
        component: AcademicRecord,
      },
    ],
  },
  [PathsLayouts.paymentHub]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SHOW_PAYMENT_HUB],
    views: [
      {
        path: '/',
        component: PaymentHub,
      },
    ],
  },
  [PathsLayouts.studentPetitions]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/received',
    requiredPermissions: [PermissionName.STUDENTS_PETITIONS_LIST],
    views: [
      {
        path: '/received',
        component: StudentPetitions,
      },
      {
        path: '/resolved',
        component: StudentPetitions,
      },
      {
        path: '/:petitionId',
        component: StudentsPetitionsDetail,
      },
    ],
  },
  [PathsLayouts.studentsDegree]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/graduate',
    requiredPermissions: [PermissionName.STUDENTS_DEGREE_LIST],
    views: [
      {
        path: '/graduate',
        component: StudentsDegree,
      },
      {
        path: '/titled',
        component: StudentsDegree,
      },
      {
        path: '/:studyPlanEnrollmentId',
        component: StudentsDegreeStudent,
      },
    ],
  },
};

export const routes = { ...AUTHORIZED_ROUTES, ...NOT_AUTHORIZED_ROUTES };
