import { CURRICULUM_PROGRESS } from '../../api/endpoints';
import request from '../../api/request';
import {
  CurriculumProgressBase,
  CurriculumProgressStudents,
  CurriculumProgressStudentsQuery,
} from './types';

export function getCurriculumProgress() {
  return request<CurriculumProgressBase>(CURRICULUM_PROGRESS.BASE, {
    method: 'get',
  });
}

export function getCurriculumProgressStudents(
  query: CurriculumProgressStudentsQuery,
) {
  return request<CurriculumProgressStudents>(CURRICULUM_PROGRESS.STUDENTS, {
    method: 'get',
    params: {
      studyPlanVersionId: query.search.studyPlanVersionId,
      searchText: query.search.searchText,
      status: query.search.status,
      page: query.page - 1,
      periodId: query.search.periodId,
    },
  });
}
