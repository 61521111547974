import { ReactElement, useCallback } from 'react';
import { DefaultValues } from 'react-hook-form';
import { AxiosResultDefaultError } from '../../../../../api/request';
import {
  AuthenticationError,
  getStudentFile,
} from '../../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../../components/info/DisplayError';
import Loading from '../../../../../components/info/Loading';
import { useTuitionProcessNoSua } from '../../../hooks/useTuitionProcessNoSua';
import { StudentFileResponse } from '../../../../../types/studentFileSectionTypes';
import { STATUS_POSTULATION } from '../../../../../types/tuitionProcessOnSite';
import studenFileFormatPreviusData from '../utils/studentFileFormatPreviusData';
import WaitingList from './WaitingList';
import { useParameters } from '../../../../../hooks/useParameters';

export interface StudentFileLoaderProps {
  postulationDetailId: string | number;
  onError: (code: string, onUnknown?: (() => void) | undefined) => void;
  children: (props: {
    defaultValues: DefaultValues<any>;
    photo: string | null;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  StudentFileResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export const StudentFileLoaderConsumer = FetchConsumer;

export default function StudentFileLoader({
  postulationDetailId,
  onError,
  children,
}: StudentFileLoaderProps) {
  const request = useCallback(async () => {
    return getStudentFile(postulationDetailId);
  }, [postulationDetailId]);

  const { countryOptions } = useParameters();
  const { statusPostulation } = useTuitionProcessNoSua();

  if (statusPostulation === STATUS_POSTULATION.IN_WAITING_LIST) {
    return <WaitingList />;
  }

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            onError(error.code);
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            defaultValues: studenFileFormatPreviusData(data, countryOptions),
            photo: data.student.photo,
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
