import { addToast } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import { getStudentsPetitionsList } from '../../../api/requests/studentsDegree';

import { StudentsDegreeRow } from '../../../types/studentsDegree';

import { STUDENT_STATUS } from '../../../types/studentStatusTypes';
import { SearchControls } from '../parts/SearchControls';
import PetitionsExcelReportInfoModal from '../parts/StudentsDegreeExcelReportInfoModal';
import PetitionsTable from '../parts/StudentsDegreeTable';
import { SearchParams } from '../types/StudentsDegree';

const dictPrefix = 'studentsDegree.titled';
const ITEMS_PER_PAGE = 10;

export default function TitledList() {
  const { t } = useTranslation();
  const [isLoadingTable, setIsTableLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const [tableData, setTableData] = useState<StudentsDegreeRow[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalGraduates, setTotalGraduates] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<SearchParams>({});

  const handleSearch = useCallback(
    async ({
      searchParams,
      page = 0,
    }: {
      searchParams?: SearchParams;
      page: number;
    }) => {
      setIsTableLoading(true);
      const { data, error } = await getStudentsPetitionsList({
        items: ITEMS_PER_PAGE,
        page: page,
        search: searchParams?.search,
        studyPlanId: searchParams?.studyPlanId,
        status: STUDENT_STATUS.TITLED,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalGraduates(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleClear = async () => {
    setTableData([]);
    setFilters({});
    setCurrentPage(0);
  };

  useEffect(() => {
    handleSearch({ searchParams: filters, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearch, currentPage]);

  return (
    <Card className="mx-3 p-4">
      <h2 className="text-primary text-uppercase fs-20 fw-600">
        {t(`${dictPrefix}.title`)}
      </h2>
      <p className="text-medium fs-16 py-2">{t(`${dictPrefix}.description`)}</p>

      <div className="mb-5">
        <SearchControls
          onSearch={handleSearch}
          onClear={handleClear}
          setFilters={setFilters}
        />
      </div>

      <PetitionsExcelReportInfoModal
        open={showInfoModal}
        onClose={() => setShowInfoModal(!showInfoModal)}
        onAfterClose={() => setShowModal(!showModal)}
      />

      <PetitionsTable
        isLoadingResults={isLoadingTable}
        data={tableData}
        totalPages={totalPages}
        totalPetitions={totalGraduates}
        currentPage={currentPage}
        onChangePage={(page) => {
          setCurrentPage(page);
        }}
        hasFilters={filters?.hasFilters}
      />
    </Card>
  );
}
