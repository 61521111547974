import { baseURL } from '../../../../../api/api';
import { TUITION_PROCESS } from '../../../../../api/endpoints';
import request from '../../../../../api/request';

export const getPaymentData = async ({
  token,
  postulationDetailId,
}: {
  token: string | undefined;
  postulationDetailId: string | number;
}) => {
  const url = TUITION_PROCESS.POSTULANT_PAYMENT_STEP(postulationDetailId);
  return request<any>(url, {
    params: {
      TBK_TOKEN: token,
      postulationDetailId,
    },
  });
};

/**
 * Obtiene el token para poder obtener documento para firmar la documentación
 * @returns string
 */
export const finishPaymentStep = (postulationDetailId: string | number) => {
  return request<{ notGenerateSalenote: boolean }>(
    TUITION_PROCESS.POSTULANT_PAYMENT_STEP_FINISH(postulationDetailId),
    {
      method: 'put',
    },
  );
};

/**
 * Obtiene el token para poder obtener documento para firmar la documentación
 * @returns string
 */
export const voucherToken = (postulationDetailId?: string | number) => {
  return request<string>(
    TUITION_PROCESS.POSTULANT_PAYMENT_VOUCHER_TOKEN(postulationDetailId),
    {
      method: 'post',
    },
  );
};

export const voucherOnSitePreview = (token: string): string => {
  return `${baseURL}${TUITION_PROCESS.POSTULANT_PAYMENT_ONSITE_VOUCHER}?token=${token}`;
};

export const voucherOnlinePreview = (token: string): string => {
  return `${baseURL}${TUITION_PROCESS.POSTULANT_PAYMENT_ONLINE_VOUCHER}?token=${token}`;
};
