import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SearchBox,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { studentsPetitionsListFilter } from '../../../api/requests/studentsDegree';
import { useValidations } from '../../../hooks/useValidations';
import {
  SearchParams,
  SelectOptionWithDefaultValue,
} from '../types/StudentsDegree';

const dictPrefix = 'studentsDegree.searchControls';

const DEFAULT_VALUES = {
  search: '',
  studyPlanId: null,
  status: {
    label: 'Todos los estados',
    value: null,
  },
};

interface FormValues {
  search: string;
  studyPlanId: OutlinedSelectOptionType | null;
  status: SelectOptionWithDefaultValue;
}

type onSearch = {
  searchParams: SearchParams;
  page: number;
};

interface SearchControlsProps {
  onSearch?: ({ searchParams, page }: onSearch) => void;
  onClear?: () => void;
  setFilters?: (params: any) => void;
}

export function SearchControls({
  onSearch = () => null,
  onClear = () => null,
  setFilters = (params: any) => {},
}: SearchControlsProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { validateMinLength } = useValidations();

  const [studyPlans, setStudyPlans] = useState<OutlinedSelectOptionType[]>([]);

  const { handleSubmit, control, reset, watch } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const [searchWatch, studyPlanIdWatch] = watch(['search', 'studyPlanId']);

  useEffect(() => {
    const searchParams = {
      search: searchWatch?.trim() !== '' ? searchWatch : undefined,
      studyPlanId: studyPlanIdWatch?.value as number,
    };

    setFilters({
      ...searchParams,
      hasFilters: Object.values(searchParams).some((value) => value !== null),
    });
  }, [searchWatch, studyPlanIdWatch, setFilters]);

  const handleSearch = (values: FormValues) => {
    const searchParams = {
      search: values.search?.trim() !== '' ? values.search : undefined,
      studyPlanId: values.studyPlanId?.value as number,
      hasFilters: Object.values(values).some((value) => value !== null),
    };

    onSearch({ searchParams, page: 0 });
  };

  const handleClear = () => {
    reset(DEFAULT_VALUES);
    onClear();
    onSearch({ searchParams: {}, page: 0 });
  };

  const getFilters = useCallback(async () => {
    const { data } = await studentsPetitionsListFilter();
    if (data) {
      setStudyPlans(
        data.data.studyPlans.map(({ id: value, name: label }) => ({
          label,
          value,
        })),
      );
      reset();
    }
  }, [reset]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  return (
    <Form onSubmit={handleSubmit(handleSearch)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={4}>
          <SearchBox
            name="search"
            label={t(`${dictPrefix}.nameOrId`)}
            placeholder={t(`${dictPrefix}.nameOrIdPlaceholder`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="studyPlanId"
            label={t(`${dictPrefix}.studyPlan`)}
            placeholder={t(`${dictPrefix}.studyPlanPlaceholder`)}
            control={control}
            options={studyPlans}
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3, offset: 6 }}
          lg={{ size: 2, offset: 0 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3 }}
          lg={2}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            text={t(`common.actions.clean`)}
            size="md"
            fullwidth
            outlined
            onClick={handleClear}
          />
        </Col>
      </Row>
    </Form>
  );
}
