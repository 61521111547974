import { Button, Loading } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, Card } from 'reactstrap';

import { getSummary } from '../../api/requests/graduationProcessSingle';
import DisplayError from '../../components/info/DisplayError';
import { PathsLayouts } from '../../config/routes';
import { useLoadingState } from '../../hooks/useLoadingState';
import { StudentSummary } from '../../types/graduationProcessSingle';
import { SearchParams } from '../GraduationProcessSingle/parts';
import {
  CreditsSummaryTable,
  FailedMandatoryCoursesTable,
  PendingOptativeCreditsTable,
  StudentDataTable,
} from './parts';
import { mapCredits, mapOptionalCredits } from './parts/helpers';

const dictPrefix = 'graduationProcessSinglePendingRequirerments';

export default function GraduationProcessSinglePendingRequirerments() {
  const history = useHistory();
  const { t } = useTranslation();

  const { state: routerState } =
    useLocation<{ studyPlanEnrolmentId: number; searchParams: SearchParams }>();

  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [summary, setSummary] = useState<StudentSummary>();

  if (!routerState.studyPlanEnrolmentId || !routerState.searchParams) {
    history.push(`${PathsLayouts.graduationProcessSingle}`);
  }

  const handleSearch = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getSummary(routerState.studyPlanEnrolmentId);
    if (error) {
      setErrorLoading(error.code);
    } else {
      setErrorLoading(undefined);
      setSummary(data?.data);
    }
    setLoading(false);
  }, [routerState.studyPlanEnrolmentId, setErrorLoading, setLoading]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  if (errorLoading) {
    return (
      <Card className="mx-3 p-4">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => setLoading(true)}
          loadingAction={loading}
        />
      </Card>
    );
  }

  if (loading) {
    return (
      <Card className="mx-3 p-4">
        <Loading textBody={t(`common.loading.body`)} insideCard />
      </Card>
    );
  }

  return (
    <Card className="mx-3 p-4">
      <Button
        icon="back"
        size="sm"
        text={t(`common.actions.goBackStep`)}
        outlined
        rounded
        style={{ maxWidth: 'fit-content' }}
        onClick={() =>
          history.push(PathsLayouts.graduationProcessSingle, {
            searchParams: routerState.searchParams,
          })
        }
      />

      <div className="mb-3 mt-4">
        <StudentDataTable
          data={[
            {
              studentId: summary!.studentId,
              studentName: summary!.studentName,
              studyPlanName: summary!.studyPlanName,
            },
          ]}
        />
      </div>

      <Alert
        color="danger"
        className="mt-3 mb-5text-danger fs-16"
        style={{ borderRadius: 8 }}
      >
        {t(`${dictPrefix}.alert`)}
      </Alert>

      <div className="mb-5">
        <h2 className="text-primary fw-600 fs-20 mb-4">
          {t(`${dictPrefix}.coursedCreditsSummary`)}
        </h2>
        <CreditsSummaryTable
          data={mapCredits({
            obtained: summary!.obtained,
            required: summary!.required,
          })}
        />
      </div>

      <div className="mb-5">
        <h2 className="text-primary fw-600 fs-20 mb-4">
          {t(`${dictPrefix}.mandatoryFailedCourses`)}
        </h2>

        <FailedMandatoryCoursesTable data={summary!.missingObligatoryCourses} />
      </div>

      <div className="mb-5">
        <h2 className="text-primary fw-600 fs-20 mb-4">
          {t(`${dictPrefix}.pendingOptativeCredits`)}
        </h2>
        <PendingOptativeCreditsTable
          data={mapOptionalCredits(summary!.missingOptionalCourses)}
        />
      </div>
    </Card>
  );
}
