import {
  Button,
  CellFormatOptions,
  ColumnTable,
  Icon,
  OutlinedSelect,
  OutlinedSelectOptionType,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentStatus,
  statusDocument,
} from '../../../api/requests/postulants';
import {
  changeDocumentStateOfPostulantProcess,
  changeIdentityCardStateOfPostulantProcess,
  getLinkDownloadDocumentOfPostulantProcess,
  getLinkDownloadIdentityCardOfPostulantProcess,
  showOtherDocumentOfPostulantProcess,
  showIdentityCardDocumentOfPostulantProcess,
} from '../../../api/requests/tuitionProcesses';
import { showToast } from '../../../utils/toast';
import { PostulantionDocumentationReviewDocumentRow } from '../types';

const IDETITY_CARD_TYPE_DOCUMENTS = [
  'postulant-passport',
  'sustainer-passport',
  'cosigner-passport',
];

export function useColumnsListDocumentByStatus(props: {
  onChangeStateRow: (
    row: PostulantionDocumentationReviewDocumentRow,
    status: statusDocument,
  ) => void;
  documentDelete: (row: PostulantionDocumentationReviewDocumentRow) => void;
  uploadDocument: (row: PostulantionDocumentationReviewDocumentRow) => void;
}) {
  const { onChangeStateRow, documentDelete, uploadDocument } = props;
  const { t } = useTranslation();

  const changeDocumentState = useCallback(
    async (
      row: PostulantionDocumentationReviewDocumentRow,
      e: { value: statusDocument; label: string } | null,
    ) => {
      if (!row.document) {
        return;
      }
      if (e) {
        let params: [number, { status: string }] = [
          row.document.id,
          {
            status: e.value,
          },
        ];
        let { error } =
          row.document.isIdentityCard &&
          !IDETITY_CARD_TYPE_DOCUMENTS.includes(row.document.type)
            ? await changeIdentityCardStateOfPostulantProcess(...params)
            : await changeDocumentStateOfPostulantProcess(...params);
        if (error) {
          showToast(true, t);
        } else {
          showToast(false, t);
          onChangeStateRow(row, e.value);
        }
      }
    },
    [onChangeStateRow, t],
  );

  const fetchLinkDownloadDocument = useCallback(
    async (
      documentID: number,
      isIdentity: boolean,
      name: string,
      type: string,
    ) => {
      let { data, error } =
        isIdentity && !IDETITY_CARD_TYPE_DOCUMENTS.includes(type)
          ? await getLinkDownloadIdentityCardOfPostulantProcess(documentID)
          : await getLinkDownloadDocumentOfPostulantProcess(documentID);
      if (error) {
        showToast(true, t);
      } else if (data) {
        let link = document.createElement('a');
        link.download = `${name}`;
        link.href = data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [t],
  );
  const fetchViewLinkDocument = useCallback(
    async (documentId: number, isIdentity: boolean, type: string) => {
      let { data, error } =
        isIdentity && !IDETITY_CARD_TYPE_DOCUMENTS.includes(type)
          ? await showIdentityCardDocumentOfPostulantProcess(documentId)
          : await showOtherDocumentOfPostulantProcess(documentId);
      if (error) {
        showToast(true, t);
      } else if (data) {
        window.open(data.file);
      }
    },
    [t],
  );
  return useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'type',
        headerText: t('documents.studyDocuments.type'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<PostulantionDocumentationReviewDocumentRow>,
        ) => {
          return (
            <span className="text-lowercase">
              {options.row.name}
              &nbsp;
              {options.row.document?.isIdentityCard &&
                t(`postulationDocs.attachment.${options.row.document.side}`)}
            </span>
          );
        },
      },
      {
        columnName: 'name',
        headerText: t('documents.studyDocuments.name'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<PostulantionDocumentationReviewDocumentRow>,
        ) => {
          return options.row.document?.inCloud
            ? options.row.document.filename
            : '';
        },
      },
      {
        columnName: 'action',
        headerText: '',
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<PostulantionDocumentationReviewDocumentRow>,
        ) => {
          const document = options.row.document;
          if (!document) {
            return null;
          }
          return (
            <div className="d-flex" style={{ gap: '24px' }}>
              <div
                className={!document.inCloud ? 'icon-disabled' : ''}
                onClick={() =>
                  fetchViewLinkDocument(
                    document.id,
                    document.isIdentityCard,
                    document.type,
                  )
                }
              >
                <Icon
                  name="eye"
                  color={document.inCloud ? 'primary' : 'disabled'}
                  className="icon-selector"
                />
              </div>
              <div
                className={!document.inCloud ? 'icon-disabled' : ''}
                onClick={() =>
                  fetchLinkDownloadDocument(
                    document.id,
                    document.isIdentityCard,
                    options.row.name,
                    document.type,
                  )
                }
              >
                <Icon
                  name="download"
                  color={document.inCloud ? 'primary' : 'disabled'}
                  className="icon-selector"
                />
              </div>
              <div
                className={!document.inCloud ? 'icon-disabled' : ''}
                onClick={() => documentDelete(options.row)}
              >
                <Icon
                  name="trash"
                  color={document.inCloud ? 'primary' : 'disabled'}
                  className="icon-selector"
                />
              </div>
            </div>
          );
        },
      },
      {
        columnName: 'upload',
        headerText: t('documents.studyDocuments.newUpload'),
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<PostulantionDocumentationReviewDocumentRow>,
        ) => {
          return (
            <div style={{ marginBottom: '.8rem' }}>
              <Button
                type="button"
                text={t('documents.studyDocuments.upload')}
                icon="export"
                disabled={options.row.document?.inCloud}
                size="md"
                outlined
                onClick={() => uploadDocument(options.row)}
              />
            </div>
          );
        },
      },
      {
        columnName: 'state',
        headerText: t('documents.studyDocuments.state.headerText'),
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({
          row,
        }: CellFormatOptions<PostulantionDocumentationReviewDocumentRow>) => {
          let options: OutlinedSelectOptionType[] = [
            {
              value: DocumentStatus.NOT_REVIEW,
              label: t('documents.studyDocuments.state.notReview'),
              icon: {
                name: 'error',
                color: 'danger',
              },
            },
            {
              value: DocumentStatus.WAITING,
              label: t('documents.studyDocuments.state.waiting'),
              icon: {
                name: 'warning',
                color: 'warning',
              },
            },
            {
              value: DocumentStatus.APPROVED,
              label: t('documents.studyDocuments.state.Aproved'),
              icon: {
                name: 'success',
                color: 'success',
              },
            },
          ];
          let selectOptions = options.find(
            ({ value }) => value === row.document?.status,
          );
          return (
            <div className="d-flex justify-content-center">
              <div
                style={{
                  width: '180px',
                }}
              >
                <OutlinedSelect
                  name="status"
                  isSearchable={false}
                  disabled={!row.document?.inCloud}
                  value={selectOptions}
                  isClearable={false}
                  options={options}
                  menuPosition="fixed"
                  onChange={(e) => changeDocumentState(row, e)}
                  menuShouldBlockScroll={true}
                />
              </div>
            </div>
          );
        },
      },
    ];
  }, [
    t,
    fetchLinkDownloadDocument,
    changeDocumentState,
    documentDelete,
    uploadDocument,
    fetchViewLinkDocument,
  ]);
}
