import {
  AdministrativeEnrollment,
  AvailableCourseEnrollmentResponse,
  AvailableSectionEnrollmentResponse,
  CourseRegistrationDetailResponse,
  CourseRegistrationResponse,
  AdministrativeEnrollmentRequestResponse,
  AdministrativeCourseRegistrationDeletionResponse,
} from '../../types/courseRegistrationTypes';
import { COURSE_REGISTRATION } from '../endpoints';
import request from '../request';
import { requestAuth } from './tuitionProcess';

/**
 * Obtiene listado con las inscripciones de asignaturas
 */
export const requestCourseRegistrationList = (data: {
  items_per_page: number;
  page: number;
  rut_or_passport?: string | null;
  search_text?: string | null;
  study_plan?: number | null;
}) => {
  return requestAuth<CourseRegistrationResponse>(
    `${COURSE_REGISTRATION.LIST}`,
    { params: data },
  );
};

/**
 * Obtiene el detalle de inscripciones de asignaturas de un alumno
 * en el período actual
 */
export const requestCourseRegistrationDetail = ({
  studyPlanEnrollmentId,
  itemsPerPage = 10,
  page = 0,
}: {
  studyPlanEnrollmentId: number | string;
  itemsPerPage?: number;
  page?: number;
}) => {
  const params = {
    items_per_page: itemsPerPage,
    page,
  };
  return requestAuth<CourseRegistrationDetailResponse>(
    `${COURSE_REGISTRATION.GET_DETAIL(studyPlanEnrollmentId)}`,
    { params },
  );
};

/**
 * Obtiene el listado de asignaturas disponibles para inscripción
 */
export const requestAvailableCoursesEnrollment = () => {
  return requestAuth<AvailableCourseEnrollmentResponse>(
    `${COURSE_REGISTRATION.AVAILABLE_COURSES}`,
  );
};

/**
 * Obtiene el listado de asignaturas disponibles para inscripción
 */
export const requestAvailableSectionsEnrollment = (courseId: number) => {
  return requestAuth<AvailableSectionEnrollmentResponse>(
    `${COURSE_REGISTRATION.GET_AVAILABLE_SECTIONS(courseId)}`,
  );
};

/**
 *  Le inscribe una materia a un estudiante (administrativamente)
 */
export const requestAdministrativeEnrollment = (
  data: AdministrativeEnrollment,
) => {
  return request<AdministrativeEnrollmentRequestResponse>(
    `${COURSE_REGISTRATION.SAVE_ADMINISTRATIVE_ENROLLMENT}`,
    {
      method: 'POST',
      data,
    },
  );
};

/**
 * Le desinscribe una materia a un estudiante (administrativamente)
 */
export const requestCourseRegistrationDeletion = (data: {
  accountId: number;
  sectionId: number;
  ignoreWarning: boolean;
}) => {
  return request<AdministrativeCourseRegistrationDeletionResponse>(
    COURSE_REGISTRATION.DETELE_COURSE_REGISTRATION,
    {
      method: 'DELETE',
      data,
    },
  );
};

/**
 * Mueve a un estudiante de una seccion a otra
 */
export const requestCourseSectionChange = (data: {
  accountId: number;
  newSectionId: number;
  oldSectionId: number;
  ignoreWarning?: boolean;
}) => {
  const { accountId, newSectionId, oldSectionId, ignoreWarning = false } = data;
  const url = COURSE_REGISTRATION.UPDATE_COURSE_REGISTRATION_SECTION(accountId);
  return request<{ message: string[]; statusCode: number }>(url, {
    data: {
      accountId,
      newSectionId,
      oldSectionId,
      ignoreWarning,
    },
    method: 'PUT',
  });
};
