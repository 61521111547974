import { Select } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Card, Col } from 'reactstrap';
import BaseLoader from './parts/BaseLoader';
import { PeriodsSelector } from './parts/PeriodsSelector';
import TuitionContinuityProcessManagementList from './parts/TuitionContinuityProcessManagementList';
import { TuitionContinuityProcessManagementLoader } from './parts/TuitionContinuityProcessManagementLoader';

export default function TuitionContinuityProcessManagement() {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityProcessManagement';

  const texts = {
    period: t(`${prefix}.period`),
    school: t(`${prefix}.school`),
  };

  return (
    <div className="student-grades-closure mx-3">
      <Card className="p-4">
        <BaseLoader>
          {({ data }) => (
            <PeriodsSelector {...data}>
              {(periodId: number) => (
                <TuitionContinuityProcessManagementLoader periodId={periodId}>
                  {({
                    studyPlanVersionOffers,
                    schoolOption,
                    onChangeSchool,
                  }) => (
                    <>
                      <Col md={6}>
                        <Select
                          name="selection-school"
                          label={texts.school}
                          isClearable
                          options={schoolOption}
                          onChange={onChangeSchool}
                        />
                      </Col>

                      <Col md={12}>
                        <TuitionContinuityProcessManagementList
                          periodId={periodId}
                          data={studyPlanVersionOffers}
                        />
                      </Col>
                    </>
                  )}
                </TuitionContinuityProcessManagementLoader>
              )}
            </PeriodsSelector>
          )}
        </BaseLoader>
      </Card>
    </div>
  );
}
