import { SelectOptionType } from '@octano/global-ui';
import { TariffData } from '../../../../../types/tariffTypes';
import { TariffFormatter } from '../../../../../utils/currency';

export default function tariffDefaultValues(
  options: {
    tariffPaymentTypesOptions: SelectOptionType[];
    paymentMethodsOptions: SelectOptionType[];
    documentTypesOptions: SelectOptionType[];
    businessActivitiesOptions: SelectOptionType[];
    cityOptions: SelectOptionType[];
    quotaOptions: SelectOptionType[];
  },
  tariffData: TariffData,
) {
  const findSelectedOption = (
    value?: string | number,
    arr?: SelectOptionType[],
  ) => arr?.find((t) => t.value === value);

  const selectedActivity = findSelectedOption(
    tariffData?.sustainer?.businessActivityId,
    options.businessActivitiesOptions,
  );

  return {
    tariffPaymentType: findSelectedOption(
      tariffData.postulationDetail?.tariffPaymentTypeCode,
      options.tariffPaymentTypesOptions,
    ),
    tariffDownPayment: TariffFormatter(
      `${tariffData.postulationDetail?.tariffDownPayment}`,
    ),
    paymentMethod: findSelectedOption(
      tariffData?.postulationDetail?.paymentMethodCode,
      options.paymentMethodsOptions,
    ),
    documentType: findSelectedOption(
      tariffData?.postulationDetail?.documentTypeCode,
      options.documentTypesOptions,
    ),
    businessActivity: selectedActivity,
    economicActivityCode: selectedActivity?.value
      ? `${selectedActivity?.value} ${selectedActivity?.label}`
      : undefined,
    businessCity: findSelectedOption(
      tariffData.sustainer?.businessCityId,
      options.cityOptions,
    ),
    quota: options.quotaOptions.find(
      (t) =>
        t.value ===
        tariffData?.studyPlan?.admissions?.[0].postulationsDetail?.[0]
          .paymentDates?.length,
    ),
  };
}
