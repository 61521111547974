import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import GoBackButton from '../../../../components/buttons/GoBackButton';
import { useStepState } from '../../../../components/step/useStepState';
import { useTuitionProcessNoSua as useTuitionNoSuaProcess } from '../../../TuitionProcessNoSua/hooks/useTuitionProcessNoSua';
import HeaderStep from '../../../TuitionProcessNoSua/steps/HeaderStep';
import { useTuitionProcess } from '../../TuitionProcessContext';
import SustainerForm from './SustainerForm';
import SustainerLoader from './SustainerLoader';

const Sustainer = () => {
  const {
    prefix: translationPrefix,
    postulationDetailId,
    onError,
  } = useTuitionProcess();
  const prefix = `${translationPrefix}.sustainer`;
  const { t } = useTranslation();
  const { prevStep } = useStepState();

  const { postulationDetail } = useTuitionNoSuaProcess();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card className="px-4 px-md-5 py-4">
      <SustainerLoader
        postulationDetailId={postulationDetailId}
        onError={onError}
      >
        {({
          sustainerPassportAvailable,
          studentFullName,
          defaultValues,
          ownSustainer,
        }) => (
          <>
            {postulationDetail?.admissionMethod === 'sin-sua' ? (
              <HeaderStep prefixStep={prefix} showBackBtn />
            ) : (
              <Row className="pb-4 pb-md-0">
                <Col xs={12}>
                  <GoBackButton onClick={() => prevStep()} />
                </Col>
                <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
                  <span className="fs-18 text-primary">
                    {t(`${prefix}.student`)}
                  </span>
                  <br />
                  <span className="fs-18 text-primary fw-600">
                    {studentFullName}
                  </span>
                </Col>
              </Row>
            )}
            <SustainerForm
              sustainerPassportAvailable={sustainerPassportAvailable}
              defaultValues={defaultValues}
              dataOwnSustainer={ownSustainer}
            />
          </>
        )}
      </SustainerLoader>
    </Card>
  );
};

export default Sustainer;
