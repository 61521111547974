export interface Account {
  id: number;
  email: string;
  fullname: string;
  isActive: boolean;
}

export interface Permission {
  id: number;
  name_public: string;
  name: PermissionName;
}

export interface Access {
  token: string;
}

export interface Refresh {}

export interface AuthResponse {
  account: Account;
  permissions: Permission[];
  access: Access;
  refresh: Refresh;
}

export enum PermissionName {
  SHOW_DOCUMENT_REVIEW = 'show.documentReview',
  SHOW_ADMISSIONS = 'show.admissions',
  EDIT_ADMISIONS = 'edit.admissions',
  ENROLLMENT_PERSON = 'enrollment.person',
  EDIT_ERROLMENT = 'edit.enrollment',
  CREATE_PERIOD = 'create.period',
  EDIT_PERIOD = 'edit.period',
  CREATE_FACULTY = 'create.school',
  EDIT_FACULTY = 'edit.school',
  CREATE_CAMPUS = 'create.campus',
  EDIT_CAMPUS = 'edit.campus',
  CREATE_MODALITY = 'create.modality',
  EDIT_MODALITY = 'edit.modality',
  CREATE_SCHEDULE = 'create.schedule',
  EDIT_SCHEDULE = 'edit.schedule',
  CREATE_STUDY_PLAN = 'create.planStudy',
  EDIT_STUDY_PLAN = 'edit.planStudy',
  CREATE_STUDY_PLAN_VERSION = 'create.versions.planStudy',
  EDIT_STATUS_VERSION = 'edit.statusVersion',
  CREATE_MALLA = 'create.malla',
  EDIT_MALLA = 'edit.malla',
  EDIT_STATUS_MALLA = 'edit.statusMalla',
  EDIT_STATUS_MENTION = 'edit.statusMention',
  CREATE_ACADEMIC_OFFER = 'create.offerAcademy',
  EDIT_ACADEMIC_OFFER = 'edit.offerAcademy',
  DELETE_ACADEMIC_OFFER = 'delete.offerAcademy',
  CREATE_DISCOUNTS = 'create.discounts',
  EDIT_DISCOUNTS = 'edit.discounts',
  DELETE_DISCOUNTS = 'delete.discounts',
  CREATE_TUITIONS_PERIOD = 'create.tuition-period',
  EDIT_TUITIONS_PERIOD = 'edit.tuition-period',
  DELETE_TUITIONS_PERIOD = 'delete.tuition-period',
  SHOW_TUITIONS_PERIOD = 'show.tuition-period',
  SHOW_PACKAGES = 'show.sectionPackages',
  CREATE_PACKAGES = 'create.sectionPackages',
  EDIT_PACKAGES = 'edit.sectionPackages',
  SHOW_SECTIONS_AND_PACKAGES = 'show.loadSectionPackages',
  CREATE_SECTIONS_AND_PACKAGES = 'create.loadSectionPackages',
  EDIT_SECTIONS_AND_PACKAGES = 'edit.loadSectionPackages',
  SHOW_COURSE_REGISTRATION = 'show.student.enrollment',
  SHOW_REPORTS = 'show.report',
  UPLOAD_STUDENTS_EMAILS = 'upload.file.no.email',
  DOWNLOAD_STUDENTS_WITHOUT_EMAIL = 'download.file.no.email',
  DOWNLOAD_SECTION_GRADES = 'download.file.section.grades',
  DOWNLOAD_TUITIONS_PERIOD_CONSOLIDATED = 'download.file.tuition.period-consolidated',
  DOWNLOAD_REPORT_ACADEMIC = 'download.file.academic',
  DOWNLOAD_STUDENT_SECTION_ENROLLED = 'download.file.student.section.enroll',
  SHOW_SECTION_ATTRIBUTES_LIST = 'show.section-attributes-list',
  GET_SECTION_ATTRIBUTES = 'get.section-attributes',
  DOWNLOAD_SECTION_ATTRIBUTES_BASE_INFO = 'download.section-attributes-base-info',
  SEARCH_TEACHER = 'search.teacher',
  ACTIVATE_TEACHER_ACCOUNT = 'activate.teacher',
  SHOW_SECTION_MAINTAINER = 'show.section',
  SHOW_STUDENT_STATUS = 'show.student.status',
  CHANGE_STUDENT_STATUS = 'change.student.status',
  REPORT_STUDENT_STATUS = 'download.file.students.status',
  SHOW_LIST_PERIOD_CLOSE_ADMIN_PORTAL = 'admin-portal.period-close.list',
  SHOW_COURSES = 'show.course',
  CREATE_COURSE = 'create.course',
  EDIT_COURSE = 'edit.course',
  REMOVE_COURSE = 'remove.course',
  SHOW_PAYMENT_HUB = 'payment-hub.show',
  UPDATE_SETTINGS_TIME_LESSON = 'settings.time-lesson.update',
  CREATE_SETTINGS_GRADE_ENTRY = 'settings.grades-entry.create',
  UPDATE_SETTINGS_FINANTIAL = 'settings.finantial.update',
  STUDENTS_POST_CLOSE = 'admin-portal.students-post-close',
  TUITION_CONTINUITY_PROCESS_MANAGEMENT_LIST = 'admin-portal.tuition-continuity-process-management.list',
  SHOW_GRADUATION_PROCESS_MASSIVE = 'graduation-process-information.show',
  LAUNCH_GRADUATION_PROCESS_MASSIVE = 'graduation-process.launch',
  SEARCH_GRADUATE_GRADUATION_PROCESS_SINGLE = 'graduation-process.find-student',
  LAUNCH_GRADUATION_PROCESS_SINGLE = 'graduation-process.launch-one',
  SHOW_GRADUATES_LIST = 'graduation-process.show-graduated',
  TUITION_CONTINUITY_VALUES_LIST = 'admin-portal.tuition-continuity-tariff.tuition-list',
  TUITION_CONTINUITY_VALUE_CREATE = 'admin-portal.tuition-continuity-tariff.create',
  TUITION_CONTINUITY_VALUE_EDITING = 'admin-portal.tuition-continuity-tariff.update',
  TUITION_CONTINUITY_VALUE_PERIOD_LIST = 'admin-portal.tuition-continuity-tariff.period-list',
  TUITION_CONTINUITY_TARIFF_LIST = 'admin-portal.tuition-continuity-tariff.tariff-list',
  TUITION_CONTINUITY_TARIFF_SAVE_CREATE = 'admin-portal.tuition-continuity-tariff.create-update-tariff',
  TUITION_CONTINUITY_DOCUMENT_REVIEW_LIST = 'admin-portal.tuition-continuity-document-review.offer-list',
  STUDENT_CURRICULUM_PROGRESS_LIST = 'admin-portal.student-curriculum-progress.student-list',
  STUDENT_COURSE_VALIDATION_INTERNAL_LIST = 'admin-portal.student-course-validation.student-list',
  ACADEMIC_RECORD_LOAD = 'admin-portal.academic-record-load',
  STUDENTS_PETITIONS_LIST = 'admin-portal.student-petition.list',
  STUDENTS_DEGREE_LIST = 'admin-portal.student-degree.list',
}

export enum UserSessionEvent {
  EXPIRED = 'user-session-expired',
}
