import { ColumnTable, Table } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { moneyFormatter } from '../../../../../utils/currency';
import { GatewayPaymentItem } from '../types';

interface IPaymentTableProps {
  data?: GatewayPaymentItem[];
  loading: boolean;
}

export default function PaymentTable({ loading, data }: IPaymentTableProps) {
  const prefix = 'tuitionProcessNoSua.payment';
  const { t } = useTranslation();

  const columns: ColumnTable[] = useMemo(
    () => [
      {
        columnName: 'detail',
        headerText: t(`${prefix}.table.detail`),
        thClassName: 'text-left px-4',
        tdClassName: 'px-0 py-0 text-left',
        cellFormat({ row }) {
          return (
            <span
              className={`px-4 d-flex align-items-center text-black text-left ${
                row?.isFinalRow ? 'font-weight-bold alert-primary' : ''
              }`}
              style={{ height: 60 }}
            >
              {t(`${prefix}.table.${row.label ?? row.detail}`)}
            </span>
          );
        },
      },
      {
        columnName: 'total',
        headerText: t(`${prefix}.table.total`),
        thClassName: 'text-right px-4',
        tdClassName: 'px-0 py-0 text-right',
        cellFormat({ row }) {
          return (
            <span
              className={`px-4 d-flex align-items-center text-black justify-content-end text-right font-weight-bold ${
                row?.isFinalRow ? 'alert-primary' : ''
              }`}
              style={{
                height: 60,
              }}
            >
              {moneyFormatter().format(row.total)}
            </span>
          );
        },
      },
    ],
    [t],
  );

  const items = useMemo(() => {
    if (data?.length) {
      const total = data?.map((e) => e?.total ?? 0)?.reduce((a, b) => a + b, 0);
      return data?.concat([
        {
          isFinalRow: true,
          detail: 'total',
          total,
        },
      ]);
    }
    return [];
  }, [data]);

  return (
    <Row>
      <Col xs={12}>
        <div className="w-100 mx-auto">
          <div className="text-center">
            <Table
              isLoadingResults={loading}
              columns={columns}
              data={items ?? []}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
