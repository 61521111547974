import { useMemo } from 'react';

import TabControl from '../../components/tabs/TabControl';
import { PathsLayouts } from '../../config/routes';
import ReceivedList from './ReceivedList/ReceivedList';
import ResolvedList from './ResolvedList/ResolvedList';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

export default function StudentPetitions() {
    const tabsPrefix = 'studentPetitions';
    const { t } = useTranslation();

    const tabs = useMemo(
        () => [
            { name: 'received', content: <ReceivedList /> },
            { name: 'resolved', content: <ResolvedList /> },
        ],
        [],
    );

    return (
        <>
            <Card >
                <p className="text-medium fs-16 mt-3 mb-5 mx-4">
                    {t(`${tabsPrefix}.title`)}
                </p>
                <TabControl
                    tabsPrefix={`${tabsPrefix}.tabs`}
                    tabs={tabs}
                    path={PathsLayouts.studentPetitions}
                />
            </Card>
        </>
    );
}

