import { addToast, Button, Icon, Modal } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import {
  updateDocumentationChangeStatus,
  uploadDocumentationChangeStatus,
} from '../../../api/requests/studentStatus';
import { STUDENT_STATUS } from '../../../types/studentStatusTypes';
import { formatDate } from '../../../utils/dates';
import { ModalChangeStatusType } from '../StudentStatus';
import ModalChangeFieldsForm from './ModalChangeFieldsForm';

interface ModalChangeStatusProps {
  modalChangeStatus?: ModalChangeStatusType;
  setModalChangeStatus: (props?: ModalChangeStatusType) => void;
  onSave: (index: number, newStatus: string) => void;
}

export interface InputsForm {
  file: any;
  startDate?: Date;
  endDate?: Date;
  checkWithoutEndDate?: boolean;
}

const ModalChangeStatus = ({
  modalChangeStatus,
  setModalChangeStatus,
  onSave,
}: ModalChangeStatusProps) => {
  const prefix = 'studentStatus.modalChange';
  const { t } = useTranslation();
  const methods = useForm<InputsForm>({
    reValidateMode: 'onBlur',
    defaultValues: modalChangeStatus?.defaultValues,
  });
  const { handleSubmit, formState, reset } = methods;

  useEffect(() => {
    if (modalChangeStatus?.defaultValues) {
      reset(modalChangeStatus?.defaultValues);
    }
  }, [modalChangeStatus?.defaultValues, reset]);

  const onSubmitChange = useCallback(
    async ({ file, startDate, endDate }: InputsForm) => {
      if (modalChangeStatus) {
        const body = {
          file: file?.size ? file : null,
          studyPlanEnrollmentId: modalChangeStatus.studyPlanEnrollmentId,
          newStatus: modalChangeStatus.newStatus,
          startDate: startDate
            ? formatDate(startDate, 'YYYY-MM-DD')
            : undefined,
          endDate: endDate ? formatDate(endDate, 'YYYY-MM-DD') : undefined,
        };

        const requestAction =
          modalChangeStatus.action === 'create'
            ? uploadDocumentationChangeStatus
            : updateDocumentationChangeStatus;

        const { data, error } = await requestAction(body);
        if (data && !error) {
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.uploadSuccess`),
          });
          onSave(modalChangeStatus.index, modalChangeStatus.newStatus);
          setModalChangeStatus(undefined);
        } else {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.uploadError`),
          });
        }
      }
    },
    [setModalChangeStatus, modalChangeStatus, onSave, t],
  );

  const isWatchMode = useMemo(() => {
    return modalChangeStatus?.action === 'watch';
  }, [modalChangeStatus]);

  const [isOpen, setIsOpen] = useState<boolean>(Boolean(modalChangeStatus));

  useEffect(() => {
    setIsOpen(Boolean(modalChangeStatus));
  }, [modalChangeStatus]);

  return (
    <Modal isOpen={isOpen} toggle={() => setModalChangeStatus(undefined)}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmitChange)}>
          <Row className="pb-4">
            {/* Se muestra el icono de warning sólo si el cambio del alumno 
            es hacia el estado regular, porque solo se solicitará una confirmación */}
            {modalChangeStatus?.newStatus === STUDENT_STATUS.REGULAR && (
              <Col xs={12} className="text-center pb-4">
                <Icon name="warning" size="56px" color="secondary" />
              </Col>
            )}
            <Col xs={12} className="text-center">
              <h1 className="text-dark fs-22">{t(`${prefix}.title`)}</h1>
            </Col>
          </Row>

          {modalChangeStatus?.newStatus !== STUDENT_STATUS.REGULAR ? (
            <>
              {!isWatchMode && (
                <Row>
                  <Col xs={12}>
                    <p className="fs-16 fw-300 text-center pb-3">
                      {t(`${prefix}.description`)}
                    </p>
                  </Col>
                </Row>
              )}
              <ModalChangeFieldsForm modalChangeStatus={modalChangeStatus} />
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <p className="fs-18 fw-300 text-center pb-4">
                  <Trans t={t}>{t(`${prefix}.changeToRegular`)}</Trans>
                </p>
              </Col>
            </Row>
          )}

          <Row className="pb-2 pt-4">
            <Col className="mx-auto" xs={12} sm={6}>
              <Button
                onClick={() => {
                  setModalChangeStatus(undefined);
                }}
                text={t(`common.actions.close`)}
                outlined
                fullwidth
                disabled={formState.isSubmitting}
              />
            </Col>
            {!isWatchMode && (
              <Col xs={12} sm={6}>
                <Button
                  type="submit"
                  text={t(`common.actions.save`)}
                  fullwidth
                  loading={formState.isSubmitting}
                />
              </Col>
            )}
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ModalChangeStatus;
