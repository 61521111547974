import {
  addToast,
  Button,
  CheckInput,
  Modal,
  TextInput,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { saveDiscountPostulant } from '../../api/requests/studyPlansOffer';
import CardTitle from '../../components/text/CardTitle';
import { useLayoutState } from '../../hooks/useLayoutState';

interface DiscountItem {
  id: number;
  code: string;
  name: string;
  description: string | null;
  percentage: number;
}
export interface AdmissionItem {
  Postulant_rut: string | null;
  PostulantPassport_number: string | null;
  Postulant_names: string;
  Postulant_paternalLastName: string;
  Postulant_maternalLastName: string;
  Contact_cellPhone: string;
  Contact_email: string;
  Discount_discountValue: number | null;
  Details_enrolledDate: string | null;
  Details_retractedDate: string | null;
  Details_postulationId: number;
  Details_id: number;
  Discount_discountCode: string | null;
  Discount_discountName: string | null;
  Discount_id: number | null;
}
interface DiscountFormProps {
  show: boolean;
  toggleModal: () => void;
  discounts: DiscountItem[];
  postulant: AdmissionItem;
  reloadList: () => void;
}

export const InProcessDiscountModal = ({
  show,
  toggleModal,
  discounts,
  postulant,
  reloadList,
}: DiscountFormProps) => {
  const prefix = 'admission';
  const { t } = useTranslation();

  const [giveCustomDiscountChecked, setGiveCustomDiscountChecked] =
    useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [percentage, setPercentage] = useState<string>('');
  const [disabledName, setDisabledName] = useState<boolean>(true);
  const [disabledPercentage, setDisabledPercentage] = useState<boolean>(true);
  const [showNotDiscountFound, setShowNotDiscountFound] =
    useState<boolean>(false);

  const showToast = () => {
    toggleModal();
    addToast({
      icon: 'success',
      color: 'success',
      text: t(`${prefix}.discountAssignedMessage`),
    });
  };
  const { showErrorModal } = useLayoutState();

  const saveDiscount = async () => {
    const { data, error } = await saveDiscountPostulant(
      postulant.Details_id,
      code,
      name,
      Number(percentage),
      postulant.Discount_id,
    );
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
      return;
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
      toggleModal();
    }
    if (data) {
      showToast();
      reloadList();
      toggleModal();
    }
  };

  const getCurrentDiscount = useCallback(() => {
    setCode(
      postulant.Discount_discountCode ? postulant.Discount_discountCode : '',
    );
    setName(
      postulant.Discount_discountName ? postulant.Discount_discountName : '',
    );
    setPercentage(
      postulant.Discount_discountValue
        ? String(postulant.Discount_discountValue)
        : '',
    );
  }, [postulant]);

  const resetFields = useCallback(() => {
    setCode('');
    setName('');
    setPercentage('');
  }, []);

  const findCode = useCallback(
    (codeToSearch: string) => {
      const found = discounts.find((element) => element.code === codeToSearch);
      if (!found) {
        setName('');
        setPercentage('');
        setDisabledName(true);
        setDisabledPercentage(true);
        if (
          postulant.Discount_id &&
          postulant.Discount_discountCode === codeToSearch
        ) {
          getCurrentDiscount();
          setShowNotDiscountFound(false);
        } else if (codeToSearch !== '') {
          setShowNotDiscountFound(true);
        }
        return;
      }

      if (found) {
        setName(found.name);
        setPercentage(String(found.percentage));
        setShowNotDiscountFound(false);
        return;
      }
    },
    [
      discounts,
      getCurrentDiscount,
      postulant.Discount_discountCode,
      postulant.Discount_id,
    ],
  );

  useEffect(() => {
    resetFields();
    if (!giveCustomDiscountChecked) {
      getCurrentDiscount();
    }
    setDisabledName(!giveCustomDiscountChecked);
    setDisabledPercentage(!giveCustomDiscountChecked);
    setShowNotDiscountFound(false);
  }, [giveCustomDiscountChecked, getCurrentDiscount, resetFields]);

  useEffect(() => {
    if (!giveCustomDiscountChecked) {
      findCode(code);
    }
  }, [code, findCode, giveCustomDiscountChecked]);

  useEffect(() => {
    setShowNotDiscountFound(false);
    if (!show) {
      resetFields();
      setGiveCustomDiscountChecked(false);
      return;
    }
    getCurrentDiscount();
    setDisabledName(true);
    setDisabledPercentage(true);
  }, [show, postulant, getCurrentDiscount, resetFields]);

  return (
    <Modal isOpen={show} toggle={toggleModal} size="md" unmountOnClose>
      <Row>
        <Col>
          <div className="text-center">
            <CardTitle children={t(`${prefix}.titleModalDiscount`)} />
          </div>
        </Col>
      </Row>
      <Row className="mt-0 mt-md-4">
        <Col xs="12" md="12">
          <TextInput
            name="name"
            readOnly={disabledName}
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={t('common.forms.name')}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6} xs={12}>
          <TextInput
            name="code"
            value={code}
            label={t('tariffAndTuition.discounts.discount.labelDiscountCode')}
            onChange={(e) => setCode(e.target.value)}
          />
          <span style={{ color: 'red' }}>
            {showNotDiscountFound
              ? t('tariffAndTuition.discounts.noDiscount')
              : ''}
          </span>
        </Col>
        <Col md={6} xs={12}>
          <TextInput
            name="percentage"
            value={percentage}
            readOnly={disabledPercentage}
            onChange={(e) => setPercentage(e.target.value)}
            label={t('tariffAndTuition.discounts.discount.percentage')}
          />
        </Col>
      </Row>
      <CheckInput
        name="giveCustomDiscount"
        value={giveCustomDiscountChecked}
        label={t(`${prefix}.btn.giveCustomDiscount`)}
        onChange={() => {
          setGiveCustomDiscountChecked((prev) => !prev);
        }}
      />
      <Row className="mt-5">
        <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
          <Button
            type="button"
            text={t('common.actions.cancel')}
            outlined
            onClick={() => toggleModal()}
            fullwidth
          />
        </Col>
        <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
          <Button
            type="button"
            onClick={saveDiscount}
            text={t('common.actions.confirm')}
            fullwidth
            className="mb-3 mb-md-0"
          />
        </Col>
      </Row>
    </Modal>
  );
};
