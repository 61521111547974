import { Alert, Button, addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

import { moneyFormatter } from '../../../../../../utils/currency';
import { useTuitionProcessNoSua } from '../../../../hooks/useTuitionProcessNoSua';
import { voucherOnSitePreview, voucherToken } from '../../services/requests';
import PaymentTable from '../PaymentTable';
// import GatewayRedirect from './GatewayRedirect';

// export interface PaymentProps extends GatewayPaymentResponse {}

export default function Payment({ ...props }: any) {
  const prefix = `tuitionProcessNoSua.payment`;

  const [downloading, setDownloading] = useState<boolean>(false);

  const methods = useForm();

  //Custom Hooks
  const { t } = useTranslation();
  const { tuitionInfo } = useTuitionProcessNoSua();
  // const gatewatRedirectRef = useRef<any>(null);

  // const { setLoading } = useLoadingState();
  // const { nextStep } = useStepState();
  const { handleSubmit, formState } = methods;

  // const [finishing, setFinishing] = useState<boolean>(false);
  const [creatingLink, setCreatingLink] = useState<boolean>(false);
  console.log(setCreatingLink);
  const onSubmit = useCallback(async () => {
    if (creatingLink) {
      return;
    }
    // setCreatingLink(true);
    // setLoading(true);
    // const { data, error } = await generatePaymentLink(window?.location?.href);
    // if (error) {
    //   addToast({
    //     icon: 'error',
    //     color: 'danger',
    //     text: t(`${prefix}.errorOnGenerate`),
    //   });
    // } else if (data) {
    //   addToast({
    //     icon: 'check',
    //     color: 'success',
    //     text: t(`${prefix}.successOnGenerate`),
    //   });
    //   gatewatRedirectRef?.current?.redirect(data);
    // } else {
    //   addToast({
    //     icon: 'error',
    //     color: 'danger',
    //     text: t(`${prefix}.errorOnGenerate`),
    //   });
    // }
    // setCreatingLink(false);
  }, [creatingLink]);

  // const handleFinishProcesss = useCallback(async () => {
  //   try {
  //     if (finishing) {
  //       return;
  //     }
  //     setFinishing(true);
  //     const { error } = await finishPaymentProcess();
  //     if (error) {
  //       throw error;
  //     } else {
  //       setFinishing(false);
  //       nextStep();
  //     }
  //   } catch (error: any) {
  //     setFinishing(false);
  //     if (error && error.code === 'HTTP_ERROR' && error.status === 401) {
  //       setIsSessionExpired(true);
  //     } else {
  //       addToast({
  //         icon: 'error',
  //         color: 'danger',
  //         text: t(`${prefix}.errorOnFinish`),
  //       });
  //     }
  //   }
  // }, [finishing, nextStep, setIsSessionExpired, t]);

  const handleDownloadVoucher = useCallback(async () => {
    try {
      if (downloading) {
        return;
      }
      setDownloading(true);
      const { data: token, error: errorToken } = await voucherToken();
      if (errorToken) {
        throw errorToken;
      } else if (!token) {
        throw new Error('NO_TOKEN');
      }

      const url = voucherOnSitePreview(token);
      setDownloading(false);
      window.open(url);
    } catch (error: any) {
      setDownloading(false);
      if (error && error.code === 'HTTP_ERROR' && error.status === 401) {
        // setIsSessionExpired(true);
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorOnDownload`),
        });
      }
    }
  }, [downloading, prefix, t]);

  return (
    <>
      <Form className="" onSubmit={handleSubmit(onSubmit)}>
        {!!props?.message?.trim() && (
          <Alert
            isOpen={true}
            text={props?.message?.trim()}
            className="mx-auto"
            style={{ maxWidth: 370 }}
            icon="information"
            fade
          />
        )}

        <Col xs={12} className="d-flex flex-column" style={{ gap: '24px' }}>
          {Boolean(props?.details?.items?.length) && (
            <PaymentTable data={props?.details?.items ?? []} loading={false} />
          )}

          {tuitionInfo?.isOnlineTuition && (
            <>
              {Boolean(props?.details) && (
                <Row className="pt-5 mt-5 justify-content-center">
                  <Col xs={12}>
                    <Button
                      type="submit"
                      style={{ maxWidth: 348 }}
                      text={t(`${prefix}.submit`)}
                      loading={formState.isSubmitting || creatingLink}
                      className="mx-auto"
                      fullwidth
                    />
                  </Col>
                  <Col xs={12}>
                    <span className="w-100 d-block text-center fs-14 mt-2 text-light">
                      {t(`${prefix}.hint`, {
                        amount: `${moneyFormatter().format(
                          props?.details?.total ?? 0,
                        )}`,
                      })}
                    </span>
                  </Col>
                </Row>
              )}

              {Boolean(props?.receipt) && (
                <Row className="pt-5 mt-5 justify-content-center">
                  <Col xs={12}>
                    <Button
                      type="button"
                      style={{ maxWidth: 348 }}
                      text={t(`${prefix}.finishCourseRegistration`)}
                      className="mx-auto"
                      // loading={finishing}
                      fullwidth
                      // onClick={handleFinishProcesss}
                    />
                  </Col>
                  <Col xs={12}>
                    <Button
                      type="button"
                      icon="download"
                      style={{ maxWidth: 348 }}
                      text={t(`${prefix}.donwloadReceipt`)}
                      className="mx-auto mt-2"
                      loading={downloading}
                      fullwidth
                      outlined
                      onClick={handleDownloadVoucher}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Form>

      {/* <GatewayRedirect ref={gatewatRedirectRef} /> */}
    </>
  );
}
