import { SelectOptionType } from '@octano/global-ui';
import { ReactChild } from 'react';
import {
  CurriculumDegreeActivity,
  TableCurriculumDegreeActivity,
} from '../studyPlans/curriculum/CurriculumDegreeActivities/types';
export interface CurriculumFormProps {
  curriculumData: any;
  defaultValues?: CurriculumValues;
  setDisabledEditButton: (value: boolean) => void;
  children: (props: {
    isSubmitting: boolean;
    isDirty?: boolean;
    legend: any;
  }) => ReactChild;
  onSubmit: (
    values: CurriculumValues,
  ) => Promise<'CONNECTION' | 'HTTP_ERROR' | undefined>;
  studyPlanName: string;
  readOnly: boolean;
}
export interface CurriculumListItem {
  id: number;
  name?: string;
  code?: string;
  status?: string;
  isPrimary?: boolean;
  duration?: number;
  totalCredits?: number;
  obligatoryCredits?: number;
  optionalCredits?: number;
  otherCredits?: number;
  activityNumber?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CurriculumTypeFull {
  id: number;
  name?: string;
  code?: string;
  status: string;
  isPrimary: boolean;
  duration: number;
  totalCredits: number;
  obligatoryCredits: number;
  optionalCredits: number;
  otherCredits: number;
  activityNumber: number;
  createdAt: Date;
  updatedAt: Date;
}
export interface CurriculumValues {
  mentionCode?: number;
  mentionName?: number;
  semestersAmount: SelectOptionType;
  totalCredits: number;
  requiredCredits: number;
  optionalCredits: number;
  otherCredits: number;
  activitiesNumber: number;
  activitiesType: CurriculumDegreeActivity[];
  curriculumActivities?: TableCurriculumDegreeActivity[];
  hasActivitiesChecked: boolean;
}

export interface CourseInfo {
  id: string | undefined;
  type: string;
  name: string | undefined;
  code: string | undefined;
  credits: number;
  shortening: string | undefined;
  semester: number;
  semesterId: number | undefined;
  semesterCourseId: number | undefined;
}

export interface LegendInfo {
  total: number;
  totalNeeded: number;
  required: number;
  requiredNeeded: number;
  optional: number;
  optionalNeeded: number;
  other: number;
  otherNeeded: number;
  activities: number;
  activitiesNeeded: number;
}

export interface CurriculumListResponse {
  data: CurriculumListItem[];
  total?: number;
  total_pages?: number;
}

export interface CurriculumsResponse {
  curriculums: CurriculumListItem[];
  isPrimary?: boolean;
}

export const ACTION_TYPES = {
  CREATE: 'create',
  LIST: 'list',
  EDIT: 'edit',
  VIEW: 'view',
};

export const COURSE_TYPES = {
  OBLIGATORIO: {
    id: 1,
    name: 'Obligatorio',
    priority: 1,
    alias: 'Obligatorio',
  },
  OPTATIVO_OTRO: {
    id: 2,
    name: 'Optativos',
    priority: 10,
    alias: 'Optativo - Otro',
  },
  OPTATIVO_RELIGIOSO: {
    id: 3,
    name: 'Optativo-Religioso',
    priority: 8,
    alias: 'Optativo - Religioso',
  },
  OPTATIVO_DEPORTIVO: {
    id: 4,
    name: 'Optativo-Deportivo',
    priority: 9,
    alias: 'Optativo - Deportivo',
  },
  ACTIVIDAD: {
    id: 5,
    name: 'Actividad',
    priority: 1,
    alias: 'Actividad',
  },
  OTROS: {
    id: 6,
    name: 'Otros',
    priority: 7,
    alias: 'Otras',
  },
};

export const ERRORS_DEFAULT = {
  has: false,
  total: false,
  required: false,
  optional: false,
  other: false,
  activities: false,
  semester: false,
  activitiesChecked: false,
};

export const LEGEND_DEFAULT = {
  total: 0,
  totalNeeded: 0,
  required: 0,
  requiredNeeded: 0,
  optional: 0,
  optionalNeeded: 0,
  other: 0,
  otherNeeded: 0,
  activities: 0,
  activitiesNeeded: 0,
};

export enum CURRICULUM_STATUS {
  borrador = 'borrador',
  activo = 'activo',
  vigente = 'vigente',
  cerrado = 'cerrado',
}
