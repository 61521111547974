import { OutlinedSelectOptionType, Select } from '@octano/global-ui';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { StudyPlansAdmissionDetailForm } from '../../api/requests/studyPlans';
import { isNumber } from '../../utils/math';

import DisplayError from '../../components/info/DisplayError';
import './admissionsPlanesStudent.scss';

interface AdmissionsListProps extends StudyPlansAdmissionDetailForm {
  children?: (periodId: number) => ReactElement;
}

export default function AdmissionsList({
  periods,
  currentPeriod,
  children,
}: AdmissionsListProps) {
  const { t } = useTranslation();

  const options = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [periods]);

  const [value, setValue] = useState(() => {
    const option = options.find((o) => o.value === currentPeriod?.id);
    if (option) {
      return option;
    }
    if (options.length > 0) {
      return options[0];
    }
    return undefined;
  });

  const periodId = value?.value;

  if (!periodId || !isNumber(periodId)) {
    return (
      <DisplayError
        insideCard
        textBody="Ha ocurrido un error al seleccionar el periodId"
      />
    );
  }

  return (
    <div id="admissionsPlanesList">
      <div className="g-table-container mt-2 pb-4 pt-4">
        <div className="mb-5">{t('admission.selectFilters')}</div>
        <Row>
          <Col md={4}>
            <Select
              label={t('packages.period')}
              name="period"
              options={options}
              value={value}
              onChange={(v) => {
                setValue(v);
              }}
              isClearable={false}
            />
          </Col>
        </Row>
        {children && children(periodId)}
      </div>
    </div>
  );
}
