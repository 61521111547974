import { Button, DetailBox, Table } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import { requestCourseRegistrationDetail } from '../../api/requests/courseRegistration';
import GoBackButton from '../../components/buttons/GoBackButton';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import TableEmptyContent from '../../components/text/TableEmptyContent';
import { useLoadingState } from '../../hooks/useLoadingState';
import {
  SectionCourseRegistration,
  StudentCourseRegistration,
  StudyPlanCourseRegistration,
} from '../../types/courseRegistrationTypes';
import ModalConfirmDeleteRegistration from './parts/ModalConfirmDeleteRegistration';
import ModalNewCourse from './parts/ModalNewCourse';
import ModalSectionChange from './parts/ModalSectionChange';
import { useColumnsCourseRegistrationDetail } from './parts/useColumnsCourseRegistrationDetail';

const SECTIONS_PER_PAGE = 10;

const DetailCourseRegistration = () => {
  const prefix = 'courseRegistrationList.detail';
  const { t } = useTranslation();
  const history = useHistory();

  const { loading, errorLoading, setErrorLoading, setLoading } =
    useLoadingState();
  const [student, setStudent] = useState<StudentCourseRegistration>();
  const [studyPlan, setStudyPlan] = useState<StudyPlanCourseRegistration>();

  const [sections, setSections] = useState<SectionCourseRegistration[]>([]);
  const [sectionsPage, setSectionsPage] = useState(0);
  const [sectionsTotalPages, setSectionsTotalPages] = useState(0);
  const [sectionTotalItems, setSectionsTotalItems] = useState(0);

  const [modalNewCourse, setModalNewCourse] = useState<boolean>(false);
  const [deletingSectionRegistration, setDeletingSectionRegistration] =
    useState<number>();
  const [changingSectionFrom, setChangingSectionFrom] =
    useState<SectionCourseRegistration>();

  const columns = useColumnsCourseRegistrationDetail({
    onTrashClick: setDeletingSectionRegistration,
    onSectionChangeClick: setChangingSectionFrom,
  });

  const { accountId, studyPlanEnrollmentId } =
    useParams<{ accountId: string; studyPlanEnrollmentId: string }>();

  const hasMention = useMemo(() => {
    return studyPlan?.curriculums?.length && studyPlan?.curriculums?.length > 0;
  }, [studyPlan]);

  const detailColumns = useMemo(() => {
    const cols = [
      {
        title: t(`${prefix}.studentName`),
        body: student?.fullName,
        size: { xs: 12, md: hasMention ? 4 : 6 },
      },
      {
        title: t(`${prefix}.studentStudyPlan`),
        body: studyPlan?.name,
        size: { xs: 12, md: hasMention ? 4 : 6 },
      },
    ];
    if (hasMention) {
      cols.push({
        title: t(`${prefix}.mention`),
        body: studyPlan?.curriculums?.[0].name,
        size: { xs: 12, md: hasMention ? 4 : 6 },
      });
    }
    return cols;
  }, [hasMention, t, student, studyPlan]);

  const getCourses = useCallback(async () => {
    const { data: res, error } = await requestCourseRegistrationDetail({
      studyPlanEnrollmentId,
      itemsPerPage: SECTIONS_PER_PAGE,
      page: sectionsPage,
    });
    if (res) {
      setStudent(res.data.student);
      setStudyPlan(res.data.studyPlan);

      setSections(res.data.sections.data);
      setSectionsTotalPages(res.data.sections.total_pages);
      setSectionsTotalItems(res.data.sections.total);

      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
    }
    setLoading(false);
  }, [studyPlanEnrollmentId, sectionsPage, setLoading, setErrorLoading]);

  useEffect(() => {
    var regex = /^\d+$/;
    if (accountId && !accountId.match(regex)) {
      history.replace('/course-registration');
    } else {
      if (loading) {
        getCourses();
      }
    }
  }, [getCourses, loading, accountId, history]);

  const excludedCourses = useMemo(() => {
    return sections.map((section) => section.course.id);
  }, [sections]);

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => setLoading(true)}
          loadingAction={loading}
        />
      </div>
    );
  }
  if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />;
      </div>
    );
  }

  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col xs={12} lg={6}>
          <GoBackButton text={t('common.actions.backToList')} />
        </Col>
        <Col xs={12} lg={6} className="py-3 py-lg-0">
          <DetailBox columns={detailColumns} />
        </Col>
        <Col xs={12} className="pt-1">
          <Button
            icon="plus"
            size="md"
            text={t(`${prefix}.btnNewCourse`)}
            onClick={() => {
              setModalNewCourse(true);
            }}
          />
        </Col>

        <Col xs={12} className="pt-3">
          <Table<SectionCourseRegistration>
            columns={columns}
            data={sections}
            pagination={{
              itemsPerPage: SECTIONS_PER_PAGE,
              currentPage: sectionsPage + 1,
              totalPages: sectionsTotalPages,
              totalItems: sectionTotalItems,
              onChangePage: (p) => {
                setSectionsPage(() => p - 1);
                setLoading(true);
              },
            }}
            noResultsText={
              <TableEmptyContent
                title={t(`${prefix}.noResultsTitle`)}
                subtitle={t(`${prefix}.noResultsBody`)}
              />
            }
          />
        </Col>
      </Row>

      <ModalNewCourse
        enrollmentData={{
          accountId: Number(accountId),
          studyPlanVersionId: studyPlan?.id || -1,
        }}
        excludedCourses={excludedCourses}
        isOpen={modalNewCourse}
        toggle={() => setModalNewCourse(false)}
        onSave={() => {
          setLoading(true);
          setModalNewCourse(false);
        }}
      />

      <ModalConfirmDeleteRegistration
        enrrollmentData={{
          accountId: Number(accountId),
          sectionId: deletingSectionRegistration || -1,
        }}
        isOpen={!!deletingSectionRegistration}
        onCancel={() => setDeletingSectionRegistration(undefined)}
        onRequestError={() => setDeletingSectionRegistration(undefined)}
        onSave={() => {
          setLoading(true);
          setDeletingSectionRegistration(undefined);
        }}
      />

      {changingSectionFrom && (
        <ModalSectionChange
          isOpen={!!changingSectionFrom}
          accountId={Number(accountId)}
          currentCourseRegistration={changingSectionFrom}
          onCancel={() => setChangingSectionFrom(undefined)}
          onSave={() => {
            setLoading(true);
            setChangingSectionFrom(undefined);
          }}
        />
      )}
    </Card>
  );
};

export default DetailCourseRegistration;
