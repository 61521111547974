import { useEffect, useState, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  SearchAccordion,
  SearchBox,
  useMobile,
} from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useValidations } from '../../../hooks/useValidations';
import { getStudyPlanVersions } from '../../../api/requests/studyPlans';

export type Filters = {
  rut_or_passport: string | null;
  search_text: string | null;
  study_plan: number | null;
};

type FormValues = {
  rut_or_passport: string | null;
  search_text: string | null;
  study_plan: SelectOptionType | null;
};

const DEFAULT_VALUES = {
  rut_or_passport: null,
  search_text: null,
  study_plan: null,
};

interface Props {
  onFiltersChange: (filterValues: Filters) => void;
}

export default function SearchFilters({ onFiltersChange = () => null }: Props) {
  const { t } = useTranslation();
  const prefix = 'courseRegistrationList.search';

  const isMobile = useMobile();
  const [studyPlans, setStudyPlans] = useState<SelectOptionType[]>([]);

  const { handleSubmit, reset, watch, setValue, control, formState } =
    useForm<FormValues>({
      defaultValues: DEFAULT_VALUES,
      mode: 'onChange',
    });
  const currentFiltersValues = watch();
  const lastEmittedFilters = useRef<FormValues>();
  const { validateMinLength } = useValidations();

  const cleanFilters = () => {
    // No se disparara si ya los filtros estaban vacios
    const needToClean = Object.values(currentFiltersValues).some(
      (value) => value !== null,
    );

    if (needToClean) {
      reset();
      onFiltersChange(DEFAULT_VALUES);
    }
  };

  const onSubmit = useCallback((newValues: FormValues) => {
    lastEmittedFilters.current = { ...newValues };

    const study_plan = newValues?.study_plan?.value
      ? Number(newValues?.study_plan.value)
      : null;

    onFiltersChange({
      ...newValues,
      study_plan,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasSomeFilterValue = () => {
    return Object.values(currentFiltersValues).some(
      (filterValue) => filterValue !== null,
    );
  };

  // El valor por defecto de los filtros es null, si se vacian se devuelven a null
  useEffect(() => {
    for (const filter in currentFiltersValues) {
      if (currentFiltersValues[filter as keyof Filters] === '') {
        setValue(filter as keyof Filters, null);
      }
    }
  }, [currentFiltersValues, setValue, onSubmit]);

  // Se solicito que los filtros refrescaran la busqueda cuando se vaciaran despues del primer submit
  useEffect(() => {
    let changesToEmit: Partial<FormValues> = {};

    for (const filter in currentFiltersValues) {
      const currFilterValue = currentFiltersValues[filter as keyof Filters];
      const prevFilterValue =
        lastEmittedFilters.current &&
        lastEmittedFilters.current[filter as keyof Filters];

      if (
        formState.isSubmitted &&
        currFilterValue !== prevFilterValue &&
        currFilterValue === null
      ) {
        onSubmit({ ...currentFiltersValues, ...changesToEmit });
      }
    }
  }, [currentFiltersValues, onSubmit, formState.isSubmitted]);

  // El rut y pasaporte no lleva ni guiones ni puntos, se remueven automaticamente
  useEffect(() => {
    const currVal = currentFiltersValues.rut_or_passport;

    if (currVal) {
      const cleaned = currVal.replace('-', '').replace('.', '');
      setValue('rut_or_passport', cleaned);
    }
  }, [currentFiltersValues.rut_or_passport, setValue]);

  // Pidiendo el listado de planes de estudio (versiones)
  useEffect(() => {
    const fetchStudyPlans = async () => {
      const { data } = await getStudyPlanVersions();

      const options = data?.data.map((sp: any) => ({
        label: sp.name,
        value: sp.id,
      }));

      setStudyPlans(options || []);
    };

    fetchStudyPlans();
  }, []);

  return (
    <div
      className={`course-registration-search-filters ${isMobile ? 'mb-4' : ''}`}
    >
      <SearchAccordion btnText={t(`${prefix}.title`)}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`d-flex align-items-start ${isMobile ? 'flex-wrap' : ''}`}
          style={{ gap: isMobile ? 0 : 12 }}
        >
          <div className={isMobile ? 'w-100' : 'w-25'}>
            <SearchBox
              name="rut_or_passport"
              label={t(`${prefix}.code`)}
              placeholder={t(`${prefix}.codePlaceholder`)}
              control={control}
              rules={{
                validate: {
                  minLength: validateMinLength(3),
                },
              }}
            />
          </div>
          <div className={isMobile ? 'w-100' : 'w-25'}>
            <SearchBox
              name="search_text"
              label={t(`${prefix}.name`)}
              placeholder={t(`${prefix}.namePlaceholder`)}
              control={control}
              rules={{
                validate: {
                  minLength: validateMinLength(3),
                },
              }}
            />
          </div>
          <div className={isMobile ? 'w-100' : 'w-25'}>
            <OutlinedSelect
              name="study_plan"
              options={studyPlans}
              label={t(`${prefix}.studyPlan`)}
              control={control}
            />
          </div>
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="sm"
            style={{
              width: isMobile ? '100%' : 125,
              height: 41,
              marginTop: isMobile ? 0 : '1.5rem',
            }}
            disabled={
              (!formState.isSubmitted && !hasSomeFilterValue()) ||
              Object.keys(formState.errors).length > 0
            }
          />
          <Button
            text={t(`common.actions.clean`)}
            size="sm"
            outlined
            onClick={cleanFilters}
            style={{
              width: isMobile ? '100%' : 125,
              height: 41,
              marginTop: isMobile ? '1rem' : '1.5rem',
            }}
          />
        </form>
      </SearchAccordion>
    </div>
  );
}
