import { useEffect, useState } from 'react';
import { getPeriod } from '../../api/requests/periods';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';

export interface PeriodRecord {
  id: number;
  code: string;
  name: string;
  description?: string | null;
  startDate: string;
  endDate: string;
  academicCalendars: {
    eventCalendar: { id: number; name: string; interval: string };
    startDate: string;
    endDate: string;
  }[];
}

export interface PeriodRecordLoaderProps {
  periodId: number | string;
  children: (period: PeriodRecord) => JSX.Element;
}

export const PeriodRecordLoader = (props: PeriodRecordLoaderProps) => {
  const { periodId, children } = props;
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState<PeriodRecord | null>(null);
  const [error, setError] = useState<'HTTP_ERROR' | 'CONNECTION' | null>(null);

  useEffect(() => {
    if (periodId) {
      setLoading(true);
      getPeriod(periodId)
        .then((response) => {
          if (response.data) {
            setPeriod(response.data.data);
          } else if (response.error) {
            setError(response.error.code);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [periodId]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <DisplayError textBody={error} />;
  }

  if (!period) {
    return <DisplayError textBody="HTTP_ERROR" />;
  }

  return children(period);
};
