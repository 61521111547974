import { addToast, Button } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { updatePeriodRequest } from '../../api/requests/periods';
import { useLayoutState } from '../../hooks/useLayoutState';
import PeriodsForm from './PeriodsForm';
import { PeriodRecordLoader } from './PeriodRecordLoader';

import { PermissionName } from '../../types/Auth';
import useUserState from '../../hooks/useUserState';
import GoBackButton from '../../components/buttons/GoBackButton';

const PeriodRecordEdit = () => {
  const { isAuthorizedTo } = useUserState();
  const { hideTabs, hideErrorModal } = useLayoutState();
  useEffect(() => {
    hideTabs();
    hideErrorModal();
  }, [hideTabs, hideErrorModal]);

  let history = useHistory();
  const goBack = useCallback(() => {
    history.push(`/maintainers/periods`);
  }, [history]);

  const prefix = 'maintainers.periodsForm';
  const { t } = useTranslation();

  const { id: periodId } = useParams<{ id?: string }>();

  const onSubmit = useCallback(
    async (values) => {
      if (!periodId) {
        return;
      }
      const response = await updatePeriodRequest(periodId, values);
      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.editSuccess`),
        });
        goBack();
      } else {
        return response.error.code;
      }
    },
    [periodId, goBack, t],
  );

  if (!periodId) {
    return <Redirect to="/maintainers/periods/record" />;
  }

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={goBack} />
        </div>
        <PeriodRecordLoader periodId={periodId}>
          {(period) => {
            const defaultValues = {
              code: period.code,
              name: period.name,
              description: period.description ?? '',
              startDate: period.startDate,
              endDate: period.endDate,
            };
            return (
              <PeriodsForm
                defaultAcademicCalendars={period.academicCalendars}
                defaultValues={defaultValues}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Row className="pt-5">
                    <Col xs={12} md={4} className="ml-auto">
                      <div>
                        <Button
                          type="submit"
                          text={t(`${prefix}.editPeriod`)}
                          loading={isSubmitting}
                          fullwidth
                          disabled={
                            !isAuthorizedTo([PermissionName.EDIT_PERIOD])
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </PeriodsForm>
            );
          }}
        </PeriodRecordLoader>
      </Card>
    </Container>
  );
};

export default PeriodRecordEdit;
