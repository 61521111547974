import { POSTULATION_STATUS } from '../../../types/postulationDetailStatus';

const findIndexStatus = (status: string, array: Array<any>) => {
  let findStatus = [
    POSTULATION_STATUS.IN_PROCESS,
    POSTULATION_STATUS.IN_WAITING_LIST,
    POSTULATION_STATUS.DESISTED,
    POSTULATION_STATUS.ENROLLED,
    POSTULATION_STATUS.CHANGE_STUDY_PLAN,
    POSTULATION_STATUS.NO_POSTULATION,
  ];
  let index = findStatus.findIndex((e) => e === status);
  if (index !== -1) {
    return array[index];
  } else {
    return array[array.length - 1];
  }
};

export { findIndexStatus };
