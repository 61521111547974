import { OutlinedSelectOptionType, Select } from '@octano/global-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import DisplayError from '../../../components/info/DisplayError';
import { isNumber } from '../../../utils/math';
import { PeriodSelectorForm } from '../types';

export const PeriodsSelector = ({
  periods,
  currentPeriod,
  children,
}: PeriodSelectorForm) => {
  const { t } = useTranslation();

  const options = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [periods]);

  const [value, setValue] = useState(() => {
    const option = options.find((o) => o.value === currentPeriod?.id);
    if (option) {
      return option;
    }
    if (options.length > 0) {
      return options[0];
    }
    return undefined;
  });

  const periodId = value?.value;

  if (Array.isArray(options) && options.length === 0) {
    return (
      <DisplayError
        insideCard
        textBody="No hay eventos de matricula de continuidad definidos. Intenta mas tarde"
      />
    );
  }

  if (!periodId || !isNumber(periodId)) {
    return (
      <DisplayError
        insideCard
        textBody="Ha ocurrido un error al seleccionar el periodId"
      />
    );
  }

  return (
    <>
      <div id="admissionsPlanesList">
        <div className="g-table-container mt-2 pb-4 pt-4">
          <div className="mb-5">
            {t('tuitionContinuityProcessManagement.selectFilters')}
          </div>
          <Row>
            <Col md={6} className="pb-3" lg={6}>
              <Select
                label={t('packages.period')}
                name="period"
                options={options}
                value={value}
                onChange={(v) => {
                  setValue(v);
                }}
                isClearable={false}
              />
            </Col>
            {children && children(periodId)}
          </Row>
        </div>
      </div>
    </>
  );
};
