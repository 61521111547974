import {
  Button,
  Select,
  SelectOptionType,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import React, { useEffect } from 'react';
import { Control, DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { SectionTitle } from '../../text';
import {
  FormField,
  FormSection,
  validationSchema,
} from '../validations/validations';

function RenderFields(
  section: FormSection,
  control: Control<any>,
  options?: Record<string, SelectOptionType[]>,
) {
  return section.fields.map((field: FormField, index: number) => (
    <Col key={index} md={field.grid.md} xs={field.grid.xs}>
      <FormGroup>
        {field.component === 'text' && (
          <TextInput
            {...{ ...field.properties, control }}
            name={field.name}
            label={field.label}
            maxLength={field.validations?.maxLength}
            rules={validationSchema(field.validations)}
            placeholder={field?.placeholder}
          />
        )}
        {field.component === 'select' && (
          <Select
            {...{ ...field.properties, control }}
            name={field.name}
            label={field.label}
            options={(options && options[field.name]) ?? []}
            rules={validationSchema(field.validations)}
            placeholder={field?.placeholder}
          />
        )}
        {field.component === 'textarea' && (
          <TextAreaInput
            {...{ ...field.properties, control }}
            name={field.name}
            label={field.label}
            maxLength={field.validations?.maxLength}
            rules={validationSchema(field.validations)}
            placeholder={field?.placeholder}
          />
        )}
      </FormGroup>
    </Col>
  ));
}

interface RenderFormProps {
  structure: any;
  defaultValues: DefaultValues<any>;
  optionalValues: DefaultValues<any>;
  options?: Record<string, SelectOptionType[]>;
  sectionComponents?: Record<string, JSX.Element>;
  onSubmit: (data: any) => Promise<void>;
  onErrors: (error: string[]) => void;
}

function RenderForm(props: RenderFormProps) {
  const {
    structure,
    defaultValues,
    optionalValues,
    sectionComponents,
    options,
    onErrors,
    onSubmit,
  } = props;
  const history = useHistory();

  const methods = useForm<{ hasOptionalValues: boolean; [key: string]: any }>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
  });

  const { formState, setValue } = methods;

  const hasOptionalValues = methods.watch('hasOptionalValues');

  useEffect(() => {
    if (hasOptionalValues && Boolean(optionalValues)) {
      Object.entries(optionalValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [hasOptionalValues, optionalValues, setValue]);

  const fieldNames = structure.flatMap((section: any) =>
    section.fields.map((field: any) => field.name),
  );

  structure
    .flatMap((section: any) => section.fields)
    .forEach((field: any) => {
      field.dependsOn?.forEach((dependency: any) => {
        if (!fieldNames.includes(dependency)) {
          onErrors([
            `El campo "${field.name}" depende de "${dependency}", que no está en el formulario.`,
          ]);
        }
      });
    });

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {structure.map((section: FormSection, index: number) =>
          section.component && sectionComponents ? (
            sectionComponents[section.component]
          ) : (
            <div key={index} className="pt-5">
              <SectionTitle text={section.title} />
              <Row>{RenderFields(section, methods.control, options)}</Row>
            </div>
          ),
        )}

        <Row className="py-5 justify-content-end">
          <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
            <Button
              type="button"
              outlined
              onClick={() => history.push('/tuition-process')}
              text="Cancelar"
              disabled={formState.isSubmitting}
              fullwidth
            />
          </Col>

          <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
            <Button
              type="submit"
              text="Siguiente"
              loading={formState.isSubmitting}
              disabled={formState.isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
}

export default RenderForm;
