import { SelectOptionType } from '@octano/global-ui';
import { useEffect, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import { Card } from 'reactstrap';
import { getFormStructure } from '../../api/requests/dynamicForm';
import DisplayError from '../info/DisplayError';
import Loading from '../info/Loading';
import RenderForm from './parts/RenderSections';

interface DynamicFormProps {
  defaultValues?: DefaultValues<any>;
  /**
   * optionalValues corresponde a valores por defecto
   * que dependen de una condicion para mostrarse.
   */
  optionalValues?: DefaultValues<any>;
  /**
   * Cuando dynamic form recibe un objeto con las opciones,
   * estas se mostrarán el el campo que coincida con el nombre
   * de la propiedad.
   */
  options?: Record<string, SelectOptionType[]>;
  /**
   * sectionComponents en el json se puede definir un componente a una seccion
   * cuando esto ocurre es necesario pasar un objeto con componentes a renderizar.
   */
  sectionComponents?: Record<string, JSX.Element>;
  dynamicFormId: number;
  postulantId: number;
  onSubmit: (data: any) => Promise<void>;
}

function DynamicForm(props: DynamicFormProps) {
  const {
    dynamicFormId,
    postulantId,
    defaultValues,
    optionalValues,
    sectionComponents,
    options,
    onSubmit,
  } = props;

  const [error, setError] = useState<string[]>();
  const [structure, setStructure] = useState<any>([]);

  useEffect(() => {
    async function fetchFormularioConfig() {
      try {
        //obtenemos la estructura del formulario
        const formStructure = await getFormStructure(dynamicFormId);
        //obtenemos las respuesta del formulario
        setStructure(formStructure.data.sections);
      } catch (error) {
        console.error('Error al cargar la configuración del formulario', error);
      }
    }

    fetchFormularioConfig();
  }, [dynamicFormId, postulantId]);

  if (error) {
    return <DisplayError insideCard textBody={'Error de dependencias'} />;
  }

  if (!structure) {
    return <Loading insideCard />;
  }

  return (
    <Card>
      <RenderForm
        sectionComponents={sectionComponents}
        structure={structure}
        onErrors={setError}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        optionalValues={optionalValues}
        options={options}
      />
    </Card>
  );
}
export default DynamicForm;
