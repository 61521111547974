import { addToast, Button } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import {
  getPreviewDocument,
  getSignDocumentation,
  postRequestSignDocument,
  tokenSignPreviewDocument,
  verifySignDocumentation,
} from '../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { useStepState } from '../../../../components/step/useStepState';
import { SectionTitle } from '../../../../components/text';
import { useLoadingState } from '../../../../hooks/useLoadingState';
import { RequestedDocument } from '../../../../types/signDocumentationTypes';
import { STATUS_POSTULATION } from '../../../../types/tuitionProcessOnSite';
import { useTuitionProcessNoSua } from '../../hooks/useTuitionProcessNoSua';
import HeaderStep from '../HeaderStep';
import DocumentCard from './DocumentCard';

/**
 * Componente que corresponde a la vista principal del paso de contrato y subida de archivos
 */
const DocumentationNoSua = () => {
  const prefix = 'tuitionProcessNoSua.documentation';
  const { t } = useTranslation();
  const { nextStep } = useStepState();
  const { postulationDetail, statusPostulation } = useTuitionProcessNoSua();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [requestedDocuments, setRequestedDocuments] = useState<
    RequestedDocument[]
  >([]);

  const [showErrors, setShowErrors] = useState<boolean>(false);

  const getStatus = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getSignDocumentation(postulationDetail?.id);
    if (data) {
      setRequestedDocuments(data.requestedDocuments);
      setErrorLoading(undefined);
    }
    if (error) {
      setErrorLoading(t('common.displayError.errorUnexpected'));
    }
    setLoading(false);
  }, [setLoading, setErrorLoading, t, postulationDetail]);

  useEffect(() => window.scrollTo(0, 0));

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const onSubmit = async () => {
    if (!postulationDetail) {
      return;
    }
    if (requestedDocuments.some((r) => r.document.required && !r.uploaded)) {
      setShowErrors(true);
    } else {
      setShowErrors(false);
      const { error } = await verifySignDocumentation(postulationDetail.id);
      if (!error) {
        nextStep();
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.nextStepError`),
        });
      }
    }
  };

  const updateState = useCallback(
    (
      property: 'downloaded' | 'uploaded',
      requestedDocument: RequestedDocument,
    ) => {
      setRequestedDocuments((requestedDocuments) => {
        const index = requestedDocuments.findIndex(
          (r) => r === requestedDocument,
        );
        if (index < 0) {
          return [...requestedDocuments];
        }
        const newRequestedDocuments = [...requestedDocuments];
        const newRequestedDocument: RequestedDocument = {
          ...requestedDocument,
          [property]: true,
        };
        newRequestedDocuments[index] = newRequestedDocument;
        return newRequestedDocuments;
      });
    },
    [],
  );

  const setDownloaded = useCallback(
    (requestedDocument: RequestedDocument) =>
      updateState('downloaded', requestedDocument),
    [updateState],
  );
  const setUploaded = useCallback(
    (requestedDocument: RequestedDocument) =>
      updateState('uploaded', requestedDocument),
    [updateState],
  );

  const downloadDocument = useCallback(
    async (requestedDocument: RequestedDocument) => {
      if (!postulationDetail) {
        return;
      }
      const { data, error } = await tokenSignPreviewDocument(
        postulationDetail.id,
        requestedDocument.document.code,
      );
      if (data && !error) {
        const url = getPreviewDocument(postulationDetail.id, data);
        window.open(url);
        setDownloaded(requestedDocument);
      } else if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.errors.download`),
        });
      }
    },
    [postulationDetail, t, setDownloaded],
  );

  const requestSign = useCallback(
    async (requestedDocument: RequestedDocument) => {
      if (!postulationDetail) {
        return;
      }
      const { error } = await postRequestSignDocument(
        postulationDetail.id,
        requestedDocument.document.code,
      );
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.errors.download`),
        });
      } else {
        addToast({
          icon: 'information',
          color: 'success',
          text: t(`${prefix}.requestSignSuccess`),
        });
      }
    },
    [postulationDetail, t],
  );

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={getStatus}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  if (!postulationDetail?.id) {
    return (
      <DisplayError
        insideCard
        textBody="postulationDetailId no indicado"
        retryAction={getStatus}
        loadingAction={loading}
      />
    );
  }
  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <HeaderStep
          prefixStep={prefix}
          showBackBtn={
            statusPostulation !== STATUS_POSTULATION.STUDY_PLAN_CHANGE
          }
        />
        <Row>
          <Col xs={12}>
            <SectionTitle text={t(`${prefix}.title`)} />
          </Col>
          <Col xs={12} className="pb-2">
            <p className="fs-18 text-light fw-300">
              {t(`${prefix}.description`)}
            </p>
          </Col>
        </Row>

        {/* Listado de documentos a adjuntar */}
        <Row className="mx-n2 align-items-end">
          {requestedDocuments.map((requestedDocument) => {
            const size = requestedDocument.document.generated ? 'md' : 'sm';
            return (
              <Col xs={12} md={6} lg={size === 'md' ? 6 : 3} className="mb-3">
                <DocumentCard
                  postulationDetailId={postulationDetail?.id}
                  errorText={undefined}
                  onDownload={downloadDocument}
                  onUploaded={setUploaded}
                  onRequestSign={requestSign}
                  requestedDocument={requestedDocument}
                  requiredDocument={requestedDocuments?.find(
                    (e) =>
                      e?.document?.id === requestedDocument?.requiredDocumentId,
                  )}
                  size={size}
                  showErrors={
                    requestedDocument.document.required ? showErrors : false
                  }
                />
              </Col>
            );
          })}
        </Row>
        <Row className="pt-3 pb-5 justify-content-end">
          <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
            <Button
              type="button"
              outlined
              onClick={() => getStatus()}
              text={t(`common.actions.reloadPage`)}
              fullwidth
            />
          </Col>
          <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
            <Button
              text={t(`${prefix}.btnNext`)}
              onClick={onSubmit}
              fullwidth
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default DocumentationNoSua;
