export interface StudentPetitionsRow {
  folio: number;
  createdAt: Date;
  studyPlanVersionName: string;
  typeId: number;
  typeName: string;
  status: PetitionStatus;
  title: string;
  studentName?: string;
  studentRun?: string;
}

export enum PetitionStatus {
  Received = 'recibida',
  InProcess = 'en_proceso',
  Waiting = 'en_espera',
  Resolved = 'resuelta',
}

export interface StudentPetitionsResponse {
  data: StudentPetitionsRow[];
  total_pages: number;
  total: number;
}

interface StudentPetitionsFilter {
  status: string[];
  studyPlans: { id: number; name: string }[];
}

export interface StudentPetitionsFilterResponse {
  data: StudentPetitionsFilter;
}

interface StudentPetitionsExcelFilter extends StudentPetitionsFilter {
  petitionTypes: { id: number; name: string }[];
}

export interface StudentPetitionsExcelFilterResponse {
  data: StudentPetitionsExcelFilter;
}
