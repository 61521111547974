import { useCallback } from 'react';
import { Button, OutlinedSelect, TextOutlinedInput } from '@octano/global-ui';
import { Form, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useParameters } from '../../hooks/useParameters';
import { useValidations } from '../../hooks/useValidations';

export interface TuitionSearchStudentValues {
  country: string;
  passport: string;
}

export interface TuitionSearchStudentFormProps {
  onSubmit: (values: TuitionSearchStudentValues) => void;
}

export const TuitionProcessSearchStudentByPassport = (
  props: TuitionSearchStudentFormProps,
) => {
  const { onSubmit: onSubmitProp } = props;
  const { t } = useTranslation();
  const { control, handleSubmit, formState } =
    useForm<TuitionSearchStudentValues>({
      mode: 'all',
    });
  const { countryOptions } = useParameters();
  const { validateTextNotEmpty } = useValidations();

  const onSubmit = useCallback(
    (values) => {
      onSubmitProp({
        country: values.country.value,
        passport: values.passport,
      });
    },
    [onSubmitProp],
  );

  const { isDirty, isValid } = formState;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <label>{t('tuitionProcess.searchStudent.inputPassport')}</label>
      <Row>
        <Col md={8} lg={6}>
          <Row>
            <Col xs={4} sm={3} className="pr-1">
              <OutlinedSelect
                name="country"
                isSearchable={true}
                placeholder={t('tuitionProcess.searchStudent.inputCountry')}
                control={control}
                options={countryOptions}
                rules={{ required: 'Campo requerido' }}
              />
            </Col>
            <Col className="pl-1">
              <TextOutlinedInput
                placeholder={t('tuitionProcess.searchStudent.inputPassport')}
                name="passport"
                control={control}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
            <Col xs={3} sm={3} style={{ padding: '0px', paddingRight: '16px' }}>
              <Button
                size="sm"
                type="submit"
                text={t(`common.actions.search`)}
                fullwidth
                disabled={!isDirty || !isValid}
                style={{ height: '42px' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
