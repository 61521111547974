import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SearchBox,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import { studentsPetitionsListFilter } from '../../../../api/requests/studentPetitions';
import { useValidations } from '../../../../hooks/useValidations';
import { SearchParams } from '../../types/Petitions';

const dictPrefix = 'studentPetitions.received.searchControls';

const DEFAULT_VALUES = {
  search: '',
  studyPlanId: null,
  status: null,
};

interface FormValues {
  search: string;
  studyPlanId: OutlinedSelectOptionType | null;
  status: OutlinedSelectOptionType | null;
}

type onSearch = {
  searchParams: SearchParams;
  page: number;
};

interface SearchControlsProps {
  onSearch?: ({ searchParams, page }: onSearch) => void;
  onClear?: () => void;
  setFilters?: (state: any) => void;
}

export function SearchControls({
  onSearch = () => null,
  onClear = () => null,
  setFilters = (state: any) => null,
}: SearchControlsProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { validateMinLength } = useValidations();

  const [states, setStates] = useState<OutlinedSelectOptionType[]>([]);
  const [studyPlans, setStudyPlans] = useState<OutlinedSelectOptionType[]>([]);

  const { handleSubmit, control, reset, watch } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const [searchWatch, statusWatch, studyPlanIdWatch] = watch([
    'search',
    'status',
    'studyPlanId',
  ]);

  useEffect(() => {
    const searchParams = {
      search: searchWatch?.trim() !== '' ? searchWatch : undefined,
      status: statusWatch?.value as number,
      studyPlanId: studyPlanIdWatch?.value as number,
    };

    setFilters({
      ...searchParams,
      hasFilters: Object.values(searchParams).some((value) => value !== null),
    });
  }, [searchWatch, statusWatch, studyPlanIdWatch, setFilters]);

  const handleSearch = (values: FormValues) => {
    const searchParams = {
      search: values.search?.trim() !== '' ? values.search : undefined,
      status: values.status?.value as string,
      studyPlanId: values.studyPlanId?.value as number,
      hasFilters: Object.values(values).some((value) => value !== null),
    };

    onSearch({ searchParams, page: 0 });
  };

  const handleClear = () => {
    reset(DEFAULT_VALUES);
    onClear();
    onSearch({ searchParams: {}, page: 0 });
  };

  const getFilters = useCallback(async () => {
    const { data } = await studentsPetitionsListFilter();
    if (data) {
      setStates(
        data.data.status.map((value: string) => ({
          label: t(`${dictPrefix}.status.${value}`),
          value: value,
        })),
      );
      setStudyPlans(
        data.data.studyPlans.map(({ id: value, name: label }) => ({
          label,
          value,
        })),
      );
      reset();
    }
  }, [t, reset]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      className={`d-flex align-items-start ${isMobile ? 'flex-wrap' : ''}`}
      style={{ gap: isMobile ? 0 : 12 }}
    >
      <Card className={isMobile ? 'w-100' : 'w-25'}>
        <OutlinedSelect
          name="studyPlanId"
          label={t(`${dictPrefix}.studyPlan`)}
          placeholder={t(`${dictPrefix}.studyPlan`)}
          control={control}
          options={studyPlans}
        />
      </Card>

      <Card className={isMobile ? 'w-100' : 'w-25'}>
        <SearchBox
          name="search"
          label={t(`${dictPrefix}.nameOrId`)}
          placeholder={t(`${dictPrefix}.nameOrIdPlaceholder`)}
          control={control}
          rules={{
            validate: {
              minLength: validateMinLength(3),
            },
          }}
        />
      </Card>

      <Card className={isMobile ? 'w-100' : 'w-25'}>
        <OutlinedSelect
          name="status"
          label={t(`${dictPrefix}.state`)}
          placeholder={t(`${dictPrefix}.state`)}
          control={control}
          options={states}
        />
      </Card>

      <Card
        className={
          isMobile ? 'w-100' : 'w-25 d-flex flex-row justify-content-between'
        }
        style={{
          marginTop: isMobile ? 0 : '1.5rem',
        }}
      >
        <Button
          type="submit"
          text={t(`common.actions.search`)}
          size="md"
          style={{
            width: isMobile ? '100%' : '48%',
          }}
        />
        <Button
          text={t(`common.actions.clean`)}
          size="md"
          outlined
          onClick={handleClear}
          style={{
            marginTop: isMobile ? '1rem' : '0',
            width: isMobile ? '100%' : '48%',
          }}
        />
      </Card>
    </form>
  );
}
