import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import GoBackToListButton from '../../components/buttons/GoBackToListButton';
import { CoursesBySemesters, Resume } from '../../components/curriculum';
import Legends, { LegendsProps } from '../../components/curriculum/Legends';
import { ResumeProps } from '../../components/curriculum/Resume';
import { SectionTitle } from '../../components/text';
import { PathsLayouts } from '../../config/routes';
import { isNumber } from '../../utils/math';
import BaseLoader from './parts/BaseLoader';
import CourseHistory from './parts/CourseHistory';
import ProgressByCurrentPeriod from './parts/ProgressByCurrentPeriod';
import StudyPlanBox from './parts/StudyPlanBox';

export default function CurriculumProgressStudent() {
  const prefix = 'curriculumProgressStudent';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      body: t(`${prefix}.body`),
      resumeTitle: t(`${prefix}.resume.title`),
      curriculumTitle: t(`${prefix}.curriculum.title`),
      semester: t(`${prefix}.curriculum.semester`),
      credits: t(`${prefix}.curriculum.credits`),
      box: {
        student: t(`${prefix}.box.student`),
        rut: t(`${prefix}.box.rut`),
        studyPlan: t(`${prefix}.box.studyPlan`),
      },
      tab: {
        progress: t(`${prefix}.tab.progress`),
        curriculum: t(`${prefix}.tab.curriculum`),
        history: t(`${prefix}.tab.history`),
      },
      resume: {
        ...t<string, ResumeProps['texts']>(`${prefix}.resume`, {
          returnObjects: true,
        }),
        durationValue: (duration: number) =>
          t(`${prefix}.resume.durationValue`, { count: duration }),
      },
      curriculumStatus: t<string, LegendsProps['texts']>(
        `${prefix}.curriculum.status`,
        {
          returnObjects: true,
        },
      ),
      progress: {
        table: {
          columns: {
            section: t(`${prefix}.progress.section`),
            courseCode: t(`${prefix}.progress.courseCode`),
            generalPassedAverage: t(`${prefix}.progress.generalPassedAverage`),
            averageGradeToDate: t(`${prefix}.progress.averageGradeToDate`),
            progressPorcentInGrades: t(
              `${prefix}.progress.progressPorcentInGrades`,
            ),
            averageAttendanceToDate: t(
              `${prefix}.progress.averageAttendanceToDate`,
            ),
            attendanceCatedral: t(`${prefix}.progress.attendanceCatedral`),
            attendanceLaboratory: t(`${prefix}.progress.attendanceLaboratory`),
            attendanceAssistantship: t(
              `${prefix}.progress.attendanceAssistantship`,
            ),
          },
          tooltips: {
            attendanceCatedral: t(
              `${prefix}.progress.tooltip.attendanceCatedral`,
            ),
            attendanceLaboratory: t(
              `${prefix}.progress.tooltip.attendanceLaboratory`,
            ),
            attendanceAssistantship: t(
              `${prefix}.progress.tooltip.attendanceAssistantship`,
            ),
          },
          notResult: {
            title: t(`${prefix}.progress.notResult.title`),
            subtitle: t(`${prefix}.progress.notResult.subtitle`),
          },
        },
      },
      history: {
        generalAverage: t(`${prefix}.history.generalPassedAverage`),
        coursesValidated: t(`${prefix}.history.coursesValidated`),
        noCourses: t(`${prefix}.history.noCourses`),
        download: {
          button: t(`${prefix}.history.downloadHistory`),
          failed: t(`${prefix}.history.downloadFailed`),
          success: t(`${prefix}.history.downloadSuccess`),
        },
      },
    }),
    [t],
  );

  const tabs = {
    progress: 'progress',
    curriculum: 'curriculum',
    history: 'history',
  };

  const { studyPlanEnrollmentId: studyPlanEnrollmentIdStr } =
    useParams<{ studyPlanEnrollmentId: string }>();

  const studyPlanEnrollmentId = useMemo(() => {
    if (isNumber(studyPlanEnrollmentIdStr)) {
      return Number(studyPlanEnrollmentIdStr);
    }
    return null;
  }, [studyPlanEnrollmentIdStr]);

  if (!studyPlanEnrollmentId) {
    return <Redirect to={PathsLayouts.curriculumProgress} />;
  }

  return (
    <Card className="p-4 mt-2 mx-3">
      <BaseLoader studyPlanEnrollmentId={studyPlanEnrollmentId}>
        {(data) => (
          <>
            <Row>
              <Col xs={12} sm={3} className="pb-2">
                <GoBackToListButton path={PathsLayouts.curriculumProgress} />
              </Col>
              <Col xs={12} sm={9} className="pb-2">
                <StudyPlanBox
                  studentName={`${data.student.lastnames}, ${data.student.name}`}
                  studentRut={data.student.rut}
                  studyPlanName={data.progress.studyPlanVersion.name}
                  texts={texts.box}
                />
              </Col>
            </Row>
            <p className="text-medium fs-16 mt-3 mb-5">{texts.body}</p>
            <Tabs size="md" defaultTab={tabs.curriculum}>
              <TabsList>
                <Tab id={tabs.progress}>{texts.tab.progress}</Tab>
                <Tab id={tabs.curriculum}>{texts.tab.curriculum}</Tab>
                <Tab id={tabs.history}>{texts.tab.history}</Tab>
              </TabsList>
              <br className="mt-4" />
              <TabPanels>
                <TabPanel id={tabs.progress}>
                  <ProgressByCurrentPeriod
                    progressCurrentPeriod={data.progressCurrentPeriod}
                    studyPlanEnrollmentId={studyPlanEnrollmentId}
                    texts={texts.progress}
                  />
                </TabPanel>
                <TabPanel id={tabs.curriculum}>
                  <>
                    {/* DATOS DE LA MALLA */}
                    <div className="mb-4">
                      <div className="mb-3">
                        <SectionTitle text={texts.resumeTitle} />
                      </div>
                      <Resume texts={texts.resume} {...data.progress} />
                    </div>

                    {/* MALLA DE ESTUDIOS */}
                    <div>
                      <div className="mb-3">
                        <SectionTitle text={texts.curriculumTitle} />
                      </div>
                      <div className="mb-3">
                        <CoursesBySemesters
                          semesters={data.progress.semesters}
                          texts={texts}
                        />
                      </div>
                      <Legends texts={texts.curriculumStatus} />
                    </div>
                  </>
                </TabPanel>
                <TabPanel id={tabs.history}>
                  <CourseHistory
                    coursesValidated={data.history.coursesValidated}
                    periods={data.history.periods}
                    generalAverage={data.history.generalAvg}
                    studyPlanEnrollmentId={studyPlanEnrollmentId}
                    texts={texts.history}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </BaseLoader>
    </Card>
  );
}
