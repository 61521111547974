import { OutlinedSelectOptionType } from '@octano/global-ui';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTcDocumentReviewList } from '../../../api/requests/tuitionContinuityDocumentReview';
import {
  GetTuitionContinuityProcessManagemenResponse,
  TuitionContinuityProcessManagementErrors,
  getPeriodOFContinuity as getBase,
} from '../../../api/requests/tuitionContinuityProcessManagement';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';
import {
  TcDocumentReviewPeriodSelect,
  TcDocumentReviewResponse,
} from '../../../types/tuitionContinuityDocumentReview';
import { TuitionContinuityDocumentReviewDetailProps } from './TuitionContinuityDocumentReviewDetail';

export interface TuitionContinuityDocumentReviewLoaderProps {
  children: (props: {
    loading: boolean;
    offerDetail: TuitionContinuityDocumentReviewDetailProps[];
    periods: TcDocumentReviewPeriodSelect;
    onChangePeriod: (data: OutlinedSelectOptionType) => void;
    schoolOptions: OutlinedSelectOptionType[];
    onChangeSchool: (op?: OutlinedSelectOptionType) => void;
  }) => ReactElement;
}

export function TuitionContinuityDocumentReviewLoader({
  children,
}: TuitionContinuityDocumentReviewLoaderProps) {
  const prefix = 'TuitionContinuityDocumentReview';
  const { t } = useTranslation();

  const { loading, setLoading } = useLoadingState();

  const { loading: offerLoading, setLoading: setOfferLoading } =
    useLoadingState(false);

  const [allOfferDetail, setAllOfferDetail] = useState<
    TcDocumentReviewResponse[]
  >([]);

  const [selectedSchool, setSelectedSchool] = useState<
    number | string | undefined
  >();

  const [schools, setSchool] = useState<OutlinedSelectOptionType[]>([]);

  const [periodList, setPeriodList] = useState<TcDocumentReviewPeriodSelect>();
  const [error, setError] =
    useState<TuitionContinuityProcessManagementErrors>();

  const text = useMemo(
    () => ({
      error: t(`${prefix}.error.load`),
      total: t(`${prefix}.details.total`),
    }),
    [t],
  );

  const loadOfferDetail = useCallback(
    async (periodId: number | string) => {
      setOfferLoading(true);
      const { data, error } = await getTcDocumentReviewList(periodId);
      if (error) {
        setError(error);
      }
      if (data) {
        setError(undefined);
        setAllOfferDetail(data.data);
        setSchool(
          data.data.reduce((acc, value) => {
            if (!acc.find((x) => x.value === value.schoolId)) {
              acc.push({ value: value.schoolId, label: value.schoolName });
            }
            return acc;
          }, new Array<OutlinedSelectOptionType>()),
        );
      }
      setOfferLoading(false);
    },
    [setOfferLoading],
  );

  const offerDetail = useMemo<TuitionContinuityDocumentReviewDetailProps[]>(
    () =>
      allOfferDetail
        .filter((c) => !selectedSchool || selectedSchool === c.schoolId)
        .reduce((acc, curr) => {
          const summary = acc.find((x) => x.studyPlanVersionOfferId === 0);

          if (summary) {
            summary.passed += curr.passed;
            summary.pending += curr.pending;
            summary.withoutReview += curr.withoutReview;
            summary.enrolled += curr.enrolled;
          } else {
            acc.push({
              withoutReview: curr.withoutReview,
              pending: curr.pending,
              passed: curr.passed,
              studyPlanVersionOfferId: 0,
              enrolled: curr.enrolled,
              title: text.total,
              periodId: periodList?.current?.value ?? 0,
              schoolId: curr.schoolId,
            });
          }
          const { studyPlanVersionName: title, ...others } = curr;
          acc.push({
            title,
            periodId: periodList?.current?.value ?? 0,
            ...others,
          });
          return acc;
        }, new Array<TuitionContinuityDocumentReviewDetailProps>()),
    [allOfferDetail, periodList, selectedSchool, text.total],
  );

  const selectedOption = (
    options: OutlinedSelectOptionType[],
    selectedPeriod:
      | GetTuitionContinuityProcessManagemenResponse['currentPeriod']
      | undefined,
  ) => {
    const option = options.find(({ value }) => value === selectedPeriod?.id);
    if (option) {
      return option;
    }
    if (options.length > 0) {
      return options[0];
    }
    return undefined;
  };

  const loadPeriods = useCallback(async () => {
    setLoading(true);

    const { data, error } = await getBase();

    if (data) {
      const periodsList = data.periods.map<OutlinedSelectOptionType>((x) => ({
        value: x.id,
        label: x.name,
      }));
      const initialCurrentPeriod = data.currentPeriod ?? undefined;
      const currentPeriod = selectedOption(periodsList, initialCurrentPeriod);

      setPeriodList({
        current: currentPeriod,
        periods: periodsList,
      });
      setError(undefined);

      if (currentPeriod) {
        loadOfferDetail(currentPeriod.value);
      }
    }
    if (error) {
      setError(error);
    }
    setLoading(false);
  }, [loadOfferDetail, setLoading]);

  const onChangePeriod = useCallback(
    async ({ value, label }: OutlinedSelectOptionType) => {
      setPeriodList((prev) => {
        if (prev) {
          prev.current = { value, label };
          return { ...prev };
        }
      });
      await loadOfferDetail(value);
    },
    [loadOfferDetail],
  );

  const onChangeSchool = useCallback((op?: OutlinedSelectOptionType) => {
    setSelectedSchool((prevState) => {
      if (prevState !== op?.value) {
        return op?.value;
      }
      return prevState;
    });
  }, []);

  useEffect(() => {
    loadPeriods();
  }, [loadPeriods]);

  if (loading) {
    return <Loading insideCard />;
  }

  if (!periodList || error) {
    return (
      <DisplayError
        insideCard
        textBody={text.error}
        retryAction={() => loadPeriods()}
        loadingAction={loading}
      />
    );
  }

  return children({
    loading: offerLoading,
    offerDetail,
    periods: periodList,
    onChangePeriod,
    schoolOptions: schools,
    onChangeSchool,
  });
}
