import { ColumnTable, DisplayInfo, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { Student } from '../../../../types/tuitionProcessOnSite';
import { TuitionProcessFormActions } from '../../TuitionProcessFormActions';
import { findIndexStatus } from '../../helper/findIndexStatus';

const dictPrefix = `tuitionProcess.searchStudent.table`;

interface Props {
  data: Student[];
  isLoadingResults: boolean;
  preload: () => void;
}

export default function SearchStudentOnSiteMultiTuitionTable({
  data,
  isLoadingResults,
  preload,
}: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<Student>[] = [
    {
      headerText: t(`${dictPrefix}.columns.studyPlan`),
      columnName: 'Admission',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ row }) => {
        return <>{row?.studyPlanVersion_name}</>;
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.campus`),
      columnName: 'Admission',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ row }) => {
        return <>{row?.campus_name}</>;
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.schedule`),
      columnName: 'Admission',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ row }) => {
        return <>{row?.schedule_name}</>;
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.status`),
      columnName: 'Admission',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ row }) => {
        const prefix = 'tuitionProcess.form.';
        return (
          <>
            {findIndexStatus(row?.status, [
              t(prefix + 'state.body.inProcess'),
              t(prefix + 'state.body.waiting'),
              t(prefix + 'state.body.desisted'),
              t(prefix + 'state.body.enrolled'),
              t(prefix + 'state.body.changing'),
              t(prefix + 'state.body.notPostulate'),
            ])}
          </>
        );
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.admissionRoute`),
      columnName: 'Admission',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      cellFormat: ({ row }) => {
        const prefix = 'admissionTypes.';
        return <>{t(prefix + `${row?.admissionTypeId || 'SIN_SUA'}`)}</>;
      },
    },

    {
      headerText: t(`${dictPrefix}.columns.action`),
      columnName: 'Admission',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <TuitionProcessFormActions
            student={row}
            preload={preload}
            loading={isLoadingResults}
          />
        );
      },
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      noResultsText={
        <div className="d-flex justify-content-center align-items-center py-5">
          <DisplayInfo
            iconName="information"
            title={t(`${dictPrefix}.noResults.description`)}
            textBody=""
            maxWidth="650px"
          />
        </div>
      }
    />
  );
}
