import { Button, Icon, Table, addToast } from '@octano/global-ui';
import {
  CellFormatOptions,
  PaginationType,
} from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import {
  deleteAcademicOfferRequest,
  getAcademicOffersRequest,
} from '../api/requests/academicOffers';
import DisplayError from '../components/info/DisplayError';
import TableEmptyContent from '../components/text/TableEmptyContent';
import { useLoadingState } from '../hooks/useLoadingState';
import useUserState from '../hooks/useUserState';
import { AcademicOffersType } from '../types/AcademicOffersType';
import { PermissionName } from '../types/Auth';
import { PaginationRequestType } from '../types/paginationRequestType';
import AcademicOfferDeleteModal from './modals/AcademicOfferDeleteModal';

// valores iniciales para la paginación
const ITEMS_PER_PAGE_DEFAULT = 10;
const PAGE_INITIAL_DEFAULT = 1;

const getBadgeColor = (status: string) =>
  ({
    Cerrada: 'danger',
    Confirmada: 'primary',
    Borrador: 'warning',
  }[status]);

const showDeleteToast = (error: boolean, t: Function) => {
  addToast(
    error
      ? {
          icon: 'error',
          color: 'danger',
          text: t('academicOffers.list.deleteError'),
        }
      : {
          icon: 'success',
          color: 'success',
          text: t('academicOffers.list.deleteSuccess'),
        },
  );
};

const AcademicOffersList = () => {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  // obtener elemento
  const refTable = useRef<HTMLDivElement>(null);
  // estado para guardar el tamaño del contenedor
  const [widthDiv, setWidthDiv] = useState<number>(1000);
  const history = useHistory();
  const [activeRecordId, setActiveRecordId] = useState<string | null>(null);

  // data para la  tabla
  const [academicOffer, setAcademicOffer] = useState<
    PaginationRequestType<AcademicOffersType>
  >({
    data: [],
    total_pages: 0,
    total: 0,
  });

  // actualizacion de la paginacion
  const [currentPage, setCurrentPage] = useState<number>(PAGE_INITIAL_DEFAULT);
  const [itemsPerPage] = useState<number>(ITEMS_PER_PAGE_DEFAULT);

  // loading
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  // obtener la lista de versiones
  const getAcademicOffers = useCallback(async () => {
    const { error, data: response } = await getAcademicOffersRequest(
      itemsPerPage,
      currentPage - 1,
    );
    if (response) {
      setAcademicOffer(response);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      return;
    }
    setLoading(false);
  }, [itemsPerPage, currentPage, setErrorLoading, setLoading]);

  const handleDeleteOfferClick = (value: string) => {
    if (isAuthorizedTo([PermissionName.DELETE_ACADEMIC_OFFER])) {
      setActiveRecordId(value);
    }
  };

  const deleteAcademicOffer = useCallback(
    async (id: string) => {
      const { error, data: response } = await deleteAcademicOfferRequest(id);
      if (response) {
        getAcademicOffers();
      }

      showDeleteToast(!!error, t);
    },
    [getAcademicOffers, t],
  );

  const handlerOnChangePage = useCallback(
    async (page: number) => setCurrentPage(page),
    [],
  );

  const pagination = useMemo<PaginationType | undefined>(() => {
    if (academicOffer.total > itemsPerPage) {
      return {
        totalItems: academicOffer.total,
        itemsPerPage: itemsPerPage,
        totalPages: academicOffer.total_pages,
        currentPage: currentPage,
        onChangePage: handlerOnChangePage,
      };
    }
  }, [academicOffer, itemsPerPage, currentPage, handlerOnChangePage]);
  const columnWidth = `${100 / 6}%`;
  // columnas para las tablas
  const columns = [
    {
      columnName: 'Period_name',
      headerText:
        widthDiv > 999
          ? t('academicOffers.list.academicPeriod')
          : t('academicOffers.list.Period'),
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<AcademicOffersType>) => {
        return <div className={'ellipsis'}>{options.value}</div>;
      },
    },
    {
      columnName: 'Period_startDate',
      headerText: t('academicOffers.list.startDate'),
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<AcademicOffersType>) =>
        dayjs(options.value).utc().format('DD/MM/YYYY'),
    },
    {
      columnName: 'Period_endDate',
      headerText: t('academicOffers.list.endDate'),
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<AcademicOffersType>) =>
        dayjs(options.value).utc().format('DD/MM/YYYY'),
    },
    {
      columnName: 'AcademicOffer_quota',
      headerText: t('academicOffers.list.quota'),
      width: columnWidth,
    },
    {
      columnName: 'enrolled',
      headerText: t('academicOffers.list.enrolled'),
      width: columnWidth,
    },
    {
      columnName: 'AcademicOffer_status',
      headerText:
        widthDiv > 999
          ? t('academicOffers.list.offerStatus')
          : t('academicOffers.list.status'),
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<AcademicOffersType>) => {
        const color = getBadgeColor(options.value);
        return (
          <Badge
            className={options.value === 'Borrador' ? 'Borrador' : ''}
            color={color}
          >
            {options.value}
          </Badge>
        );
      },
    },

    {
      columnName: 'AcademicOffer_id',
      headerText: '',
      width: columnWidth,
      cellFormat: (options: CellFormatOptions<AcademicOffersType>) => {
        const { AcademicOffer_status } = options.row;
        const type = AcademicOffer_status === 'Borrador' ? 'record' : 'info';
        return (
          <div className="d-flex justify-content-end">
            {options.row.AcademicOffer_status === 'Borrador' && (
              <span
                onClick={() => handleDeleteOfferClick(options.value)}
                className="mr-2"
              >
                <Icon
                  className={`cursor-pointer ${
                    !isAuthorizedTo([PermissionName.DELETE_ACADEMIC_OFFER])
                      ? 'icon-disabled'
                      : ''
                  }`}
                  name="trash"
                  key="eye"
                  size="18px"
                />
              </span>
            )}

            <Link to={`/academic-offers/${type}/${options.value}`}>
              <Icon className="custom-eye" name="eye" key="eye" size="22px" />
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getAcademicOffers();

    setTimeout(() => {
      refTable.current && setWidthDiv(refTable.current.offsetWidth);
    }, 100);
  }, [getAcademicOffers]);

  if (errorLoading) {
    return (
      <div className="mx-3">
        <DisplayError
          insideCard
          textBody={errorLoading}
          retryAction={() => setLoading(true)}
          loadingAction={loading}
        />
      </div>
    );
  }
  if (loading) {
    return <></>;
  }

  return (
    <div className="mx-3">
      <AcademicOfferDeleteModal
        show={!!activeRecordId}
        onCloseModal={() => setActiveRecordId(null)}
        onConfirm={() => {
          if (activeRecordId) deleteAcademicOffer(activeRecordId);
          setActiveRecordId(null);
        }}
      />
      <div className="g-table-container">
        <Button
          text={t('academicOffers.list.createVersion')}
          type="button"
          size="sm"
          icon="plus"
          className="g-add-button mb-3 mt-3"
          onClick={() => history.push(`/academic-offers/record`)}
          disabled={!isAuthorizedTo([PermissionName.CREATE_ACADEMIC_OFFER])}
        />
        <div id={'academic-offers-table'} ref={refTable}>
          <Table
            columns={columns}
            data={academicOffer.data}
            pagination={pagination}
            noResultsText={
              <TableEmptyContent
                title={t('academicOffers.list.TableEmptyTitle')}
                subtitle={t('academicOffers.list.TableEmptySubtitle')}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AcademicOffersList;
