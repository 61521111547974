import { CheckInput, DateInput, FileInputControlled } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useValidations } from '../../../hooks/useValidations';
import { STUDENT_STATUS } from '../../../types/studentStatusTypes';
import { ModalChangeStatusType } from '../StudentStatus';

const ModalChangeFieldsForm = ({
  modalChangeStatus,
}: {
  modalChangeStatus?: ModalChangeStatusType;
}) => {
  const prefix = 'studentStatus.modalChange';
  const today = new Date();
  const { t } = useTranslation();
  const { msgValidations } = useValidations();
  const requiredFile = t(`${prefix}.requiredFile`);
  const { control, watch, formState } = useFormContext();

  const [startDateValue, check] = watch(['startDate', 'checkWithoutEndDate']);
  const showDates = useMemo(() => {
    return (
      modalChangeStatus?.newStatus === STUDENT_STATUS.SUSPENDED ||
      modalChangeStatus?.newStatus === STUDENT_STATUS.CANCELED ||
      modalChangeStatus?.newStatus === STUDENT_STATUS.POSTPONED
    );
  }, [modalChangeStatus]);

  const showCheck = useMemo(() => {
    return (
      modalChangeStatus?.newStatus === STUDENT_STATUS.SUSPENDED ||
      modalChangeStatus?.newStatus === STUDENT_STATUS.POSTPONED
    );
  }, [modalChangeStatus]);

  const isWatchMode = useMemo(() => {
    return modalChangeStatus?.action === 'watch';
  }, [modalChangeStatus]);

  const isDisabled = useMemo(() => {
    return formState.isSubmitting || isWatchMode;
  }, [formState.isSubmitting, isWatchMode]);

  const showEndDate = useMemo(() => {
    return (showCheck && !check) || !showCheck;
  }, [showCheck, check]);

  return (
    <>
      <Row>
        <Col xs={12} className="pb-3">
          <FileInputControlled
            btnText={t(`common.actions.load`)}
            name="file"
            label={t(`${prefix}.file`)}
            rules={{
              required: requiredFile,
            }}
            accept="image/png, image/jpeg, application/pdf"
            control={control}
            disabled={isDisabled}
            shouldUnregister
            onDownloadFile={
              modalChangeStatus?.defaultValues?.file.url
                ? () =>
                    window.open(
                      modalChangeStatus?.defaultValues?.file.url,
                      '_blank',
                    )
                : undefined
            }
          />
        </Col>
      </Row>
      {showDates && (
        <Row>
          <Col xs={showEndDate ? 6 : 12}>
            <DateInput
              name="startDate"
              control={control}
              label={t(`${prefix}.startDate`)}
              rules={{
                required: msgValidations.required,
              }}
              shouldUnregister
              disabled={isDisabled}
              maxDate={today}
            />
            {showCheck && (
              <CheckInput
                name="checkWithoutEndDate"
                label={t(`${prefix}.checkWithoutEndDate`)}
                control={control}
                shouldUnregister
                disabled={isDisabled}
              />
            )}
          </Col>
          {showEndDate && (
            <Col xs={6}>
              <DateInput
                name="endDate"
                control={control}
                label={t(`${prefix}.endDate`)}
                rules={{
                  required: msgValidations.required,
                }}
                minDate={
                  startDateValue ? dayjs(startDateValue).toDate() : undefined
                }
                shouldUnregister
                disabled={isDisabled}
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default ModalChangeFieldsForm;
