import {
  StudyPlanItemCustom,
  VersionOffer,
} from './../../interfaces/study-plan-item-custom.interface';
import { UpdateQuotaType } from '../../../api/requests/academicOffers';
import { ConfirmModalData } from '../../modals/AcademicOffersAddModal';

// Construir body para guardar los cupos nuevos o editar los existentes
export const buildRequestBody = (
  data: VersionOffer[],
  studyPlanId: number,
  studyPlanItem: StudyPlanItemCustom,
  tableQuota: string[],
): UpdateQuotaType[] => {
  let array = data.map((item: VersionOffer, index: number) => {
    let quota = parseInt(tableQuota[index]);
    return {
      studyPlanId,
      studyPlanVersionId: studyPlanItem.Version_id,
      studyPlanVersionOfferId: item.StudyPlanVersionOffer_id,
      campusId: item.StudyPlanVersionOffer_campusId,
      scheduleId: item.StudyPlanVersionOffer_scheduleId,
      academicOfferStudyPlanVersionOfferId:
        item.AcademicOfferStudyPlanVersionOffers_id,
      quota: isNaN(quota) ? null : quota,
    };
  });
  array.pop();
  return array;
};

// Agregar una fila
export const buildRow = (
  studyPlanItem: StudyPlanItemCustom,
  values: ConfirmModalData,
  academicOfferId: number,
): VersionOffer => {
  return {
    StudyPlanVersionOffer_id: 0,
    StudyPlanVersionOffer_studyPlanVersionId: studyPlanItem.Version_id,
    StudyPlanVersionOffer_campusId: values.campus.id || 0,
    StudyPlanVersionOffer_scheduleId: values.schedule.id,
    AcademicOfferStudyPlanVersionOffers_id: 0,
    AcademicOfferStudyPlanVersionOffers_academicOfferId: academicOfferId,
    AcademicOfferStudyPlanVersionOffers_studyPlanVersionOfferId: 0,
    AcademicOfferStudyPlanVersionOffers_quota: values.quota,
    Campus_name: values.campus.name,
    Schedule_name: values.schedule.name,
    duplicatedQuota: 0,
    enrolled: 0,
  };
};

// Agregar una fila para mostrar vacia
export const buildEmptyRow = (): VersionOffer => {
  return {
    StudyPlanVersionOffer_id: -1,
    StudyPlanVersionOffer_studyPlanVersionId: 0,
    StudyPlanVersionOffer_campusId: 0,
    StudyPlanVersionOffer_scheduleId: 0,
    AcademicOfferStudyPlanVersionOffers_id: 0,
    AcademicOfferStudyPlanVersionOffers_academicOfferId: 0,
    AcademicOfferStudyPlanVersionOffers_studyPlanVersionOfferId: 0,
    AcademicOfferStudyPlanVersionOffers_quota: 0,
    Campus_name: '',
    Schedule_name: '',
    duplicatedQuota: 0,
    enrolled: 0,
  };
};

/**
 * Agregar una fila para un dato duplicado
 * Esto prepara la data para insertar en caso de que usen el boton de confirmar cupos
 * @param studyPlanItem
 * @param values
 * @param academicOfferId
 * @returns
 */
export const buildDuplicatedRow = (
  studyPlanItem: StudyPlanItemCustom,
  academicOfferId: number,
  versionOffer: VersionOffer,
): VersionOffer => {
  return {
    StudyPlanVersionOffer_id: 0,
    StudyPlanVersionOffer_studyPlanVersionId: studyPlanItem.Version_id,
    StudyPlanVersionOffer_campusId: versionOffer.StudyPlanVersionOffer_campusId,
    StudyPlanVersionOffer_scheduleId:
      versionOffer.StudyPlanVersionOffer_scheduleId,
    AcademicOfferStudyPlanVersionOffers_id: 0,
    AcademicOfferStudyPlanVersionOffers_academicOfferId: academicOfferId,
    AcademicOfferStudyPlanVersionOffers_studyPlanVersionOfferId: 0,
    AcademicOfferStudyPlanVersionOffers_quota: 0,
    Campus_name: versionOffer.Campus_name,
    Schedule_name: versionOffer.Schedule_name,
    duplicatedQuota: versionOffer.AcademicOfferStudyPlanVersionOffers_quota,
    enrolled: 0,
  };
};
