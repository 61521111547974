import {
  Button,
  ColumnTable,
  DisplayInfo,
  Icon,
  Table,
} from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'rut.js';

import { TextEllipsis } from '../../../../components/text';
import { PathsLayouts } from '../../../../config/routes';
import { StudentPetitionsRow } from '../../../../types/studentPetitions';
import { PetitionsStoryModal } from '../../parts/PetitionsStory/PetitionsStoryModal';
import PetitionStatusBadge from '../../../../components/badgeStatus/PetitionStatusBadge';

const dictPrefix = 'studentPetitions.received.table';

interface Props {
  data: StudentPetitionsRow[];
  isLoadingResults: boolean;
  totalPetitions: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  hasFilters?: boolean;
}

export default function PetitionsTable({
  data,
  isLoadingResults,
  totalPages,
  totalPetitions,
  currentPage,
  onChangePage,
  hasFilters,
}: Props) {
  const ITEMS_PER_PAGE = 10;
  const { t } = useTranslation();
  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [petitionId, setPetitionId] = useState('');

  const columns: ColumnTable<StudentPetitionsRow>[] = [
    {
      headerText: t(`${dictPrefix}.columns.folio`),
      columnName: 'folio',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.columns.studentName`),
      columnName: 'studentName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
      cellFormat: ({ row }) => {
        return (
          <p>
            {row?.studentName}
            <br />
            {row?.studentRun && format(row.studentRun)}
          </p>
        );
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.createdAt`),
      columnName: 'createdAt',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
      cellFormat: (options) => dayjs(options.value).format('DD/MM/YYYY'),
    },
    {
      headerText: t(`${dictPrefix}.columns.studyPlanVersionName`),
      columnName: 'studyPlanVersionName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '20%',
      cellFormat: (options: CellFormatOptions) => (
        <TextEllipsis key={options.index} textWidth={350}>
          {options.value}
        </TextEllipsis>
      ),
    },
    {
      headerText: t(`${dictPrefix}.columns.typePetition`),
      columnName: 'typeName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.columns.title`),
      columnName: 'title',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
    },
    {
      headerText: t(`${dictPrefix}.columns.status`),
      columnName: 'status',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      width: '150px',
      cellFormat: ({ row, value }) => {
        return <PetitionStatusBadge status={value} name={row.folio} />;
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.action`),
      columnName: 'folio',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      width: '200px',
      cellFormat: (options: CellFormatOptions) => {
        return (
          <Button
            text={t(`${dictPrefix}.columns.resolved`)}
            size="sm"
            onClick={
              () =>
                // if (isAuthorizedTo([PermissionName.EDIT_TUITIONS_PERIOD])) {
                history.push(
                  `${PathsLayouts.studentPetitions}/${options.value}`,
                )
              // }
            }
          />
        );
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.seeStory`),
      columnName: 'folio',
      width: '100px',
      cellFormat: (options: CellFormatOptions) => {
        return (
          <div className="d-flex justify-content-center">
            <span
              onClick={() => {
                setPetitionId(options.value);
                setShowModal(true);
              }}
              className="cursor-pointer"
            >
              <Icon
                className="custom-certificates_requests"
                name="certificates_requests"
                key="certificates_requests"
                size="22px"
              />
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={{
          currentPage: currentPage + 1,
          itemsPerPage: ITEMS_PER_PAGE,
          totalItems: totalPetitions,
          totalPages: totalPages,
          onChangePage: (page) => onChangePage(page - 1),
        }}
        noResultsText={
          <div className="d-flex justify-content-center align-items-center py-5">
            <DisplayInfo
              iconName="information"
              title={t(
                `${dictPrefix}.${
                  hasFilters ? 'noResultsFilter' : 'noResults'
                }.description`,
              )}
              textBody=""
              maxWidth="650px"
            />
          </div>
        }
      />
      <PetitionsStoryModal
        show={showModal}
        toggleModal={() => {
          setShowModal(!showModal);
          setPetitionId('');
        }}
        petitionId={petitionId}
      />
    </>
  );
}
