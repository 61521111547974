import {
  addToast,
  Button,
  DateInput,
  TextAreaInput,
  TextInput,
} from '@octano/global-ui';
import {
  Fragment,
  ReactChild,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import {
  getEventsRequest,
  PeriodAcademicCalendarEvent,
} from '../../api/requests/periods';
import { SectionTitle } from '../../components/text';
import { useDateRange } from '../../hooks/useDateRange';
import { useLayoutState } from '../../hooks/useLayoutState';
import useUserState from '../../hooks/useUserState';
import { useValidations } from '../../hooks/useValidations';
import { PermissionName } from '../../types/Auth';
import { EventForm } from './event/EventForm';
import PeriodAcademicCalendarTable from './PeriodAcademicCalendarTable';
import PeriodCantCreateAcademicCalendarModal from './PeriodCantCreateAcademicCalendarModal';

export interface PeriodValues {
  code: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
}

export interface AcademicCalendar {
  startDate?: string | null;
  endDate?: string | null;
  eventCalendar: PeriodAcademicCalendarEvent;
}

export interface PeriodFormProps {
  defaultAcademicCalendars?: AcademicCalendar[];
  defaultValues?: Partial<PeriodValues>;
  children: (props: { isSubmitting: boolean }) => ReactChild;
  onSubmit: (
    values: PeriodValues & { academicCalendars: any },
  ) => Promise<'CONNECTION' | 'HTTP_ERROR' | 'NOT_UNIQUE_CODE' | undefined>;
}

const PeriodsForm = (props: PeriodFormProps) => {
  const { isAuthorizedTo } = useUserState();
  const {
    defaultAcademicCalendars = [],
    defaultValues: defaultValuesProp,
    onSubmit: onSubmitProp,
    children,
  } = props;

  const { showErrorModal } = useLayoutState();

  const { t } = useTranslation();
  const prefix = 'maintainers.periodsForm';
  const [eventsList, setEventsList] = useState<PeriodAcademicCalendarEvent[]>(
    [],
  );
  const [showEventForm, setShowEventForm] = useState<boolean>(false);
  const [showCantCreateEventModal, setShowCantCreateEventModal] =
    useState<boolean>(false);
  const [activeEventToEdit, setActiveEventToEdit] =
    useState<AcademicCalendar | null>(null);

  const [formEvents, setFormEvents] = useState<AcademicCalendar[]>(
    defaultAcademicCalendars,
  );

  const defaultValues = Object.assign(
    {
      code: '',
      name: '',
      description: '',
      startDate: '',
      endDate: '',
    },
    defaultValuesProp,
  );

  const methods = useForm<PeriodValues>({
    mode: 'onSubmit',
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setError,
    watch,
    setValue,
  } = methods;

  const [startDate, endDate, periodName, code] = watch([
    'startDate',
    'endDate',
    'name',
    'code',
  ]);

  const isFilledForm = startDate && endDate && periodName && code;

  const setEndDate = useCallback((e) => setValue('endDate', e), [setValue]);
  const { minDate, endDateDisabled } = useDateRange({
    startDate,
    endDate,
    setEndDate,
  });

  const { msgValidations, validateTextNotEmpty, validateAlphaNumeric } =
    useValidations();

  const onSubmit = useCallback(
    async (formValues: PeriodValues) => {
      const response = await onSubmitProp({
        ...formValues,
        academicCalendars: formEvents,
      });
      if (response === 'CONNECTION') {
        showErrorModal();
      } else if (response === 'NOT_UNIQUE_CODE') {
        setError('code', {
          message: t('common.errors.codeError', { code: formValues.code }),
        });
      } else if (response === 'HTTP_ERROR') {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.errors.save'),
        });
      }
    },
    [onSubmitProp, showErrorModal, setError, formEvents, t],
  );

  const changeEventsTable = useCallback(
    (
      eventData: { eventId: number; startDate: string; endDate: string },
      isEdition: boolean = false,
    ) => {
      if (isEdition) {
        setFormEvents((currentAcademicCalendars) => {
          const index = currentAcademicCalendars.findIndex(
            (a) => a.eventCalendar.id === eventData.eventId,
          );
          if (index < 0) {
            return currentAcademicCalendars;
          }

          const eventCalendar = eventsList.find(
            (e) => e.id === eventData.eventId,
          );

          if (!eventCalendar) {
            return currentAcademicCalendars;
          }
          const newAcademicCalendars = [...currentAcademicCalendars];
          newAcademicCalendars[index] = {
            startDate: eventData.startDate,
            endDate: eventData.endDate,
            eventCalendar,
          };
          return newAcademicCalendars;
        });
      } else {
        setFormEvents((currentAcademicCalendars) => {
          const eventCalendar = eventsList.find(
            (e) => e.id === eventData.eventId,
          );

          if (!eventCalendar) {
            return currentAcademicCalendars;
          }
          return [
            ...currentAcademicCalendars,
            {
              startDate: eventData.startDate,
              endDate: eventData.endDate,
              eventCalendar,
            },
          ];
        });
      }
    },
    [eventsList],
  );

  const getEvents = async () => {
    const { data, error } = await getEventsRequest();
    if (error) {
      return;
    }

    if (data) {
      const { data: items = [] } = data;
      setEventsList(items);
    }
  };
  useEffect(() => {
    getEvents();
  }, []);

  const selectedEventsIds = useMemo(() => {
    return formEvents.map((f) => f.eventCalendar.id);
  }, [formEvents]);

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} md={6}>
              <SectionTitle text={t(`${prefix}.periodData`)} />
              <Row>
                <Col xs={12} md={6}>
                  <div className="pb-3">
                    <TextInput
                      name="code"
                      label={t(`common.forms.code`)}
                      control={control}
                      rules={{
                        required: msgValidations.required,
                        maxLength: {
                          value: 50,
                          message: t('common.validations.maxLength', {
                            length: 50,
                          }),
                        },
                        validate: validateAlphaNumeric,
                      }}
                      disabled={!isAuthorizedTo([PermissionName.EDIT_PERIOD])}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="pb-3">
                    <TextInput
                      name="name"
                      label={t(`common.forms.name`)}
                      control={control}
                      rules={{
                        minLength: {
                          value: 5,
                          message: t('common.validations.minLength', {
                            length: 5,
                          }),
                        },
                        maxLength: {
                          value: 255,
                          message: t('common.validations.maxLength', {
                            length: 255,
                          }),
                        },
                        validate: validateTextNotEmpty,
                      }}
                      disabled={!isAuthorizedTo([PermissionName.EDIT_PERIOD])}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <div className="pb-3">
                    <DateInput
                      name="startDate"
                      label={t(`common.forms.startDate`)}
                      control={control}
                      rules={{ required: msgValidations.required }}
                      disabled={!isAuthorizedTo([PermissionName.EDIT_PERIOD])}
                    />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="pb-3">
                    <DateInput
                      name="endDate"
                      label={t(`common.forms.endDate`)}
                      minDate={minDate}
                      disabled={
                        endDateDisabled ||
                        !isAuthorizedTo([PermissionName.EDIT_PERIOD])
                      }
                      control={control}
                      rules={{ required: msgValidations.required }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextAreaInput
                    name="description"
                    label={`${t('common.forms.description')} ${t(
                      'common.forms.optionalLabel',
                    )}`}
                    control={control}
                    height={200}
                    placeholder={t(`common.forms.descriptionPlaceholder`)}
                    disabled={!isAuthorizedTo([PermissionName.EDIT_PERIOD])}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} style={{ borderLeft: '1px solid #F4F4F4' }}>
              <SectionTitle text={t(`${prefix}.academicCalendarDates`)} />
              <Button
                text={t(`${prefix}.addEvent`)}
                type="button"
                size="sm"
                icon="plus"
                className="g-add-button mb-3"
                onClick={() =>
                  isFilledForm
                    ? setShowEventForm(true)
                    : setShowCantCreateEventModal(true)
                }
                disabled={
                  !isAuthorizedTo([PermissionName.EDIT_PERIOD]) &&
                  !isAuthorizedTo([PermissionName.CREATE_PERIOD])
                }
              />
              <PeriodAcademicCalendarTable
                academicCalendars={formEvents}
                onEdit={(a) => {
                  setActiveEventToEdit(a);
                  setShowEventForm(true);
                }}
              />
            </Col>
          </Row>
          {children({ isSubmitting })}
        </Form>
      </FormProvider>
      <EventForm
        recordToEdit={activeEventToEdit}
        changeEventsTable={changeEventsTable}
        periodName={periodName}
        eventsList={eventsList}
        show={showEventForm || !!activeEventToEdit}
        onClose={() => {
          setActiveEventToEdit(null);
          setShowEventForm(false);
        }}
        selectedEventsIds={selectedEventsIds}
      />
      <PeriodCantCreateAcademicCalendarModal
        open={showCantCreateEventModal}
        onClose={() => {
          setShowCantCreateEventModal(false);
        }}
      />
    </Fragment>
  );
};

export default PeriodsForm;
