import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Card } from 'reactstrap';
import { addToast, Box, Button, useMobile } from '@octano/global-ui';
import GraduatesTable from './parts/GraduatesTable';
import {
  CurrentPeriodAndFacultiesResponse,
  ProcessGraduatesResponse,
} from '../../types/graduationProcessMassive';
import { PathsLayouts } from '../../config/routes';
import { downloadGraduatesList } from '../../api/requests/graduationProcessMassive';
import { downloadFromBlob } from '../../utils/blob';
import dayjs from 'dayjs';

const dictPrefix = `graduatesListProcessMassive`;

export default function GraduatesListProcessMasive() {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const { state: routerState } = useLocation<{
    graduates: ProcessGraduatesResponse['data'];
    currentPeriod: CurrentPeriodAndFacultiesResponse['currentPeriod'];
    facultyId: number;
    studyPlanId: number;
  }>();

  const history = useHistory();

  if (
    !routerState?.graduates ||
    !routerState?.currentPeriod ||
    !routerState?.facultyId ||
    !routerState?.studyPlanId
  ) {
    history.push(PathsLayouts.graduationProcessMassive);
  }

  const handleDownload = async () => {
    const res = await downloadGraduatesList(routerState.studyPlanId);

    if (res.error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${dictPrefix}.errorDownloadingFile`),
      });
    } else {
      const blob = new Blob([res.data], { type: 'vnd.ms-excel' });

      // Como el boton se deshabilita si no hay egresados, asumimos que aca siempre tendremos al menos 1
      const studyPlanName = routerState.graduates[0].studyPlanName.replace(
        /\s/g,
        '_',
      );
      const todaysDate = dayjs().format('DD_MM_YY');
      const fileName = `ListaEgresados_${studyPlanName}_${todaysDate}.xlsx`;

      downloadFromBlob(blob, fileName);
    }
  };

  return (
    <Card className="mx-3 p-4">
      <div
        className="d-flex flex-wrap justify-content-between"
        style={{ gap: 12 }}
      >
        <div>
          <Button
            text={t(`common.actions.goBackStep`)}
            icon="back"
            size="sm"
            onClick={() =>
              history.push(PathsLayouts.graduationProcessMassive, {
                facultyId: routerState.facultyId,
              })
            }
            rounded
            outlined
          />
        </div>
        <Box
          color="primary"
          body={routerState?.currentPeriod?.name || ''}
          title={t(`${dictPrefix}.currentPeriod`)}
          className={clsx('rounded', isMobile && 'w-100')}
        />
      </div>

      <div
        className="d-flex flex-wrap justify-content-between align-items-center mt-4 mb-3"
        style={{ gap: 12 }}
      >
        <h1 className="text-primary text-uppercase fs-20 fw-600 mb-3">
          {t(`${dictPrefix}.graduates`)}
        </h1>
        {routerState?.graduates?.length > 0 && (
          <Button
            text={t(`${dictPrefix}.downloadExcel`)}
            icon="download"
            size="md"
            outlined
            className={clsx('fw-700', isMobile && 'w-100')}
            style={{ minWidth: isMobile ? 'unset' : 205 }}
            onClick={handleDownload}
          />
        )}
      </div>

      <GraduatesTable data={routerState?.graduates || []} />
    </Card>
  );
}
