import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
} from '@octano/global-ui';
import { Col, Row } from 'reactstrap';
import { AgreedFilters } from '../interfaces/agreed-filters.interface';
import dayjs from 'dayjs';

type FormValues = {
  search: string;
  matriculationDate: string | null;
  program: SelectOptionType | null;
  period: SelectOptionType | null;
  type: SelectOptionType | null;
};

const DEFAULT_FORM_VALUES: FormValues = {
  search: '',
  matriculationDate: null,
  program: null,
  period: null,
  type: null,
};

const dictPrefix = 'paymentHub.searchControls';

interface Props {
  programs: SelectOptionType[];
  periods: SelectOptionType[];
  detailsOptions: SelectOptionType[];
  isDownloadLoading?: boolean;
  onSearch?: (sp: AgreedFilters) => void;
  onClear?: () => void;
  onDownload?: () => void;
}

export function SearchControls({
  detailsOptions,
  programs,
  periods,
  isDownloadLoading = false,
  onSearch = () => null,
  onClear = () => null,
  onDownload = () => null,
}: Props) {
  const { t } = useTranslation();

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const handleSearch = (values: FormValues) => {
    const matriculationDate = [];
    if (values?.matriculationDate?.trim()) {
      matriculationDate.push(
        ...[
          dayjs(values?.matriculationDate?.trim()).startOf('day').toISOString(),
          dayjs(values?.matriculationDate?.trim()).endOf('day').toISOString(),
        ],
      );
    }
    onSearch({
      search: values?.search?.trim() ?? undefined,
      period: values?.period?.value ?? undefined,
      program: values?.program?.value ?? undefined,
      type: values?.type?.value ?? undefined,
      matriculationDate: matriculationDate?.length
        ? matriculationDate
        : undefined,
    });
  };

  const handleClear = () => {
    reset(DEFAULT_FORM_VALUES);
    onClear();
  };

  return (
    <Row className="mb-5">
      <Col xs={12} sm={6} md={6} lg={4}>
        <TextOutlinedInput
          name="search"
          control={control}
          label={t(`${dictPrefix}.nameOrId.label`)}
          placeholder={t(`${dictPrefix}.nameOrId.placeholder`)}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          name="program"
          label={t(`${dictPrefix}.program.label`)}
          placeholder={t(`${dictPrefix}.program.placeholder`)}
          options={programs}
          control={control}
          disabled={!programs?.length}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          name="type"
          label={t(`${dictPrefix}.detail.label`)}
          placeholder={t(`${dictPrefix}.detail.placeholder`)}
          options={detailsOptions}
          control={control}
          disabled={!detailsOptions?.length}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <OutlinedSelect
          name="period"
          label={t(`${dictPrefix}.period.label`)}
          placeholder={t(`${dictPrefix}.period.placeholder`)}
          options={periods}
          control={control}
          disabled={!periods?.length}
        />
      </Col>
      <Col xs={12} sm={6} md={6} lg={4}>
        <TextOutlinedInput
          control={control}
          type={'date' as any}
          name="matriculationDate"
          label={t(`${dictPrefix}.date.label`)}
          placeholder={t(`${dictPrefix}.date.placeholder`)}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={4}>
        <div className="d-flex flex-column w-100 align-items-end mt-4">
          <div>
            <Button
              className="mx-1"
              type="button"
              size="md"
              text={t(`${dictPrefix}.search`)}
              onClick={handleSubmit(handleSearch)}
            />
            <Button
              className="mx-1"
              type="button"
              size="md"
              text={t(`${dictPrefix}.clear`)}
              outlined
              onClick={handleClear}
            />
          </div>
          <div className="mt-3 d-flex flex-wrap justify-content-end w-100">
            <Button
              className="mx-md-1 mb-2"
              type="button"
              icon="download"
              size="md"
              outlined
              fullwidth
              loading={isDownloadLoading}
              text={t(`${dictPrefix}.download`)}
              onClick={onDownload}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
