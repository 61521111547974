import { Select } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import TuitionContinuityDocumentReviewDetailList from './parts/TuitionContinuityDocumentReviewDetailList';
import { TuitionContinuityDocumentReviewLoader } from './parts/TuitionContinuityDocumentReviewLoader';

export default function TuitionContinuityProcessDocumentReview() {
  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      periodSelect: t('TuitionContinuityDocumentReview.form.periodSelect'),
      schoolSelect: t('TuitionContinuityDocumentReview.form.schoolSelect'),
    }),
    [t],
  );
  return (
    <Card className="mx-4 p-4">
      <TuitionContinuityDocumentReviewLoader>
        {({
          loading,
          periods,
          offerDetail,
          onChangePeriod,
          schoolOptions,
          onChangeSchool,
        }) => (
          <>
            <Row>
              <Col md={6} className="pb-3" lg={6}>
                <Select
                  name="selection-period"
                  label={text.periodSelect}
                  isClearable={false}
                  options={periods.periods}
                  value={periods.current}
                  onChange={onChangePeriod}
                />
              </Col>
              <Col md={6}>
                <Select
                  name="selection-school"
                  label={text.schoolSelect}
                  isClearable
                  options={schoolOptions}
                  onChange={onChangeSchool}
                />
              </Col>
            </Row>
            <Row>
              <TuitionContinuityDocumentReviewDetailList
                offerList={offerDetail}
                isLoading={loading}
              />
            </Row>
          </>
        )}
      </TuitionContinuityDocumentReviewLoader>
    </Card>
  );
}
