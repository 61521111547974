import {
  Button,
  OutlinedSelect,
  SearchBox,
  SelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

export interface SearchFiltersType {
  status: SelectOptionType | null;
  nameOrId: string | null;
}

interface SearchFiltersProps {
  onSearch: (filters: SearchFiltersType) => void;
  status: string[];
}

const SearchFilters = ({ onSearch, status }: SearchFiltersProps) => {
  const prefix = 'studentStatus.filters';
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<SearchFiltersType>();
  const isMobile = useMobile();

  const onSubmit = (values: SearchFiltersType) => {
    onSearch(values);
  };

  const statusOptions = useMemo(() => {
    let newStatus: SelectOptionType[] = [];
    newStatus.push({
      value: 0,
      label: t(`studentStatus.status.all`),
    });
    status.forEach((state) => {
      newStatus.push({
        value: state,
        label: t(`studentStatus.status.${state}`),
      });
    });
    return newStatus;
  }, [status, t]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={5}>
          <OutlinedSelect
            name="status"
            options={statusOptions}
            label={t(`${prefix}.status`)}
            placeholder={t(`${prefix}.placeholderStatus`)}
            control={control}
            isClearable
          />
        </Col>
        <Col xs={12} md={6} lg={5}>
          <SearchBox
            name="nameOrId"
            label="Nombre o ID"
            placeholder="Ingresa un nombre o ID"
            control={control}
            clear={() => setValue('nameOrId', '')}
          />
        </Col>
        <Col
          xs={12}
          md={{ size: 4, offset: 8 }}
          lg={{ size: 2, offset: 0 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>
      </Row>
    </Form>
  );
};

export default SearchFilters;
