import { useCallback, useState } from 'react';
import { changeStatusToRetracted } from '../../../api/requests/studyPlansOffer';

export enum RetractedModal {
  none = 'none',
  confirm = 'confirm',
  empty = 'empty',
  out = 'out',
}

export const useValidateRetracted = () => {
  const [studentName, setStudentName] = useState<string>('');
  const [selectRetractedID, setSelectRetractedID] = useState<number>();
  const [showRetractedModal, setShowRetractedModal] = useState<
    typeof RetractedModal[keyof typeof RetractedModal]
  >(RetractedModal.none);

  const validateRetracted = useCallback(
    async (postulationDetailId: number, names: string, lastnames: string) => {
      let { status } = await changeStatusToRetracted(postulationDetailId, {
        showWarning: true,
      });
      switch (status) {
        case 200:
          setSelectRetractedID(postulationDetailId);
          setStudentName(`${names} ${lastnames}`);
          setShowRetractedModal(RetractedModal.confirm);
          break;
        case 204:
          setShowRetractedModal(RetractedModal.empty);
          break;
        case 400:
          setShowRetractedModal(RetractedModal.out);
          break;
        default:
          setShowRetractedModal(RetractedModal.none);
      }
    },
    [],
  );

  return {
    studentName,
    selectRetractedID,
    setSelectRetractedID,
    showRetractedModal,
    setShowRetractedModal,
    validateRetracted,
  };
};
