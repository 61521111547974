import { Card, Col, Row } from 'reactstrap';
import InterestsRatesSection from './parts/InterestsRatesSection';

export default function Finantial() {
  return (
    <Card className="p-4">
      <Row>
        <Col sm={12}>
          <InterestsRatesSection />
        </Col>
      </Row>
    </Card>
  );
}
