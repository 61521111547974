import { useEffect, useState, useCallback } from 'react';
import { getModalitiesRequest } from '../api/requests/modalities';
import { getSchoolsRequest, SchoolsListItem } from '../api/requests/schools';
import { getStudyPlanRequest } from '../api/requests/studyPlans';
import Loading from '../components/info/Loading';
import { ModalityListItem } from '../types/ModalityTypes';
import DisplayError from '../components/info/DisplayError';
import { StudyPlanItem } from './interfaces/sudy-plan-item.interface';

export interface StudyPlanRecord {
  id: number;
  code: string;
  name: string;
  modalityId: number;
  schoolId: number;
}

export type RequestType = 'HTTP_ERROR' | 'CONNECTION' | null;

export type ErrorType = {
  error: {
    code: RequestType;
  };
};

export interface ErrorsType {
  modalitiesErrorResponse: ErrorType;
  schoolsErrorResponse: ErrorType;
  studyPlanErrorResponse: ErrorType;
}

export interface RequestsData {
  studyPlan: StudyPlanItem | null;
  schools: SchoolsListItem[];
  modalities: ModalityListItem[];
}

export interface StudyPlanRecordLoaderProps {
  studyPlanId?: number;
  children: (data: RequestsData) => JSX.Element;
}

export const StudyPlanRecordLoader = (props: StudyPlanRecordLoaderProps) => {
  const { children, studyPlanId } = props;
  const [loading, setLoading] = useState(true);

  const [modalities, setModalities] = useState<ModalityListItem[]>([]);
  const [schools, setSchools] = useState<SchoolsListItem[]>([]);
  const [studyPlan, setStudyPlan] = useState<StudyPlanItem | null>(null);
  const [error, setError] = useState<'HTTP_ERROR' | 'CONNECTION' | null>(null);

  const loadData = useCallback(async () => {
    const [{ data: modalitiesResponse }, { data: schoolsResponse }] =
      await Promise.all([
        getModalitiesRequest(9999, 0),
        getSchoolsRequest(9999, 0),
      ]);

    if (modalitiesResponse) setModalities(modalitiesResponse.data);
    if (schoolsResponse) setSchools(schoolsResponse.data);
    if (studyPlanId) {
      getStudyPlanRequest(studyPlanId)
        .then((response) => {
          if (response.data) {
            setStudyPlan(response.data.data);
          } else if (response.error) {
            setError(response.error.code);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [studyPlanId]);

  useEffect(() => {
    loadData();
  }, [loadData]);
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <DisplayError textBody={error} />;
  }

  if (studyPlanId && !studyPlan) {
    return <DisplayError textBody="HTTP_ERROR" />;
  }

  return children({ studyPlan, schools: schools, modalities });
};
