import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useQuery from '../../hooks/useQuery';
import CollectedPayments from './Collected/Collected';
import AgreedPayments from './Agreed/Agreed';

enum TabName {
  Collected = 'collected',
  Agreed = 'agreed',
}

const DEFAULT_TAB = TabName.Collected;

export default function Configurations() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = useQuery();

  const { t } = useTranslation();
  const tabsPrefix = `paymentHub.tabs`;

  const initialTab = useMemo(() => {
    const queryTab = params.get('tab');
    if (!queryTab) return DEFAULT_TAB;

    const isValidTab = Object.values(TabName).some((tn) => tn === queryTab);
    return isValidTab ? queryTab : DEFAULT_TAB;
  }, [params]);

  const handleTabSelection = (selectedTab: number | string) => {
    history.push({ pathname: path, search: `?tab=${selectedTab}` });
    return null;
  };

  return (
    <div className="mx-3">
      <Tabs defaultTab={initialTab} onSelection={handleTabSelection}>
        <TabsList>
          <Tab id={TabName.Collected}>{t(`${tabsPrefix}.collected`)}</Tab>
          <Tab id={TabName.Agreed}>{t(`${tabsPrefix}.agreed`)}</Tab>
        </TabsList>
        <TabPanels>
          <TabPanel id={TabName.Collected} children={<CollectedPayments />} />
          <TabPanel id={TabName.Agreed} children={<AgreedPayments />} />
        </TabPanels>
      </Tabs>
    </div>
  );
}
