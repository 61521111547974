import { useCallback, useEffect, useState } from 'react';

import {
  getPeriodOFMantainerPortal,
  Period,
} from '../../../api/requests/MantainerPortal';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';

export interface RequestsData {
  periods: Period[];
  currentPeriod?: Period;
}

export interface PeriodsLoaderProps {
  renderPeriods: (data: RequestsData) => JSX.Element;
}

export const PeriodsLoader = ({ renderPeriods }: PeriodsLoaderProps) => {
  const [periods, setPeriods] = useState<Period[]>([]);
  const [currentPeriod, setCurrentPeriod] = useState<Period>();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const getPeriods = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getPeriodOFMantainerPortal();
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setErrorLoading(undefined);
      setPeriods(data.periods);
      setCurrentPeriod(data.currentPeriod);
    }
    setLoading(false);
  }, [setLoading, setErrorLoading]);

  useEffect(() => {
    getPeriods();
  }, [getPeriods]);

  if (loading) {
    return <Loading insideCard />;
  }

  if (errorLoading) {
    return <DisplayError insideCard textBody={errorLoading} />;
  }

  return renderPeriods({ periods, currentPeriod });
};
