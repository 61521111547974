import { ReactElement, useCallback } from 'react';
import { DefaultValues } from 'react-hook-form';
import { AxiosResultDefaultError } from '../../../../../api/request';
import {
  AuthenticationError,
  getSustainer,
} from '../../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../../components/info/DisplayError';
import Loading from '../../../../../components/info/Loading';
import { useParameters } from '../../../../../hooks/useParameters';
import {
  DataOwnSustainer,
  SustainerStepResponse,
} from '../../../../../types/sustainerTypes';
import { formatDataOwnSustainer } from '../../../../TuitionProcess/parts/Sustainer/utils';
import sustainerFileFormatPreviusData from '../utils/sustainerFileFormatPreviusData';

export interface SustainerFileLoaderProps {
  postulationDetailId: string | number;
  onError: (code: string, onUnknown?: (() => void) | undefined) => void;
  children: (props: {
    sustainerPassportAvailable: boolean;
    studentFullName: string;
    defaultValues: DefaultValues<any>;
    ownSustainer: DataOwnSustainer;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  SustainerStepResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export const SustainerLoaderConsumer = FetchConsumer;

export default function SustainerFileLoader({
  postulationDetailId,
  onError,
  children,
}: SustainerFileLoaderProps) {
  const {
    communeOptions,
    regionOptions,
    countryOptions,
    nationalityOptions,
    cityOptions,
  } = useParameters();

  const request = useCallback(async () => {
    return getSustainer(postulationDetailId);
  }, [postulationDetailId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            onError(error.code);
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            sustainerPassportAvailable:
              data.sustainerPassportAvailable ?? false,
            studentFullName: data.studentFullName,
            defaultValues: sustainerFileFormatPreviusData(data),
            ownSustainer: formatDataOwnSustainer({
              data,
              communeOptions,
              regionOptions,
              countryOptions,
              nationalityOptions,
              cityOptions,
            }),
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
