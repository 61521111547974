import { SETTINGS } from '../endpoints';
import request from '../request';
import { ServerSettings, Setting } from '../../types/Settings';
import { TariffPaymentMethodSettingValue } from '../../types/tariffTypes';

/**
 * Obtiene el valor de la configuracion de la variable de tiempo
 */
export function getTimeVariableConfig() {
  return request<Setting>(SETTINGS.TIME_STATUS_LESSONS);
}

/**
 * Obtiene el valor de la configuracion de las opciones de finanzas
 */
export function getFinantialConfig() {
  return request<TariffPaymentMethodSettingValue>(SETTINGS.FINANTIAL);
}

/**
 * Obtiene el valor de la configuracion de las opciones de finanzas
 */
export function updateFinantialConfig(data: TariffPaymentMethodSettingValue) {
  return request<TariffPaymentMethodSettingValue>(SETTINGS.FINANTIAL, {
    method: 'POST',
    data,
  });
}

/**
 * Actualiza el valor de la configuracion de la variable de tiempo
 */
export function updateTimeVariableConfig(newValue: string | number) {
  return request<Setting>(SETTINGS.TIME_STATUS_LESSONS, {
    method: 'POST',
    data: {
      timeStatusLessons: Number(newValue),
    },
  });
}

/**
 * Obtiene la fecha y hora del server con su offset segun la config de la univ
 */
export const getServerTimezone = () => {
  const url = SETTINGS.TIMEZONE;
  return request<ServerSettings>(url);
};
