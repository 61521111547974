import { StudyPlanItemCustom } from '../../academicOffers/interfaces/study-plan-item-custom.interface';
import {
  StudyPlanItem,
  Version,
} from '../../studyPlans/interfaces/sudy-plan-item.interface';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { MAINTAINERS, STUDY_PLANS } from '../endpoints';
import request from '../request';

interface StudyPlansListResponse {
  data: StudyPlanItem[];
  total: number;
  total_pages: number;
}

interface StudyPlansListBySchoolResponse {
  data: StudyPlanItemCustom[];
  total: number;
  total_pages: number;
}

export interface StudyPlanResponse {
  data: StudyPlanBody;
}

export interface StudyPlanBody {
  id?: number;
  name: string;
  code: string;
  schoolId: number;
  modalityId: number;
}

export interface CurriculumInfoBody {
  name: string;
  version: string;
}

export interface StudyPlansAdmissionDetailForm {
  periods: Array<{ id: number; name: string }>;
  currentPeriod?: { id: number; name: string };
}

interface StudyPlansAdmissionDetail {
  StudyPlanVersionOffer_id: number;
  StudyPlanVersion_id: number;
  StudyPlanVersion_name: string;
  AcademicOfferStudyPlanVersionOffer_id: number;
  Campus_name: string;
  Schedule_name: string;
  StudyPlan_id: number;
  StudyPlan_name: string;
  quota: number;
  enrolled: number;
  in_process: number;
  waiting: number;
  desisted: number;
  retracted: number;
  occupation_percentage: number;
}

/**
 * Obtiene el listado de planes de estudio
 */
export const getStudyPlansRequest = (
  items: number = 10,
  page: number = 0,
  searchQuery?: string,
) => {
  const search = searchQuery ? `&${searchQuery}` : '';
  const url = `${MAINTAINERS.STUDY_PLANS}?items_per_page=${items}&page=${page}${search}`;
  return request<StudyPlansListResponse>(url);
};

/**
 * Obtiene el listado de planes de estudio de una facultad
 */
export const getStudyPlansBySchoolRequest = (
  schoolId: number,
  academicOfferId: number,
  items: number = 10,
  page: number = 0,
) => {
  const url = `${MAINTAINERS.STUDY_PLANS}/school/${schoolId}/${academicOfferId}?items_per_page=${items}&page=${page}`;
  return request<StudyPlansListBySchoolResponse>(url);
};

/**
 *  Crear o editar un plan de estudio
 */
export const saveStudyPlanRequest = (data: StudyPlanBody) => {
  const isEdit = !!data.id;

  const url = `${MAINTAINERS.STUDY_PLANS}/${isEdit ? data.id : ''}`;
  return request<StudyPlanResponse>(url, {
    method: isEdit ? 'put' : 'post',
    data,
  });
};

/**
 * Obtiene un plan de estudios
 */
export const getStudyPlanRequest = (id: number | string) => {
  const url = `${MAINTAINERS.STUDY_PLANS}/${id}`;
  return request<{ data: StudyPlanItem }>(url);
};

/**
 * Obtiene info de plan de estudios y version
 */
export const getCurriculumInfoByStudyPlan = (
  studyPlanId: number,
  versionId: number,
) => {
  const url = `${MAINTAINERS.STUDY_PLANS}/curriculum/${studyPlanId}/${versionId}`;
  return request<{ data: CurriculumInfoBody }>(url);
};

export const getAdmissionDetailsForm = () => {
  const url = STUDY_PLANS.STUDY_PLAN_ADMISSION_DETAIL_FORM;
  return request<StudyPlansAdmissionDetailForm>(url, {
    method: 'get',
  });
};

export const getAdmissionDetail = ({
  periodId,
  items_per_page,
  page,
}: {
  periodId: number;
  items_per_page: number;
  page: number;
}) => {
  return request<PaginationRequestType<StudyPlansAdmissionDetail>>(
    STUDY_PLANS.STUDY_PLAN_ADMISSION_DETAIL,
    {
      method: 'GET',
      params: {
        periodId,
        items_per_page,
        page: page - 1,
      },
    },
  );
};

export const getStudyPlanVersions = () => {
  const url = `${MAINTAINERS.STUDY_PLAN_VERSION}/all`;
  return request<{
    data: Pick<
      Version,
      | 'degreeRequirements'
      | 'entryRequirements'
      | 'graduationRequirements'
      | 'id'
      | 'name'
    >[];
  }>(url);
};
