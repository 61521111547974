import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  TextOutlinedInput,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { graduateListFilter } from '../../../api/requests/graduationProcessMassive';

const dictPrefix = 'graduatesList.searchControls';

const SEARCH_TYPE_OPTIONS: OutlinedSelectOptionType[] = [
  { label: 'ID', value: 'identificator' },
  { label: 'Nombre', value: 'name' },
];

const DEFAULT_VALUES = {
  search: '',
  searchBy: null,
  cohortPeriodId: null,
  schoolId: null,
  studyPlanId: null,
  graduationPeriodId: null,
};

interface FormValues {
  search: string;
  searchBy: OutlinedSelectOptionType | null;
  cohortPeriodId: OutlinedSelectOptionType | null;
  schoolId: OutlinedSelectOptionType | null;
  studyPlanId: OutlinedSelectOptionType | null;
  graduationPeriodId: OutlinedSelectOptionType | null;
}

export type SearchParams = {
  search?: string;
  searchBy?: string | number;
  cohortPeriodId?: number;
  schoolId?: number;
  studyPlanId?: number;
  graduationPeriodId?: number;
  hasFilters?: boolean;
};

interface Props {
  onSearch?: (sp: SearchParams) => void;
  onClear?: () => void;
  onDownload?: () => void;
  downloadIsAvailable?: boolean;
  isDownloadLoading?: boolean;
}

export function SearchControls({
  onSearch = () => null,
  onClear = () => null,
  onDownload = () => null,
  downloadIsAvailable = true,
  isDownloadLoading = false,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [periods, setPeriods] = useState<OutlinedSelectOptionType[]>([]);
  const [schools, setSchools] = useState<OutlinedSelectOptionType[]>([]);
  const [studyPlans, setStudyPlans] = useState<OutlinedSelectOptionType[]>([]);

  const { handleSubmit, control, reset, watch } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const [searchBy, search] = watch(['searchBy', 'search']);

  const handleSearch = (values: FormValues) => {
    const {
      search,
      searchBy,
      cohortPeriodId,
      schoolId,
      studyPlanId,
      graduationPeriodId,
    } = values;

    onSearch({
      search: search?.trim() !== '' ? search : undefined,
      searchBy: searchBy?.value,
      cohortPeriodId: cohortPeriodId?.value as number,
      schoolId: schoolId?.value as number,
      studyPlanId: studyPlanId?.value as number,
      graduationPeriodId: graduationPeriodId?.value as number,
      hasFilters: Object.values(values).some((value) => value !== null),
    });
  };

  const handleClear = () => {
    reset(DEFAULT_VALUES);
    onClear();
  };

  const getFilters = useCallback(async () => {
    const { data } = await graduateListFilter();
    if (data) {
      setPeriods(
        data.data.periods.map(({ id: value, name: label }) => ({
          label,
          value,
        })),
      );
      setSchools(
        data.data.schools.map(({ id: value, name: label }) => ({
          label,
          value,
        })),
      );
      setStudyPlans(
        data.data.studyPlans.map(({ id: value, name: label }) => ({
          label,
          value,
        })),
      );
      reset();
    }
  }, [reset]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      style={{
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '30% auto',
        gridGap: isMobile ? 8 : 14,
      }}
    >
      <div style={{ maxHeight: isMobile ? 'unset' : 100 }}>
        <OutlinedSelect
          name="searchBy"
          control={control}
          options={SEARCH_TYPE_OPTIONS}
          rules={{
            validate: {
              validateSearchField: (value) => {
                if (!value && search) {
                  return `${t(`${dictPrefix}.error`)}`;
                } else {
                  return undefined;
                }
              },
            },
          }}
        />
      </div>
      <div style={{ maxHeight: isMobile ? 'unset' : 100 }}>
        <TextOutlinedInput
          name="search"
          control={control}
          placeholder={t(`${dictPrefix}.nameOrId`)}
          rules={{
            validate: {
              validateSearchByField: (value) => {
                if (!value && searchBy) {
                  return `${t(`${dictPrefix}.error`)}`;
                } else {
                  return undefined;
                }
              },
            },
          }}
        />
      </div>
      <div style={{ maxHeight: isMobile ? 'unset' : 100 }}>
        <OutlinedSelect
          name="cohortPeriodId"
          placeholder={t(`${dictPrefix}.cohorte`)}
          control={control}
          options={periods}
        />
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : 'auto auto',
          gridGap: isMobile ? 8 : 14,
        }}
      >
        <div style={{ maxHeight: isMobile ? 'unset' : 100 }}>
          <OutlinedSelect
            name="schoolId"
            placeholder={t(`${dictPrefix}.school`)}
            control={control}
            options={schools}
          />
        </div>
        <div style={{ maxHeight: isMobile ? 'unset' : 100 }}>
          <OutlinedSelect
            name="studyPlanId"
            placeholder={t(`${dictPrefix}.studyPlan`)}
            control={control}
            options={studyPlans}
          />
        </div>
      </div>
      <div style={{ maxHeight: isMobile ? 'unset' : 100 }}>
        <OutlinedSelect
          name="graduationPeriodId"
          placeholder={t(`${dictPrefix}.graduationPeriod`)}
          control={control}
          options={periods}
        />
      </div>

      <div className="d-flex flex-wrap" style={{ gap: 8 }}>
        <Button
          type="submit"
          text={t(`common.actions.search`)}
          size="md"
          style={{ maxHeight: 20, width: isMobile ? '100%' : undefined }}
        />
        <Button
          text={t(`common.actions.clean`)}
          size="md"
          outlined
          onClick={handleClear}
          style={{ maxHeight: 20, width: isMobile ? '100%' : undefined }}
        />
        {downloadIsAvailable && (
          <Button
            className="ml-auto"
            size="md"
            text={t(`common.actions.downloadList`)}
            icon="download"
            outlined
            loading={isDownloadLoading}
            onClick={onDownload}
            style={{
              maxHeight: 20,
              width: isMobile ? '100%' : undefined,
              minWidth: '207.88px',
            }}
          />
        )}
      </div>
    </form>
  );
}
