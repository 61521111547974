import request from '../request';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { MAINTAINERS } from '../endpoints';
import {
  AcademicOffersType,
  AcademicOffersTypeDuplicate,
} from '../../types/AcademicOffersType';

import { SchoolsListItem } from './schools';
export interface AcademicOffersBody {
  periodId: number | null;
  duplicatedAcademicOfferId?: number | null;
  quota: number | null;
  status?: string;
}

export type ResolutionItem = {
  id: number;
  resolutionNumber: string;
  resolutionFile: any;
};

export interface AcademicOfferAndSchools {
  data: AcademicOffersType[] | SchoolsListItem[][];
}

export interface UpdateQuotaType {
  studyPlanId: number;
  studyPlanVersionId?: number;
  studyPlanVersionOfferId?: number;
  campusId?: number;
  scheduleId?: number;
  academicOfferStudyPlanVersionOfferId?: number;
  quota: number | null;
}

export interface GetOfferReport {}

/**
 * Obtiene el listado de version del plan de estudio
 */
export const getAcademicOffersRequest = (
  items: number = 10,
  page: number = 0,
) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/?items_per_page=${items}&page=${page}`;
  return request<PaginationRequestType<AcademicOffersType>>(url);
};

/**
 * Obtiene el listado de version del plan de estudio
 */
export const getAcademicOfferAndSchools = (id: number) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/edit/${id}`;
  return request<AcademicOfferAndSchools>(url);
};

/**
 * Obtiene el listado de version del plan de estudio
 */
export const getAcademicOffersNoDraftRequest = (
  items: number = 10,
  page: number = 0,
) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/non-draft?items_per_page=${items}&page=${page}`;
  return request<PaginationRequestType<AcademicOffersTypeDuplicate>>(url);
};

/**
 *  Crear oferta academica
 */
export const saveAcademicOffersRequest = (data: AcademicOffersBody) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}`;
  return request<any>(url, {
    method: 'post',
    data,
  });
};

/**
 *  Editar oferta academica
 */
export const editAcademicOffersRequest = (
  data: AcademicOffersBody,
  id: string,
) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/${id}`;
  return request<any>(url, {
    method: 'put',
    data,
  });
};

/**
 *  Eliminar oferta academica
 */
export const deleteAcademicOfferRequest = (id: string) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/${id}`;
  return request<any>(url, {
    method: 'delete',
  });
};

/**
 *  Validar cupos oferta academica
 */
export const validateQuotaRequest = (id: string) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/validate-quota/${id}`;
  return request<any>(url, {
    method: 'get',
  });
};

/**
 *  Confirmar cupos de oferta academica
 */
export const confirmQuota = (data: UpdateQuotaType[], id: number) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/update-quota/${id}`;
  return request<any>(url, {
    method: 'post',
    data,
  });
};

export interface ConfirmOfferAcademic {
  studyPlanId: number;
  studyPlanName: string;
  studyPlanVersionId: number;
  studyPlanVersionName: string;
  versionOfferId: number;
  versionOfferCampusId: number;
  versionOfferScheduleId: number;
  campusId: number;
  campusName: string;
  scheduleId: number;
  scheduleName: string;
}

export const confirmOfferAcademic = (academicOfferId: number) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/confirm-offer/${academicOfferId}`;
  return request<Array<ConfirmOfferAcademic>>(url, { method: 'post' });
};

export const getOfferReportRequest = (
  academicOfferId: number,
  items: number,
  page: number,
) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/offer-report/${academicOfferId}?items_per_page=${items}&page=${page}`;
  return request<any>(url, { method: 'post' });
};

export const getvisualizeRequest = (
  academicOfferId: number,
  items_per_page: number,
  page: number,
) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/visualize/${academicOfferId}/?items_per_page=${items_per_page}&page=${page}`;
  return request<any>(url, { method: 'post' });
};

export const attachResolution = (resolution: ResolutionItem) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/attach-resolution/${resolution.id}`;
  const formData = new FormData();
  formData.append('resolutionFile', resolution.resolutionFile);
  formData.append('resolutionNumber', resolution.resolutionNumber);

  return request<any>(url, {
    method: 'post',
    data: formData,
  });
};

export const getResolutionUrl = (id: number) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/resolution-url/${id}`;
  return request<any>(url, {
    method: 'get',
  });
};

export const completeOffer = (id: number) => {
  const url = `${MAINTAINERS.ACADEMIC_OFFERS}/complete-offer/${id}`;
  return request<any>(url, {
    method: 'get',
  });
};
