import { Card } from 'reactstrap';
import DocumentsGridCardsContent from './parts/DocumentsGridCardsContent';
import { PeriodsLoader } from './parts/PeriodsLoader';
export function DocumentsGridCards() {
  return (
    <PeriodsLoader
      renderPeriods={({ periods, currentPeriod }) => {
        return (
          <div className="mx-3">
            <Card className="p-4">
              <DocumentsGridCardsContent
                periods={periods}
                currentPeriod={currentPeriod}
              />
            </Card>
          </div>
        );
      }}
    />
  );
}
