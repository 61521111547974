import { addToast, Button } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { saveStudyPlanRequest } from '../api/requests/studyPlans';
import GoBackButton from '../components/buttons/GoBackButton';
import { useLayoutState } from '../hooks/useLayoutState';
import useUserState from '../hooks/useUserState';
import { PermissionName } from '../types/Auth';
import StudyPlansForm from './StudyPlanForm';
import { StudyPlanRecordLoader } from './StudyPlanRecordLoader';
import VersionList from './version/VersionList';

const StudyPlanRecordEdit = () => {
  const { isAuthorizedTo } = useUserState();
  const { hideTabs, hideErrorModal } = useLayoutState();
  useEffect(() => {
    hideTabs();
    hideErrorModal();
  }, [hideTabs, hideErrorModal]);

  let history = useHistory();
  const goBack = useCallback(() => {
    history.push(`/study-plans`);
  }, [history]);

  const { t } = useTranslation();

  const { id: studyPlanId } = useParams<{ id?: string }>();

  const onSubmit = useCallback(
    async (values) => {
      if (!studyPlanId) {
        return;
      }
      const response = await saveStudyPlanRequest({
        name: values.name,
        code: values.code,
        modalityId: values.modality.value,
        schoolId: values.school.value,
        id: Number(studyPlanId),
      });
      if (!response.error) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t('common.messages.editSuccess'),
        });
        goBack();
      } else {
        return response.error.code;
      }
    },
    [studyPlanId, goBack, t],
  );

  if (!studyPlanId) {
    return <Redirect to="/study-plans/record" />;
  }

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={goBack} />
        </div>
        <StudyPlanRecordLoader studyPlanId={Number(studyPlanId)}>
          {({ modalities, schools, studyPlan }) => {
            let defaultValues;
            if (studyPlan) {
              const { code, name, modality, school } = studyPlan;
              defaultValues = {
                code,
                name,
                modality: {
                  label: modality.name,
                  value: modality.id,
                },
                school: {
                  label: school.name,
                  value: school.id,
                },
              };
            }
            return (
              <>
                <StudyPlansForm
                  modalities={modalities}
                  schools={schools}
                  defaultValues={defaultValues}
                  onSubmit={onSubmit}
                  readOnly={!isAuthorizedTo([PermissionName.EDIT_STUDY_PLAN])}
                >
                  {({ isSubmitting, isDirty }) => (
                    <Row className="pt-5">
                      <Col xs={12} md={4} className="ml-auto">
                        <div>
                          <Button
                            type="submit"
                            text={t('common.actions.saveChanges')}
                            loading={isSubmitting}
                            disabled={
                              !isDirty ||
                              !isAuthorizedTo([PermissionName.EDIT_STUDY_PLAN])
                            }
                            fullwidth
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </StudyPlansForm>
                {!!studyPlanId && (
                  <VersionList planStudy={{ id: +studyPlanId }} />
                )}
              </>
            );
          }}
        </StudyPlanRecordLoader>
      </Card>
    </Container>
  );
};

export default StudyPlanRecordEdit;
