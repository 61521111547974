import { addToast, Button, Icon } from '@octano/global-ui';
import { Fragment, FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormText, Row, Tooltip } from 'reactstrap';
import { RequestedDocument } from '../../../../types/signDocumentationTypes';
import isIcon from '../../../../utils/isIcon';
import UploadModal from './UploadModal';

export interface IDocumentCardProps {
  postulationDetailId: number | string;
  errorText?: string;
  onDownload: (requestedDocument: RequestedDocument) => Promise<void>;
  onUploaded: (requestedDocument: RequestedDocument) => void;
  onRequestSign?: (requestedDocument: RequestedDocument) => Promise<void>;
  requestedDocument: RequestedDocument;
  requiredDocument?: RequestedDocument | null;
  size: 'md' | 'sm';
  showErrors: boolean;
}
export interface IUrlLinkProps {
  href?: string;
}

export const UrlLink: FunctionComponent<IUrlLinkProps> = ({
  href,
  children,
}) => {
  return (
    <a
      className="fw-600 text-white"
      target="_blank"
      rel="noreferrer"
      href={href}
    >
      {children}
    </a>
  );
};

/**
 * Tarjeta que despliega el documento requerido para ser cargado.
 */
const DocumentCard = ({
  postulationDetailId,
  errorText,
  onDownload,
  onRequestSign: onRequestSignProp,
  onUploaded: onUploadedProp,
  requestedDocument,
  requiredDocument,
  size,
  showErrors,
}: IDocumentCardProps) => {
  const prefix = 'postulationDocs';
  const { t } = useTranslation();
  const requiredMsgError = t(`${prefix}.errorRequired`);

  const document = requestedDocument.document;
  const uploaded = requestedDocument.uploaded;
  const downloaded = requestedDocument.downloaded;
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const btnIconName = uploaded ? 'check' : undefined;
  const btnText = `${prefix}.buttons.${uploaded ? 'uploaded' : 'upload'}`;
  const btnSignText = `${prefix}.buttons.${uploaded ? 'signed' : 'sign'}`;
  const btnConfirmTex = `${prefix}.buttons.${
    uploaded ? 'read' : 'confirmReading'
  }`;
  const [open, setOpen] = useState<boolean>(false);
  const closeModal = () => setOpen(false);

  const onUploaded = useCallback(
    (requestedDocument: RequestedDocument) => {
      onUploadedProp(requestedDocument);
      closeModal();
    },
    [onUploadedProp],
  );

  const [requestedSign, setRequestedSign] = useState<boolean>(false);
  const onRequestSign = useCallback(async () => {
    if (!onRequestSignProp) {
      return;
    }
    setRequestedSign(true);
    await onRequestSignProp(requestedDocument);
  }, [onRequestSignProp, requestedDocument]);
  const RequestSignBtn = useCallback(
    () => (
      <Button
        onClick={onRequestSign}
        outlined
        size="sm"
        text={t(
          `${prefix}.buttons.${
            requestedSign ? 'requestedSign' : 'requestSign'
          }`,
        )}
        fullwidth
      />
    ),
    [t, onRequestSign, requestedSign],
  );
  const ConfirmBtn = useCallback(
    () => (
      <Button
        icon={btnIconName}
        onClick={() => {
          if (requiredDocument && !requiredDocument?.downloaded) {
            addToast({
              icon: 'information',
              color: 'warning',
              text: t(`${prefix}.downloadRequirementRequired`, {
                name: requiredDocument?.document?.name,
                action: t(btnSignText),
              }),
            });
          } else if (downloaded) {
            setOpen(true);
          } else {
            addToast({
              icon: 'information',
              color: 'warning',
              text: t(`${prefix}.confirmRequirement`),
            });
          }
        }}
        size="sm"
        text={t(btnConfirmTex)}
        fullwidth
        disabled={uploaded}
      />
    ),
    [
      btnIconName,
      t,
      btnConfirmTex,
      uploaded,
      requiredDocument,
      downloaded,
      btnSignText,
    ],
  );

  const requiredDownload = document.generated && !requestedDocument.downloaded;
  const UploadBtn = useCallback(
    () => (
      <Button
        icon={btnIconName}
        onClick={() => {
          if (requiredDocument && !requiredDocument?.downloaded) {
            addToast({
              icon: 'information',
              color: 'warning',
              text: t(`${prefix}.downloadRequirementRequired`, {
                name: requiredDocument?.document?.name,
                action: t(btnText),
              }),
            });
          } else if (requiredDownload) {
            addToast({
              icon: 'information',
              color: 'warning',
              text: t(`${prefix}.downloadRequirement`, {
                action: t(btnText),
              }),
            });
          } else {
            setOpen(true);
          }
        }}
        size="sm"
        text={t(btnText)}
        fullwidth
      />
    ),
    [btnIconName, btnText, requiredDownload, requiredDocument, t],
  );

  return (
    <Fragment>
      <div className="d-flex align-items-end">
        <span className="text-uppercase fs-18 fw-600 text-secondary">
          {document.name}
          {document.required && ' *'}
          {Boolean(document.hint) && (
            <span id={`inputTooltip_${document.code}`} className="pl-2">
              <Icon
                className="cursor-pointer"
                name="information_input"
                size="1rem"
              />
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                autohide={false}
                target={`inputTooltip_${document.code}`}
                toggle={() => setTooltipOpen((prevState) => !prevState)}
              >
                {document.hint} <UrlLink />
              </Tooltip>
            </span>
          )}
        </span>
      </div>

      <div className="bg-light px-4 py-3 mb-2" style={{ minHeight: '150px' }}>
        <Row>
          <Col
            className="text-center align-self-center py-3 py-md-1 flex-grow-1"
            xs={12}
            md={size === 'md' ? 6 : 12}
          >
            <Icon
              name={isIcon(document.icon) ? document.icon : 'certificate'}
              size={size === 'md' ? '95px' : '79px'}
              color="secondary"
            />
          </Col>
          <Col
            className="text-center px-5 px-md-3 align-self-end"
            xs={12}
            md={size === 'md' ? 6 : 12}
          >
            {!uploaded && document.uploadMethod === 'signature' && (
              <RequestSignBtn />
            )}
            {document.generated && (
              <Button
                icon="download"
                onClick={() => {
                  if (requiredDocument && !requiredDocument?.downloaded) {
                    addToast({
                      icon: 'information',
                      color: 'warning',
                      text: t(`${prefix}.downloadRequirementRequired`, {
                        name: requiredDocument?.document?.name,
                        action: t(`${prefix}.buttons.download`),
                      }),
                    });
                  } else {
                    onDownload && onDownload(requestedDocument);
                  }
                }}
                outlined
                size="sm"
                text={t(`${prefix}.buttons.download`)}
                fullwidth
              />
            )}
            {document.uploadMethod === 'signature' && <UploadBtn />}
            {document.uploadMethod === 'identification' && <UploadBtn />}
            {document.uploadMethod === 'default' && <UploadBtn />}
            {document.uploadMethod === 'confirmation' && <ConfirmBtn />}
          </Col>
        </Row>
      </div>

      <FormText
        className="g-input-error"
        color="danger"
        style={{ minHeight: '21px' }}
      >
        {showErrors && !uploaded ? requiredMsgError : null}
      </FormText>
      <UploadModal
        postulationDetailId={postulationDetailId}
        open={open}
        closeModal={closeModal}
        code={document.code}
        name={document.name}
        description={document.description}
        commentRequired={document.commentRequired}
        uploadMethod={document.uploadMethod}
        onUploaded={() => onUploaded(requestedDocument)}
      />
    </Fragment>
  );
};

export default DocumentCard;
