import { Button, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  COURSE_TYPES,
  CourseInfo,
  LegendInfo,
} from '../../types/CurriculumTypes';
export interface CurriculumSemesterProps {
  id: number;
  setShowCourseModal: Function;
  setCurrentSemester: Function;
  semesterCoursesList: Array<CourseInfo>;
  coursesList: Array<CourseInfo>;
  setCoursesList: Function;
  readOnly: boolean;
  legend: LegendInfo;
  setLegend: (legend: LegendInfo) => void;
  setDisabledEditButton: (value: boolean) => void;
}

const CurriculumSemester = (props: CurriculumSemesterProps) => {
  const {
    id,
    setShowCourseModal,
    setCurrentSemester,
    semesterCoursesList,
    coursesList,
    setCoursesList,
    readOnly,
    legend,
    setLegend,
    setDisabledEditButton,
  } = props;

  const prefix = 'studyPlans.curriculum.semestersList';
  const { t } = useTranslation();

  const { mentionId, curriculumId } = useParams<{
    mentionId?: string;
    curriculumId?: string;
  }>();

  const getTotalCredits = () => {
    let count = 0;
    semesterCoursesList.forEach(
      (course) => (count += parseInt(course.credits.toString())),
    );
    return count;
  };

  const removeCourse = (courseToRemove: any) => {
    const auxList = [...coursesList];
    const index = coursesList.findIndex(
      (course) =>
        course.semester === courseToRemove.semester &&
        course.type === courseToRemove.type &&
        course.name === courseToRemove.name &&
        course.credits === courseToRemove.credits,
    );
    auxList.splice(index, 1);
    setCoursesList(auxList);

    let auxLegend = { ...legend };
    if (courseToRemove.type === COURSE_TYPES.OBLIGATORIO.alias) {
      auxLegend = {
        ...auxLegend,
        total: auxLegend.total - courseToRemove.credits,
        required: auxLegend.required - courseToRemove.credits,
      };
    } else if (courseToRemove.type.includes('Optativo')) {
      auxLegend = {
        ...auxLegend,
        total: auxLegend.total - courseToRemove.credits,
        optional: auxLegend.optional - courseToRemove.credits,
      };
    } else if (courseToRemove.type === COURSE_TYPES.OTROS.alias) {
      auxLegend = {
        ...auxLegend,
        total: auxLegend.total - courseToRemove.credits,
        other: auxLegend.other - courseToRemove.credits,
      };
    } else if (courseToRemove.type === COURSE_TYPES.ACTIVIDAD.alias) {
      auxLegend = {
        ...auxLegend,
        activities: auxLegend.activities - 1,
      };
    }
    setLegend(auxLegend);

    if (
      (mentionId && !isNaN(parseInt(mentionId))) ||
      (curriculumId && !isNaN(parseInt(curriculumId)))
    ) {
      setDisabledEditButton(false);
    }
  };

  const printCourses = () => {
    return semesterCoursesList.map((course, index) => (
      <div
        key={index}
        className="course-box"
        style={{
          border: '1px solid var(--secondary)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxWidth: '100%',
          width: '100%',
          minHeight: '145px',
        }}
      >
        {course.type === COURSE_TYPES.OBLIGATORIO.alias ||
        course.type === COURSE_TYPES.ACTIVIDAD.alias ? (
          <span className="name">{course.name}</span>
        ) : (
          <span></span>
        )}
        <div className="course-box__info">
          {course.shortening ? (
            <span>
              {t(`${prefix}.code`)}: {course.shortening}
            </span>
          ) : (
            ''
          )}
          {course.type !== COURSE_TYPES.ACTIVIDAD.alias ? (
            <span>
              {t(`${prefix}.credits`)}: {course.credits}
            </span>
          ) : (
            ''
          )}
          <span className="type">{course.type}</span>
          {!readOnly && (
            <span className="delete" onClick={() => removeCourse(course)}>
              <Icon className="trash" name="trash" key={index} size="13px" />
            </span>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div className="semester" style={{ width: '140px', marginRight: '10px' }}>
      <div className="semester-courses">
        <span className="title" style={{ width: '100%', textAlign: 'center' }}>
          {t(`${prefix}.semester`)} {id}
        </span>
        <div
          className="dashed-border"
          style={{ width: '100%', textAlign: 'center' }}
        >
          <div className="circle">
            <Button
              text=""
              type="button"
              size="sm"
              icon="plus"
              className={readOnly ? 'read-only' : ''}
              key="plus"
              onClick={() => {
                setShowCourseModal(true);
                setCurrentSemester(id);
              }}
              disabled={readOnly}
            />
          </div>
        </div>
        {printCourses()}
      </div>
      <span className="credits" style={{ width: '140px', marginRight: '10px' }}>
        {t(`${prefix}.credits`)} <span>{getTotalCredits()}</span>
      </span>
    </div>
  );
};

export default CurriculumSemester;
