import { addToast } from '@octano/global-ui';
import { useMemo } from 'react';
import { Card, Col, Row } from 'reactstrap';

import { saveStudentFile } from '../../../../api/requests/tuitionProcess';
import DynamicForm from '../../../../components/DynamicForm/DynamicForm';
import DynamicContact from '../../../../components/DynamicForm/parts/DynamicContact';
import { useStepState } from '../../../../components/step/useStepState';
import { useParameters } from '../../../../hooks/useParameters';
import { useTuitionProcess } from '../../../TuitionProcess/TuitionProcessContext';
import { useTuitionProcessNoSua } from '../../hooks/useTuitionProcessNoSua';

import HeaderStep from '../HeaderStep';
import StudentBackgroundNoSua from './parts/StudentBackgroundNoSua';
import StudentFileLoader from './parts/StudentFileLoader';

const StudentFileNoSuaStep = () => {
  //Custom Hooks
  const {
    prefix: rootPrefix,
    postulationDetailId,
    onError,
  } = useTuitionProcess();
  const { nextStep } = useStepState();
  const { postulant } = useTuitionProcessNoSua();
  const { countryOptions, institutionTypeOptions } = useParameters();

  //Step Prefix
  const prefix = useMemo(() => `${rootPrefix}.studentFile`, [rootPrefix]);

  //Submit form function
  const onSubmit = async (values: any) => {
    if (postulationDetailId) {
      const responses = Object.entries(values)
        .map(([key, value]) => ({
          fieldName: key,
          value,
        }))
        .filter(({ value }) => value);

      /**
       * si el país es chile igual mando location y custom city
       */
      if (String(values.country?.label).toLowerCase().includes('chile')) {
        responses.push(
          { fieldName: 'location', value: values.commune?.label },
          { fieldName: 'customCity', value: values.city?.label },
        );
      }

      const { error } = await saveStudentFile(postulationDetailId, {
        responses,
      });

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: 'Ha ocurrido un error al guardar la información',
        });
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: 'Datos guardados correctamente',
        });
        nextStep();
      }
    }
  };

  const sectionComponents = (photo: string | null) => {
    return {
      postulantInformation: (
        <StudentBackgroundNoSua
          photo={photo}
          key={Math.floor(Math.random() * 100)}
        />
      ),
      academicContact: (
        <DynamicContact
          type="studentFile"
          prefix={`tuitionProcess.onSite.studentFile.academicContactSection`}
          optionalFields={['addressExtra', 'phone']}
          key={Math.floor(Math.random() * 100)}
        />
      ),
    };
  };

  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12}>
          <HeaderStep prefixStep={prefix} showBackBtn={true} />
        </Col>
      </Row>

      <StudentFileLoader {...{ postulationDetailId, onError }}>
        {({ defaultValues, photo }) => (
          <DynamicForm
            key={1}
            dynamicFormId={1}
            postulantId={postulant?.id!}
            defaultValues={defaultValues}
            sectionComponents={sectionComponents(photo)}
            options={{
              countryHighSchool: countryOptions,
              typeHighSchool: institutionTypeOptions,
            }}
            onSubmit={onSubmit}
          />
        )}
      </StudentFileLoader>
    </Card>
  );
};

export default StudentFileNoSuaStep;
