import { addToast } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';

import {
  downloadPetitionsListByFilter,
  getStudentsPetitionsList,
} from '../../../api/requests/studentPetitions';
import { StudentPetitionsRow } from '../../../types/studentPetitions';
import { downloadFromBlob } from '../../../utils/blob';
import PetitionsExcelReport from '../parts/PetitionsExcelReport/PetitionsExcelReport';
import PetitionsExcelReportInfoModal from '../parts/PetitionsExcelReport/PetitionsExcelReportInfoModal';
import { SearchParams } from '../types/Petitions';
import PetitionsTable from './parts/PetitionsTable';
import { SearchControls } from './parts/SearchControls';

const dictPrefix = 'studentPetitions.received';
const ITEMS_PER_PAGE = 10;

export default function ReceivedList() {
  const { t } = useTranslation();
  const [isLoadingTable, setIsTableLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const [tableData, setTableData] = useState<StudentPetitionsRow[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalGraduates, setTotalGraduates] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<SearchParams>({});

  const handleSearch = useCallback(
    async ({
      searchParams,
      page = 0,
    }: {
      searchParams?: SearchParams;
      page: number;
    }) => {
      setIsTableLoading(true);
      const { data, error } = await getStudentsPetitionsList({
        items: ITEMS_PER_PAGE,
        page: page,
        search: searchParams?.search,
        status: searchParams?.status,
        studyPlanId: searchParams?.studyPlanId,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalGraduates(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleDownload = useCallback(
    async (requestBody?: any) => {
      const { data, error, status } = await downloadPetitionsListByFilter({
        ...requestBody,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.generatingFile.errorOnDownload`),
          color: 'danger',
          icon: 'error',
        });
      }

      if (status === 200) {
        addToast({
          text: t(`${dictPrefix}.generatingFile.download`),
          color: 'success',
          icon: 'success',
        });
        const blob = new Blob([data], { type: 'vnd.ms-excel' });
        const todaysDate = dayjs().format('DD_MMYY');
        const fileName = `ReporteDeSolicitudes_${todaysDate}.xlsx`;
        downloadFromBlob(blob, fileName);
        setShowModal(false);
      }

      if (status === 204) {
        setShowInfoModal(true);
        setShowModal(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, t],
  );

  const handleClear = async () => {
    setTableData([]);
    setFilters({});
    setCurrentPage(0);
  };

  useEffect(() => {
    handleSearch({ searchParams: filters, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearch, currentPage]);

  return (
    <Card className="mx-3 p-4">
      <div className="mb-5">
        <SearchControls
          onSearch={handleSearch}
          onClear={handleClear}
          setFilters={setFilters}
        />

        <PetitionsExcelReport
          disabledExcelReport={tableData.length === 0}
          showModalExcelReport={showModal}
          toggleModalExcelReport={() => {
            setShowModal(!showModal);
          }}
          onDownload={handleDownload}
          filters={filters}
          showFilters={{
            status: true,
            studyPlanId: true,
            petitionTypeId: true,
          }}
        />
      </div>

      <PetitionsExcelReportInfoModal
        open={showInfoModal}
        onClose={() => setShowInfoModal(!showInfoModal)}
        onAfterClose={() => setShowModal(!showModal)}
      />

      <PetitionsTable
        isLoadingResults={isLoadingTable}
        data={tableData}
        totalPages={totalPages}
        totalPetitions={totalGraduates}
        currentPage={currentPage}
        onChangePage={(page) => {
          setCurrentPage(page);
        }}
        hasFilters={filters?.hasFilters}
      />
    </Card>
  );
}
