import { POSTULANT_PROCESS } from '../endpoints';
import request, { AxiosResult } from '../request';

export interface OtherDocumentOfPostulantProcess {
  id: number;
  name: string;
  extension: string;
  description: string;
  postulationDetailId: number;
}

export type PostulationStateByRutResponse =
  | {
      state: 'POSTULANT_NOT_FOUND' | 'NOT_IN_PROCESS';
    }
  | {
      state: 'IN_PROCESS';
      student: {
        fullName: string;
        rut: string;
        names: string;
        paternalName: string;
        maternalName: string;
      };
      studyPlan: {
        name: string;
        campus: string;
        schedule: string;
      };
      postulation: {
        status: string;
        number: string;
        postulationDetailId: number;
      };
      admissionType: {
        name: string;
      };
    }
  | {
      state: 'IN_WAITING_LIST';
      nextSubprocess?: {
        startedAt: string;
        endedAt: string;
      };
    };

/**
 * Obtiene el estado de la postulacion por rut
 */
export async function getPostulationStateByRut(
  rut: string,
): Promise<AxiosResult<PostulationStateByRutResponse>> {
  const url = POSTULANT_PROCESS.STATE_BY_RUT;

  return request<PostulationStateByRutResponse>(url, {
    params: { rut },
  });
}

export function changeDocumentStateOfPostulantProcess(
  id: number,
  data: { status: string },
) {
  const url = `${POSTULANT_PROCESS.CHANGE_DOCUMENT_STATE}/${id}`;
  return request<any>(url, {
    method: 'PUT',
    data,
  });
}

export function changeIdentityCardStateOfPostulantProcess(
  id: number,
  data: { status: string },
) {
  const url = `${POSTULANT_PROCESS.CHANGE_IDENTITY_CARD_STATE}/${id}`;
  return request<any>(url, {
    method: 'PUT',
    data,
  });
}

export function deleteDocumentOfPostulantProcess(id: number) {
  const url = `${POSTULANT_PROCESS.DELETE_DOCUMENT}/${id}`;
  return request<any>(url, {
    method: 'delete',
  });
}

export function deleteIdentityCardOfPostulantProcess(id: number) {
  const url = `${POSTULANT_PROCESS.DELETE_IDENTITY_CARD}/${id}`;
  return request<any>(url, {
    method: 'delete',
  });
}

export function getLinkDownloadDocumentOfPostulantProcess(id: number) {
  const url = `${POSTULANT_PROCESS.GET_LINK_DOWNLOAD_DOCUMENT}/${id}`;
  return request<any>(url, {
    method: 'get',
  });
}

export function getLinkDownloadIdentityCardOfPostulantProcess(id: number) {
  const url = `${POSTULANT_PROCESS.GET_LINK_DOWNLOAD_IDENTITY_CARD}/${id}`;
  return request<any>(url, {
    method: 'get',
  });
}

export function uploadDocumentOfPostulantProcess(
  postulationDetailId: number,
  formData: FormData,
) {
  const url = POSTULANT_PROCESS.REVIEW_UPLOAD_DOCUMENT(postulationDetailId);
  return request<any>(url, {
    method: 'post',
    data: formData,
  });
}

export function uploadIdentityCardPostulantProcess(
  postulationDetailId: number,
  formData: FormData,
) {
  const url =
    POSTULANT_PROCESS.REVIEW_UPLOAD_IDENTITY_CARD(postulationDetailId);
  return request<any>(url, {
    method: 'post',
    data: formData,
  });
}

export function getOtherDocumentOfPostulantProcess(id: number) {
  const url = `${POSTULANT_PROCESS.GET_OTHER_DOCUMENTS}/${id}`;
  return request<OtherDocumentOfPostulantProcess[]>(url, {
    method: 'get',
  });
}

export function uploadOtherDocumentOfPostulantProcess(formData: FormData) {
  const url = `${POSTULANT_PROCESS.UPLOAD_OTHER_DOCUMENTS}`;
  return request<OtherDocumentOfPostulantProcess[]>(url, {
    method: 'post',
    data: formData,
  });
}

export function downloadOtherDocumentOfPostulantProcess(id: number) {
  const url = `${POSTULANT_PROCESS.DOWNLOAD_OTHER_DOCUMENTS}/${id}`;
  return request<string>(url, {
    method: 'get',
  });
}

export function deleteOtherDocumentOfPostulantProcess(id: number) {
  const url = `${POSTULANT_PROCESS.DELETE_OTHER_DOCUEMNTS}/${id}`;
  return request<any>(url, {
    method: 'delete',
  });
}

export function showOtherDocumentOfPostulantProcess(id: number) {
  const url = `${POSTULANT_PROCESS.VIEW_OTHER_DOCUMENTS}/${id}`;
  return request<any>(url, {
    method: 'get',
  });
}

export function showIdentityCardDocumentOfPostulantProcess(id: number) {
  const url = `${POSTULANT_PROCESS.VIEW_IDENTITY_CARD}/${id}`;
  return request<any>(url, {
    method: 'get',
  });
}
