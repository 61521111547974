import { Select, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import { format as formatRut } from 'rut.js';
import SectionTitle from '../../../../../components/text/SectionTitle';
import { formatDate } from '../../../../../utils/dates';
import { useParameters } from '../../../../../hooks/useParameters';
import StudentPhoto, {
  StudentPhotoTexts,
} from '../../../../TuitionProcess/parts/StudentFile/StudentPhoto';
import { useValidations } from '../../../../../hooks/useValidations';

/**
 * Sección de Antecedentes del estudiante SIN SUA
 */

const StudentBackgroundNoSua = ({ photo }: { photo: string | null }) => {
  const { control, watch } = useFormContext();

  const prefix = `tuitionProcess.onSite.studentFile.backgroundSection`;
  const { t } = useTranslation();
  const { validateTextNotEmpty } = useValidations();
  const { countryOptions, nationalityOptions } = useParameters();

  const [rut, birthday] = watch(['rut', 'birthday']);

  const studentPhotoTexts = t<any, StudentPhotoTexts>(
    `tuitionProcess.onSite.studentFile.studentPhoto`,
    { returnObjects: true },
  );
  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-5" xs={12} lg="auto">
          <FormGroup>
            <div className="m-auto mw-100" style={{ width: '121px' }}>
              <StudentPhoto texts={studentPhotoTexts} defaultPhoto={photo} />
            </div>
          </FormGroup>
        </Col>
        <Col xs={12} lg={true}>
          <Row>
            <Col className="pb-3" xs={12} lg={6}>
              {rut ? (
                <TextInput
                  name="rut"
                  label={t(`${prefix}.rut`)}
                  formatter="rut"
                  value={formatRut(rut)}
                  defaultValue={formatRut(rut)}
                  disabled
                  shouldUnregister={true}
                />
              ) : (
                <Row>
                  <Col xs={5} className="pr-1 pr-md-2">
                    <Select
                      name="passportCountry"
                      label={t(`${prefix}.passportCountry`)}
                      options={countryOptions}
                      isClearable={false}
                      control={control}
                      shouldUnregister={true}
                      disabled
                    />
                  </Col>
                  <Col xs={7} className="pl-1 pl-md-2">
                    <TextInput
                      name="passportNumber"
                      label={t(`${prefix}.passportNumber`)}
                      control={control}
                      shouldUnregister={true}
                      disabled
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="namesStudent"
                label={t(`${prefix}.names`)}
                control={control}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="paternalSurname"
                label={t(`${prefix}.paternalLastName`)}
                control={control}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="maternalSurname"
                label={t(`${prefix}.maternalLastName`)}
                control={control}
                disabled
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="cv"
            label="Código del Programa"
            control={control}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={8}>
          <TextInput
            name="studyPlan"
            label="Nombre del Programa"
            control={control}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="campus"
            label={t(`${prefix}.campus`)}
            control={control}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="schedule"
            label={t(`${prefix}.schedule`)}
            control={control}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="entryYear"
            label={t(`${prefix}.entryYear`)}
            control={control}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="nationality"
            label={t(`${prefix}.nationality`)}
            control={control}
            options={nationalityOptions}
            isSearchable={true}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="birthday"
            label={t(`${prefix}.birthday`)}
            value={birthday ? formatDate(birthday, 'DD-MM-YYYY') : ''}
            disabled
          />
        </Col>
      </Row>
    </>
  );
};

export default StudentBackgroundNoSua;
