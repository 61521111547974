import {
  addToast,
  Button,
  DateInput,
  Modal,
  Select,
  SelectOptionType,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { PeriodAcademicCalendarEvent } from '../../../api/requests/periods';
import { CardTitle } from '../../../components/text';
import { useDateRange } from '../../../hooks/useDateRange';
import { useValidations } from '../../../hooks/useValidations';
import styles from './EventForm.module.scss';

export interface EventValues {
  event: (SelectOptionType & { interval: string }) | null;
  eventStartDate: string;
  eventEndDate: string;
}

export interface AcademicCalendar {
  startDate?: string | null;
  endDate?: string | null;
  eventCalendar: PeriodAcademicCalendarEvent;
}

function getDateInputsVisibility(eventInterval?: string) {
  if (!eventInterval) {
    return {
      showStartDate: false,
      showEndDate: false,
    };
  } else if (eventInterval === 'T') {
    return {
      showStartDate: false,
      showEndDate: true,
    };
  } else if (eventInterval === 'I') {
    return {
      showStartDate: true,
      showEndDate: false,
    };
  } else {
    return {
      showStartDate: true,
      showEndDate: true,
    };
  }
}

interface EventFormProps {
  periodName?: string;
  eventsList: PeriodAcademicCalendarEvent[];
  changeEventsTable: (
    value: { eventId: number; startDate: string; endDate: string },
    isEdit: boolean,
  ) => void;
  show: boolean;
  onClose: Function;
  selectedEventsIds: number[];
  recordToEdit: AcademicCalendar | null;
}

export const EventForm = ({
  periodName,
  eventsList,
  changeEventsTable,
  show,
  onClose,
  selectedEventsIds,
  recordToEdit,
}: EventFormProps) => {
  const { msgValidations } = useValidations();
  const { t } = useTranslation();
  const prefix = 'maintainers.periodsForm';

  const methods = useForm<EventValues>({
    mode: 'onSubmit',
    defaultValues: {
      event: null,
      eventStartDate: '',
      eventEndDate: '',
    },
  });

  const isEditionMode = !!recordToEdit;

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
    setValue,
    watch,
  } = methods;

  const [eventValue, eventStartDate, eventEndDate] = watch([
    'event',
    'eventStartDate',
    'eventEndDate',
  ]);

  useEffect(() => {
    if (show) {
      reset({
        event: recordToEdit
          ? {
              label: recordToEdit.eventCalendar.name,
              value: recordToEdit.eventCalendar.id,
              interval: recordToEdit.eventCalendar.interval,
            }
          : null,
        eventStartDate: recordToEdit?.startDate ?? '',
        eventEndDate: recordToEdit?.endDate ?? '',
      });
    }
  }, [show, recordToEdit, reset]);

  const onSubmit = async (formValues: EventValues) => {
    if (!formValues.event) {
      return;
    }
    changeEventsTable(
      {
        eventId: +formValues.event.value,
        startDate: formValues.eventStartDate,
        endDate: formValues.eventEndDate,
      },
      isEditionMode,
    );
    closeModal();
    addToast({
      icon: 'success',
      color: 'success',
      text: t(`${prefix}.createEventSuccess`),
    });
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  const { showStartDate, showEndDate } = getDateInputsVisibility(
    eventValue?.interval,
  );

  const showOnlyOneDate = showStartDate !== showEndDate;

  useEffect(() => {
    if (!showStartDate) {
      setValue('eventStartDate', '');
    }
  }, [showStartDate, setValue]);

  useEffect(() => {
    if (!showEndDate) {
      setValue('eventEndDate', '');
    }
  }, [showEndDate, setValue]);

  const setEndDate = useCallback(
    (e) => {
      if (!showOnlyOneDate) {
        setValue('eventEndDate', e);
      }
    },
    [setValue, showOnlyOneDate],
  );
  const { minDate, endDateDisabled } = useDateRange({
    startDate: eventStartDate,
    endDate: eventEndDate,
    setEndDate,
  });

  const eventsOptions = useMemo(() => {
    return eventsList.map(({ id, name, interval }) => ({
      label: name,
      value: id,
      isDisabled: selectedEventsIds.includes(id),
      interval,
    }));
  }, [eventsList, selectedEventsIds]);

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        closeModal();
      }}
      size="lg"
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <Row>
            <Col>
              <div className="text-center">
                <CardTitle
                  children={t(
                    `${prefix}.${isEditionMode ? 'editEvent' : 'newEvent'}`,
                  )}
                />
              </div>
              <Row className="mt-4 mt-md-5">
                <Col xs="12" md="5">
                  <div
                    className={`${styles.periodName} border-secondary d-flex flex-column text-secondary mb-4 mb-md-0`}
                  >
                    <span>{t(`${prefix}.periodName`)}</span>
                    <span>{periodName}</span>
                  </div>
                </Col>
                <Col xs="12" md="7">
                  <Select
                    disabled={!!recordToEdit}
                    name="event"
                    label={t(`${prefix}.event`)}
                    options={eventsOptions}
                    control={control}
                    rules={{ required: msgValidations.required }}
                  />
                </Col>
              </Row>
              <Row
                className={showStartDate || showEndDate ? 'mt-0 mt-md-2' : ''}
              >
                {showStartDate && (
                  <Col xs={12} md>
                    <DateInput
                      name="eventStartDate"
                      label={t(
                        showOnlyOneDate
                          ? 'common.forms.date'
                          : `common.forms.startDate`,
                      )}
                      control={control}
                      rules={{ required: msgValidations.required }}
                    />
                  </Col>
                )}
                {showEndDate && (
                  <Col xs={12} md>
                    <DateInput
                      name="eventEndDate"
                      label={t(
                        showOnlyOneDate
                          ? 'common.forms.date'
                          : 'common.forms.endDate',
                      )}
                      control={control}
                      rules={{ required: msgValidations.required }}
                      minDate={showOnlyOneDate ? undefined : minDate}
                      disabled={showOnlyOneDate ? false : endDateDisabled}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4">
              <Row>
                <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
                  <Button
                    type="button"
                    text={t('common.actions.cancel')}
                    outlined
                    onClick={closeModal}
                    fullwidth
                  />
                </Col>
                <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
                  <Button
                    type="submit"
                    text={t(
                      isEditionMode
                        ? 'common.actions.saveChanges'
                        : `${prefix}.add`,
                    )}
                    loading={isSubmitting}
                    fullwidth
                    className="mb-3 mb-md-0"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};
