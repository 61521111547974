import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import GoBackToListButton from '../../components/buttons/GoBackToListButton';
import {
  CoursesBySemesters,
  Legends,
  Resume,
} from '../../components/curriculum';
import { LegendsProps } from '../../components/curriculum/Legends';
import { ResumeProps } from '../../components/curriculum/Resume';
import { SemesterCourse } from '../../components/curriculum/types';
import { SectionTitle } from '../../components/text';
import { PathsLayouts } from '../../config/routes';
import { isNumber } from '../../utils/math';
import BaseLoader from './parts/BaseLoader';
import CurriculumLoader from './parts/CurriculumLoader';
import Modal from './parts/Modal';
import ModalNoCourses from './parts/ModalNoCourses';
import StudyPlanBox from './parts/StudyPlanBox';

export default function CourseValidationInternalStudent() {
  const prefix = 'courseValidationInternalStudent';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      body: t(`${prefix}.body`),
      resumeTitle: t(`${prefix}.resume.title`),
      curriculumTitle: t(`${prefix}.curriculum.title`),
      semester: t(`${prefix}.curriculum.semester`),
      credits: t(`${prefix}.curriculum.credits`),
      box: {
        student: t(`${prefix}.box.student`),
        rut: t(`${prefix}.box.rut`),
        studyPlan: t(`${prefix}.box.studyPlan`),
      },
      resume: {
        ...t<string, ResumeProps['texts']>(`${prefix}.resume`, {
          returnObjects: true,
        }),
        durationValue: (duration: number) =>
          t(`${prefix}.resume.durationValue`, { count: duration }),
      },
      curriculumStatus: t<string, LegendsProps['texts']>(
        `${prefix}.curriculum.status`,
        {
          returnObjects: true,
        },
      ),
    }),
    [t],
  );

  const { studyPlanEnrollmentId: studyPlanEnrollmentIdStr } =
    useParams<{ studyPlanEnrollmentId: string }>();

  const studyPlanEnrollmentId = useMemo(() => {
    if (isNumber(studyPlanEnrollmentIdStr)) {
      return Number(studyPlanEnrollmentIdStr);
    }
    return null;
  }, [studyPlanEnrollmentIdStr]);

  const [semesterCourseToValidate, setSemesterCourseToValidate] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const onClick = useCallback((semesterCourse: SemesterCourse) => {
    if (semesterCourse.state) {
      return;
    }
    if (semesterCourse.course) {
      setSemesterCourseToValidate({
        id: semesterCourse.id,
        name: semesterCourse.course.name,
      });
    } else {
      setSemesterCourseToValidate({
        id: semesterCourse.id,
        name: semesterCourse.courseType.name,
      });
    }
  }, []);

  if (!studyPlanEnrollmentId) {
    return <Redirect to={PathsLayouts.courseValidationInternal} />;
  }

  return (
    <Card className="p-4 mt-2 mx-3">
      <BaseLoader studyPlanEnrollmentId={studyPlanEnrollmentId}>
        {(data) => (
          <>
            <Row>
              <Col xs={12} sm={3} className="pb-2">
                <GoBackToListButton
                  path={PathsLayouts.courseValidationInternal}
                />
              </Col>
              <Col xs={12} sm={9} className="pb-2">
                <StudyPlanBox
                  studentName={`${data.student.lastnames}, ${data.student.name}`}
                  studentRut={data.student.rut}
                  studyPlanName={data.studyPlanVersion?.name}
                  texts={texts.box}
                />
              </Col>
            </Row>
            <p className="text-medium fs-16 mt-3 mb-5">{texts.body}</p>
            <CurriculumLoader studyPlanEnrollmentId={studyPlanEnrollmentId}>
              {({ data: curriculumData, refresh }) => (
                <>
                  {/* DATOS DE LA MALLA */}
                  <div className="mb-4">
                    <div className="mb-3">
                      <SectionTitle text={texts.resumeTitle} />
                    </div>
                    <Resume texts={texts.resume} {...curriculumData} />
                  </div>

                  {/* MALLA DE ESTUDIOS */}
                  <div>
                    <div className="mb-3">
                      <SectionTitle text={texts.curriculumTitle} />
                    </div>
                    <div className="mb-3">
                      <CoursesBySemesters
                        semesters={curriculumData.semesters}
                        texts={texts}
                        onClick={onClick}
                      />
                      {semesterCourseToValidate && data.courses.length > 0 && (
                        <Modal
                          studyPlanEnrollmentId={studyPlanEnrollmentId}
                          isOpen
                          semesterCourseToValidate={semesterCourseToValidate}
                          courseEnrollments={data.courses}
                          onClose={() => setSemesterCourseToValidate(null)}
                          onSuccess={() => {
                            setSemesterCourseToValidate(null);
                            refresh();
                          }}
                        />
                      )}
                      {semesterCourseToValidate && data.courses.length <= 0 && (
                        <ModalNoCourses
                          isOpen
                          onClose={() => setSemesterCourseToValidate(null)}
                        />
                      )}
                    </div>
                    <Legends texts={texts.curriculumStatus} />
                  </div>
                </>
              )}
            </CurriculumLoader>
          </>
        )}
      </BaseLoader>
    </Card>
  );
}
