import React from 'react';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useLayoutState } from '../../hooks/useLayoutState';
import TuitionProcessInfoProvider from '../../providers/TuitionProcessInfoProvider';
import TuitionProcess from '../TuitionProcess/TuitionProcess';
import TuitionProcessProvider, {
  TuitionProcessContextState,
} from '../TuitionProcess/TuitionProcessContext';
import TuitionProcessNoSua from '../TuitionProcessNoSua';

/**
 * La función `TuitionProcessOnSite` es un componente de React que representa un proceso de matrícula
 * basado en el tipo de admisión y el ID de detalle de postulación.
 * @returns un elemento JSX.
 */
function TuitionProcessOnSite() {
  const history = useHistory();
  const { showErrorModal } = useLayoutState();
  const { postulationDetailId, admissionType } = useParams<{
    postulationDetailId: string;
    admissionType: string;
  }>();

  const onError = useCallback<TuitionProcessContextState['onError']>(
    (code, onUnknown) => {
      if (code === 'CONNECTION') {
        showErrorModal();
      } else {
        onUnknown && onUnknown();
      }
    },
    [showErrorModal],
  );

  const onCancelFirstStep = useCallback(() => {
    history.replace('/tuition-process');
  }, [history]);

  function renderTuitionProcess() {
    if (admissionType === 'SIN_SUA') {
      return <TuitionProcessNoSua />;
    } else {
      return <TuitionProcess />;
    }
  }

  return (
    <TuitionProcessProvider
      prefix="tuitionProcess.onSite"
      postulationDetailId={postulationDetailId}
      onError={onError}
      onCancelFirstStep={onCancelFirstStep}
    >
      <TuitionProcessInfoProvider>
        <div className="mx-3">{renderTuitionProcess()}</div>
      </TuitionProcessInfoProvider>
    </TuitionProcessProvider>
  );
}

export default TuitionProcessOnSite;
