import { ColumnTable, Table } from '@octano/global-ui';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import dayjs from 'dayjs';
import { SectionTitle } from '../../../../../components/text';
import { QuotaSimulationItem } from '../../../../../types/tariffTypes';
import { moneyFormatter } from '../../../../../utils/currency';

interface ITariffTableProps {
  quotas: QuotaSimulationItem[];
  tariffDownPayment?: number;
  showInterestsDetails?: boolean;
}

export default function TariffTable({
  quotas,
  tariffDownPayment = 0,
  showInterestsDetails = false,
}: ITariffTableProps) {
  const prefix = 'tuitionProcessNoSua.tariffQuotas';
  const { t } = useTranslation();

  const formatDate = (date: string) => dayjs(date).format('DD/MM/YYYY');

  const printClassName = (index: number, data: any[], className?: string) => {
    return clsx(
      className,
      index === data.length - 1 &&
        'h-100 d-flex justify-content-center align-items-center',
    );
  };

  const columns = useMemo<ColumnTable<QuotaSimulationItem>[]>(() => {
    const cols: ColumnTable<QuotaSimulationItem>[] = [
      {
        columnName: 'quota',
        headerText: t(`${prefix}.table.quota`),
        width: '20%',
        tdClassName: 'p-0',
        cellFormat({ row, data, index }) {
          if (index === data!.length - 1)
            return (
              <div className={printClassName(index, data!, 'bg-tertiary')}>
                <p className="text-primary m-auto">
                  <strong>Total</strong>
                </p>
              </div>
            );
          return row.description;
        },
      },
      {
        columnName: 'expirationDate',
        headerText: t(`${prefix}.table.expirationDate`),
        width: '20%',
        tdClassName: 'p-0',
        cellFormat({ row, data, index }) {
          if (index === data!.length - 1)
            return (
              <div className={printClassName(index, data!, 'bg-tertiary')} />
            );
          return `${formatDate(row.startDate)} ${
            row.endDate !== row.startDate ? 'al ' + formatDate(row.endDate) : ''
          }`;
        },
      },
      {
        columnName: 'amount',
        headerText: t(`${prefix}.table.amount`),
        width: '20%',
        tdClassName: 'p-0',
        cellFormat({ row, data, index }) {
          if (!showInterestsDetails && index === data!.length - 1) {
            return (
              <div className={printClassName(index, data!, 'bg-tertiary')}>
                <p className="text-primary m-auto">
                  <strong>
                    {moneyFormatter().format(Math.ceil(row?.amount))}
                  </strong>
                </p>
              </div>
            );
          } else if (index === data!.length - 1) {
            return (
              <div className={printClassName(index, data!, 'bg-tertiary')} />
            );
          }

          return moneyFormatter().format(
            Math.ceil(
              row?.amount - (showInterestsDetails ? row?.interests : 0),
            ),
          );
        },
      },
    ];
    if (showInterestsDetails) {
      cols?.push({
        columnName: 'amountWithInterests',
        headerText: t(`${prefix}.table.amountWithInterests`),
        width: '20%',
        tdClassName: 'p-0',
        cellFormat({ row, data, index }) {
          if (index === data!.length - 1)
            return (
              <div className={printClassName(index, data!, 'bg-tertiary')}>
                <p className="text-primary m-auto">
                  <strong>
                    {moneyFormatter().format(Math.ceil(row?.amount))}
                  </strong>
                </p>
              </div>
            );
          return moneyFormatter().format(Math.ceil(row?.amount));
        },
      });
    }
    return cols;
  }, [showInterestsDetails, t]);

  const items = useMemo(() => {
    let _quotas: QuotaSimulationItem[] = [...quotas];
    if (tariffDownPayment > 0) {
      _quotas = [
        {
          description: t(`${prefix}.table.tariffDownPayment`),
          index: -1,
          amount: tariffDownPayment,
          interests: 0,
          startDate: dayjs().toISOString(),
          endDate: dayjs().toISOString(),
        },
      ].concat(_quotas);
    }
    if (_quotas?.length) {
      _quotas = _quotas?.concat({
        ..._quotas[_quotas?.length - 1],
        amount: _quotas
          ?.map((e) => Math.ceil(e?.amount ?? 0))
          ?.reduce((a, b) => a + b, 0),
      });
    }
    return _quotas;
  }, [quotas, t, tariffDownPayment]);

  return (
    <Row>
      <Col xs={12} className="pb-3">
        <SectionTitle text={t(`${prefix}.table.title`)} />
      </Col>
      <Col xs={12}>
        <div className="w-75 mx-auto px-4 ">
          <div className="text-center">
            <Table columns={columns} data={items} />
          </div>
        </div>
      </Col>
    </Row>
  );
}
