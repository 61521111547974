import { Button, CheckInput } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { SectionTitle } from '../../../components/text';
import { CurriculumDegreeActivitiesList } from '../CurriculumDegreeActivities';
import CurriculumForm from '../CurriculumForm';
import { CurriculumLegend } from '../CurriculumLegend';
import CurriculumMentionList from '../CurriculumMentionList';
import CurriculumStudyPlanInfo from '../CurriculumStudyPlanInfo';

type PropsParams = {
  id: string;
  versionId: string;
};

const CurriculumCreate = () => {
  const prefix = 'studyPlans.curriculum';
  const readOnly: boolean = false;

  const history = useHistory();
  const { t } = useTranslation();
  const { id: studyPlanId, versionId } = useParams<PropsParams>();

  const [studyPlanName, setStudyPlanName] = useState<string>('');
  const [disabledEditButton, setDisabledEditButton] = useState<boolean>(false);
  const [hasMentions, setHasMentions] = useState(false);

  const goBack = useCallback(() => {
    history.push(`/study-plans/record/${studyPlanId}`);
  }, [history, studyPlanId]);

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <Row>
          <Col xs={12} md={7}>
            <div className="mb-4">
              <GoBackButton onClick={goBack} />
            </div>
          </Col>
          <Col xs={12} md={5}>
            <CurriculumStudyPlanInfo
              id={studyPlanId}
              versionId={versionId}
              setStudyPlanName={setStudyPlanName}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <SectionTitle text={t(`${prefix}.mentions`)} />
            <Row className="p-3">
              <span className="fs-20">{t(`${prefix}.hasMentions`)}</span>
            </Row>
            <Row className="p-3">
              <CheckInput
                label={t(`${prefix}.hasMentionsYes`)}
                name="hasMentions"
                value={hasMentions}
                onChange={() => setHasMentions(!hasMentions)}
              />
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            {!hasMentions ? (
              <CurriculumForm
                studyPlanName={studyPlanName}
                curriculumData={undefined}
                readOnly={readOnly}
                setDisabledEditButton={setDisabledEditButton}
                isMention={false}
              >
                {({ isSubmitting, legend }) => (
                  <>
                    <Row>
                      <Col xs={{ size: 12 }}>
                        <CurriculumLegend legend={legend} />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={{ size: 12 }}>
                        <CurriculumDegreeActivitiesList readOnly={readOnly} />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xs={{ size: 12 }}
                        className="d-flex justify-content-end"
                      >
                        <Col
                          xs={{ size: 12 }}
                          md={{ size: 5 }}
                          lg={{ size: 4 }}
                        >
                          <div className="pb-3 pb-md-0 mt-5">
                            <Button
                              type="submit"
                              text={t(`${prefix}.publish`)}
                              loading={isSubmitting}
                              disabled={disabledEditButton}
                              fullwidth
                            />
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  </>
                )}
              </CurriculumForm>
            ) : (
              <CurriculumMentionList />
            )}
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default CurriculumCreate;
