import { Alert, Button, addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTuitionProcessNoSua } from '../../../../hooks/useTuitionProcessNoSua';
import { finishPaymentStep } from '../../services/requests';
import PaymentTable from '../PaymentTable';
import { useStepState } from '../../../../../../components/step/useStepState';
// import GatewayRedirect from './GatewayRedirect';

// export interface PaymentProps extends GatewayPaymentResponse {}

export default function Payment({ ...props }: any) {
  const prefix = `tuitionProcessNoSua.payment`;

  const [finishing, setFinishing] = useState<boolean>(false);

  //Custom Hooks
  const { t } = useTranslation();
  const { postulationDetail } = useTuitionProcessNoSua();
  const { nextStep } = useStepState();

  const handleSubmit = useCallback(async () => {
    try {
      if (!postulationDetail) {
        return;
      }

      if (finishing) {
        return;
      }
      setFinishing(true);
      const { error, data } = await finishPaymentStep(postulationDetail.id);
      if (error) {
        throw error;
      }
      if (data?.notGenerateSalenote) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.notGenerateSalenote`),
        });
      }
      nextStep();

      setFinishing(false);
    } catch (error: any) {
      console.log('error', error);
      setFinishing(false);
      if (error && error.code === 'HTTP_ERROR' && error.status === 401) {
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.errorOnFinish`),
        });
      }
    }
  }, [finishing, nextStep, postulationDetail, prefix, t]);

  return (
    <>
      {!!props?.message?.trim() && (
        <Alert
          isOpen={true}
          text={props?.message?.trim()}
          className="mx-auto"
          style={{ maxWidth: 370 }}
          icon="information"
          fade
        />
      )}

      <span className="fs-22 fw-600 text-primary text-uppercase">
        {t(`${prefix}.table.title`)}
      </span>

      {Boolean(props?.details?.items?.length) && (
        <PaymentTable data={props?.details?.items ?? []} loading={false} />
      )}

      <Button
        type="button"
        text={t(`${prefix}.submit`)}
        className="mx-auto mt-2"
        loading={finishing}
        fullwidth
        onClick={handleSubmit}
      />
    </>
  );
}
