import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  addToast,
  Button,
  Modal,
  Select,
  SelectOptionType,
  Table,
  useMobile,
} from '@octano/global-ui';
import { useValidations } from '../../../hooks/useValidations';
import { useColumnsSectionQuotas } from './useColumnsSectionQuotas';
import {
  AvailableSectionEnrollment,
  SectionCourseRegistration,
} from '../../../types/courseRegistrationTypes';
import {
  requestAvailableSectionsEnrollment,
  requestCourseSectionChange,
} from '../../../api/requests/courseRegistration';
import SectionInfoBox from './SectionInfoBox';
import ModalExceedQuota from './ModalExceedQuota';
import { isOvercrowded } from '../../../utils/courseSection';

interface SectionOptions extends AvailableSectionEnrollment {
  value: number | string;
  label: string;
}

type FormValues = {
  section: SelectOptionType | null;
};

interface Props {
  isOpen: boolean;
  currentCourseRegistration: SectionCourseRegistration;
  accountId: number;
  onSave?: () => void;
  onCancel?: () => void;
}

export default function ModalSectionChange({
  isOpen,
  onSave = () => null,
  onCancel = () => null,
  currentCourseRegistration,
  accountId,
}: Props) {
  const { t } = useTranslation();
  const prefix = `courseRegistrationList.detail.modalSectionChange`;
  const isMobile = useMobile();

  const { handleSubmit, watch, control, reset } = useForm<FormValues>();
  const { msgValidations } = useValidations();
  const selectedSection = watch('section');

  const [tableData, setTableData] = useState<AvailableSectionEnrollment[]>([]);
  const [sectionOptions, setSectionOptions] = useState<SectionOptions[]>([]);
  const tableColumns = useColumnsSectionQuotas({
    tableData: tableData.length ? tableData[0] : undefined,
  });

  const [wouldExceedQuota, setWouldExceedQuota] = useState(false);

  const submitSectionChange = async ({ section }: FormValues) => {
    const { error } = await requestCourseSectionChange({
      accountId,
      newSectionId: section?.value ? Number(section.value) : 0,
      oldSectionId: currentCourseRegistration.id,
      ignoreWarning: wouldExceedQuota,
    });

    if (error) {
      if (error.data.message.includes('MAX_QUOTA')) {
        return setWouldExceedQuota(true);
      }

      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.errors.save`),
      });
    } else {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.successfullyChanged`),
      });

      setWouldExceedQuota(false);
      onSave();
      reset();
    }
  };

  useEffect(() => {
    const fetchSections = async (courseId: number) => {
      const { data, error } = await requestAvailableSectionsEnrollment(
        courseId,
      );

      if (error) {
        return;
      }

      const mappedOptions = data?.data.map(
        (section: AvailableSectionEnrollment) => ({
          value: section.id,
          label: section.name,
          ...section,
        }),
      );

      const filteredOptions = mappedOptions?.filter(
        (op) => op.value !== currentCourseRegistration.id,
      );

      setSectionOptions(filteredOptions || []);
    };

    if (currentCourseRegistration.course.id)
      fetchSections(currentCourseRegistration.course.id);
  }, [currentCourseRegistration.course.id, currentCourseRegistration.id]);

  useEffect(() => {
    if (selectedSection) {
      const data = [
        {
          id: selectedSection.id,
          name: selectedSection.name,
          maximumStudent: selectedSection.maximumStudent,
          slots: selectedSection.slots,
          campus: selectedSection.campus,
          professors: selectedSection.professors,
          sectionSchedules: selectedSection.sectionSchedules,
        },
      ];

      setTableData(data);
    }
  }, [selectedSection]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={() => null} size="lg">
        <h1 className="fs-22 text-dark text-center mb-5">
          {t(`${prefix}.title`)}
        </h1>

        <form onSubmit={handleSubmit(submitSectionChange)}>
          <SectionInfoBox
            courseName={currentCourseRegistration.course.name}
            shortening={currentCourseRegistration.course.shortening}
            sectionName={currentCourseRegistration.name}
          />
          <div className="mt-4 mb-4">
            <Select
              name="section"
              options={sectionOptions}
              label={t(`${prefix}.sectionLabel`)}
              control={control}
              rules={{
                required: msgValidations.required,
              }}
            />
          </div>
          {selectedSection ? (
            <>
              <Table columns={tableColumns} data={tableData} />
              {tableData.length > 0 && isOvercrowded(tableData[0].slots) && (
                <p className="fs-12 text-danger">
                  {t(`${prefix}.alreadyOvercrowdedMsg`)}
                </p>
              )}
            </>
          ) : null}
          <div
            className={`d-flex justify-content-between mt-3 ${
              isMobile ? 'flex-wrap' : ''
            }`}
            style={{ gap: isMobile ? 16 : '2.5rem' }}
          >
            <Button
              text={t(`common.actions.cancel`)}
              outlined
              onClick={() => {
                reset();
                onCancel();
              }}
              className={isMobile ? 'w-100' : 'flex-grow-1'}
            />
            <Button
              text={t(`common.actions.select`)}
              type="submit"
              className={isMobile ? 'w-100' : 'flex-grow-1'}
            />
          </div>
        </form>
      </Modal>

      <ModalExceedQuota
        isOpen={wouldExceedQuota}
        toggle={() => null}
        onConfirm={() => submitSectionChange({ section: selectedSection })}
        onCancel={() => setWouldExceedQuota(false)}
      />
    </>
  );
}
