import { ColumnTable, DisplayInfo, Icon, Table } from '@octano/global-ui';
import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import { useTranslation } from 'react-i18next';
import { format } from 'rut.js';

import { TextEllipsis } from '../../../components/text';
import { StudentsDegreeRow } from '../../../types/studentsDegree';
import StudentDegreeStatusBadge from '../../../components/badgeStatus/StudentDegreeStatusBadge';
import { Link } from 'react-router-dom';
import { PathsLayouts } from '../../../config/routes';
import { STUDENT_STATUS } from '../../../types/studentStatusTypes';

const dictPrefix = 'studentsDegree.table';

interface Props {
  data: StudentsDegreeRow[];
  isLoadingResults: boolean;
  totalPetitions: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  hasFilters?: boolean;
}

export default function PetitionsTable({
  data,
  isLoadingResults,
  totalPages,
  totalPetitions,
  currentPage,
  onChangePage,
  hasFilters,
}: Props) {
  const ITEMS_PER_PAGE = 10;
  const { t } = useTranslation();

  const columns: ColumnTable<StudentsDegreeRow>[] = [
    {
      headerText: t(`${dictPrefix}.columns.studentName`),
      columnName: 'studentName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '25%',
      cellFormat: ({ row }) => {
        return (
          <p>
            {row?.studentLastnames}, {row?.studentName}
            <br />
            {format(row?.studentRut)}
          </p>
        );
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.faculty`),
      columnName: 'schoolName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
      cellFormat: (options) => options.value,
    },
    {
      headerText: t(`${dictPrefix}.columns.graduationPeriod`),
      columnName: 'periodEntryName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.columns.studyPlanVersionName`),
      columnName: 'studyPlanVersionName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '20%',
      cellFormat: (options: CellFormatOptions) => (
        <TextEllipsis
          key={options.index}
          textWidth={350}
          tooltip={options.value}
        >
          {options.value}
        </TextEllipsis>
      ),
    },
    {
      headerText: t(`${dictPrefix}.columns.status`),
      columnName: 'studyPlanEnrollmentStatus',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '15%',
      cellFormat: ({ row, value }) => {
        return (
          <StudentDegreeStatusBadge
            status={value}
            name={row.studentId}
            showToolTips={false}
          />
        );
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.action`),
      columnName: 'studyPlanEnrollmentId',
      width: '40px',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row, value }) => {
        return row.studyPlanEnrollmentStatus === STUDENT_STATUS.GRADUATE ? (
          <Link to={`${PathsLayouts.studentsDegree}/${value}`}>
            <Icon className="custom-edit" name="edit" key="edit" size="22px" />
          </Link>
        ) : (
          <Link to={`${PathsLayouts.studentsDegree}/${value}`}>
            <Icon className="custom-eye" name="eye" key="eye" size="22px" />
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={{
          currentPage: currentPage + 1,
          itemsPerPage: ITEMS_PER_PAGE,
          totalItems: totalPetitions,
          totalPages: totalPages,
          onChangePage: (page) => onChangePage(page - 1),
        }}
        noResultsText={
          <div className="d-flex justify-content-center align-items-center py-5">
            <DisplayInfo
              iconName="information"
              title={t(
                `${dictPrefix}.${
                  hasFilters ? 'noResultsFilter' : 'noResults'
                }.description`,
              )}
              textBody=""
              maxWidth="650px"
            />
          </div>
        }
      />
    </>
  );
}
