import {
  Button,
  CellFormatOptions,
  ColumnTable,
  PaginationType,
  Table,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format as formatRut } from 'rut.js';
import {
  AcademicOfferStudyPlansOfferDocumentationByStatus,
  DocumentationReviewStatus,
} from '../../../api/requests/studyPlansOffer';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { usePostulationDocumentsLoader } from '../contexts/PostulationDocumentsLoader';
import DocumentationReviewStatusBadge from './DocumentationReviewStatusBadge';
import { SearchFormPostulantProccessByStatus } from './SearchFormPostulantProccessByStatus';

const ITEMS_PER_PAGE_DEFAULT = 10;

export function TablePostulantsProcessByStatus() {
  const history = useHistory();
  const { t } = useTranslation();

  const { error, loading, data, query, updateQuery } =
    usePostulationDocumentsLoader();

  const paginationTable = useMemo<PaginationType | undefined>(() => {
    if (data) {
      return {
        totalItems: data.total,
        onChangePage: (page) => updateQuery({ ...query, page }),
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalPages: data.total_pages,
        currentPage: query.page,
      };
    } else return undefined;
  }, [data, query, updateQuery]);

  const goDocumentOfPostulantProccess = useCallback(
    (detailId: number) => {
      history.push({
        search: `?id=${detailId}`,
      });
    },
    [history],
  );

  const documentationReviewStatus = useMemo(() => {
    return [
      {
        value: DocumentationReviewStatus.NOT_REVIEWED,
        label: t('documents.notReview'),
      },
      {
        value: DocumentationReviewStatus.WAITING,
        label: t('documents.inWaiting'),
      },
      {
        value: DocumentationReviewStatus.APPROVED,
        label: t('documents.approved'),
      },
    ];
  }, [t]);

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'rut',
        headerText: t(`common.forms.rut`),
        width: '10%',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: (
          options: CellFormatOptions<AcademicOfferStudyPlansOfferDocumentationByStatus>,
        ) => {
          return (
            (options.row.Postulant_rut &&
              formatRut(options.row.Postulant_rut)) ||
            options.row.PostulantPassport_number
          );
        },
      },
      {
        columnName: 'Postulant_names',
        headerText: t(`common.forms.names`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
      },
      {
        columnName: 'lastnames',
        headerText: t(`common.forms.lastName`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        hideOnMobile: true,
        cellFormat: (
          options: CellFormatOptions<AcademicOfferStudyPlansOfferDocumentationByStatus>,
        ) => {
          return `${options.row.Postulant_paternalLastName} ${options.row.Postulant_maternalLastName}`;
        },
      },
      {
        columnName: 'Contact_cellPhone',
        headerText: t(`common.forms.phone`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        hideOnMobile: true,
      },
      {
        columnName: 'Contact_email',
        headerText: t(`common.forms.email`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        hideOnMobile: true,
      },
      {
        columnName: 'Status',
        headerText: t(`common.forms.status`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        hideOnMobile: false,
        cellFormat: ({ value }) => {
          const statusName = documentationReviewStatus.find(
            (d) => d.value === value,
          );
          if (!statusName) {
            return null;
          }
          return (
            <DocumentationReviewStatusBadge
              status={value}
              name={statusName.label}
            />
          );
        },
      },
      {
        columnName: 'action',
        headerText: t('documents.action'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '10%',
        cellFormat: (
          options: CellFormatOptions<AcademicOfferStudyPlansOfferDocumentationByStatus>,
        ) => {
          return (
            <Button
              text={t('documents.viewDocuments')}
              size="sm"
              fullwidth
              color="primary"
              onClick={() =>
                goDocumentOfPostulantProccess(options.row.Details_id)
              }
            />
          );
        },
      },
    ];
  }, [t, documentationReviewStatus, goDocumentOfPostulantProccess]);

  const noResultsText = useMemo(() => {
    let title, subtitle;
    if (error) {
      title = t('documents.tableEmptyContent.connection.title');
      subtitle = t('documents.tableEmptyContent.connection.subtitle');
    } else {
      title = t('documents.tableEmptyContent.notResult.title');
      subtitle = t('documents.tableEmptyContent.notResult.subtitle');
    }
    return <TableEmptyContent title={title} subtitle={subtitle} />;
  }, [t, error]);

  return (
    <>
      <SearchFormPostulantProccessByStatus
        onSearch={(params) => {
          updateQuery({
            page: 1,
            status: params?.status ?? null,
            searchText: params?.searchText,
          });
        }}
      />
      <Table
        pagination={paginationTable}
        columns={columns}
        data={data?.data ?? []}
        isLoadingResults={loading}
        noResultsText={noResultsText}
      />
    </>
  );
}
