import { Button, addToast } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { getCurriculumRequest } from '../../../api/requests/curriculum';
import GoBackButton from '../../../components/buttons/GoBackButton';
import { CurriculumDegreeActivitiesList } from '../CurriculumDegreeActivities';
import CurriculumForm from '../CurriculumForm';
import { CurriculumLegend } from '../CurriculumLegend';
import CurriculumStudyPlanInfo from '../CurriculumStudyPlanInfo';

type PropsParams = {
  id: string;
  versionId: string;
  curriculumId: string;
};

const CurriculumEdit = () => {
  const prefix = 'studyPlans.curriculum';
  const readOnly: boolean = false;

  const history = useHistory();
  const { t } = useTranslation();
  const { id: studyPlanId, versionId, curriculumId } = useParams<PropsParams>();

  const [studyPlanName, setStudyPlanName] = useState<string>('');
  const [curriculumData, setCurriculumData] = useState<any>(undefined);
  const [disabledEditButton, setDisabledEditButton] = useState<boolean>(false);

  const goBack = useCallback(() => {
    history.push(`/study-plans/record/${studyPlanId}`);
  }, [history, studyPlanId]);

  const getCurriculumData = useCallback(
    async (id: string) => {
      const { data, error } = await getCurriculumRequest(id);
      if (data) {
        setCurriculumData(data.data);
      }
      if (error) {
        addToast({
          text: t(`${prefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      }
    },
    [t],
  );

  useEffect(() => {
    if (curriculumId) {
      getCurriculumData(curriculumId);
      setDisabledEditButton(true);
    }
  }, [curriculumId, getCurriculumData]);

  return (
    <Container>
      <Card className="p-3 p-md-4">
        <Row>
          <Col xs={12} md={7}>
            <div className="mb-4">
              <GoBackButton onClick={goBack} />
            </div>
          </Col>
          <Col xs={12} md={5}>
            <CurriculumStudyPlanInfo
              id={studyPlanId}
              versionId={versionId}
              setStudyPlanName={setStudyPlanName}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <CurriculumForm
              studyPlanName={studyPlanName}
              curriculumData={curriculumData}
              readOnly={readOnly}
              setDisabledEditButton={setDisabledEditButton}
              isMention={false}
            >
              {({ isSubmitting, legend }) => (
                <>
                  <Row>
                    <Col xs={{ size: 12 }}>
                      <CurriculumLegend legend={legend} />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={{ size: 12 }}>
                      {
                        <CurriculumDegreeActivitiesList
                          readOnly={readOnly}
                          curriculumId={curriculumId}
                        />
                      }
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs={{ size: 12 }}
                      className="d-flex justify-content-end"
                    >
                      <Col xs={{ size: 12 }} md={{ size: 5 }} lg={{ size: 4 }}>
                        <div className="pb-3 pb-md-0 mt-5">
                          <Button
                            type="submit"
                            text={t(`${prefix}.publish`)}
                            loading={isSubmitting}
                            disabled={disabledEditButton}
                            fullwidth
                          />
                        </div>
                      </Col>
                    </Col>
                  </Row>
                </>
              )}
            </CurriculumForm>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default CurriculumEdit;
