import { Select, TextInput } from '@octano/global-ui';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
  useFilteredCities,
  useFilteredCommunes,
} from '../../hooks/useFilteredCommunes';
import { useParameters } from '../../hooks/useParameters';
import { useValidations } from '../../hooks/useValidations';
import { SectionTitle } from '../text';

type ContactSectionProps = {
  prefix: string;
  optionalFields?: string[];
  fieldNames?: {
    addressStreet: string;
    addressNumber: string;
    addressExtra: string;
    region: string;
    commune: string;
    city: string;
    phone: string;
    cellphone: string;
    email: string;
  };
  disabled?: boolean;
};

/**
 * Para usar este componente tu formulario debe estar envuelto en su FormProvider
 * para poder acceder a la información del formulario a través de useFormContext
 * Los nombres agregados en fieldNames se relacionan a los campos que utilizaste
 * al definir tu formulario.
 */
const ContactSection = ({
  prefix,
  optionalFields = [],
  fieldNames = {
    addressStreet: 'addressStreet',
    addressNumber: 'addressNumber',
    addressExtra: 'addressExtra',
    region: 'region',
    city: 'city',
    commune: 'commune',
    phone: 'phone',
    cellphone: 'cellphone',
    email: 'email',
  },
  disabled = false,
}: ContactSectionProps) => {
  const { control, watch, formState, setValue } = useFormContext();
  const { t } = useTranslation();
  const { validateTextNotEmpty, validatePhone } = useValidations();
  const { regionOptions } = useParameters();

  const selectedRegion = watch(fieldNames.region);
  const filteredCommunes = useFilteredCommunes(selectedRegion);
  const filteredCities = useFilteredCities(selectedRegion);

  const isRegionTouched = formState.touchedFields[fieldNames.region];

  const optional = (value: string, fieldName: string) => {
    if (optionalFields.includes(fieldName)) {
      return undefined;
    }
    return validateTextNotEmpty(value);
  };

  useEffect(() => {
    if (isRegionTouched) {
      setValue(fieldNames.commune, null);
      setValue(fieldNames.city, null);
    }
  }, [
    selectedRegion,
    setValue,
    isRegionTouched,
    fieldNames.commune,
    fieldNames.city,
  ]);

  return (
    <>
      <Row className="pt-5">
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={8}>
          <TextInput
            name={fieldNames.addressStreet}
            label={t(`${prefix}.${fieldNames.addressStreet}`)}
            control={control}
            rules={{
              validate: {
                require: (value) => optional(value, fieldNames.addressStreet),
              },
            }}
            disabled={disabled}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.addressNumber}
            label={t(`${prefix}.${fieldNames.addressNumber}`)}
            control={control}
            rules={{
              validate: {
                require: (value) => optional(value, fieldNames.addressNumber),
              },
            }}
            disabled={disabled}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.addressExtra}
            label={t(`${prefix}.${fieldNames.addressExtra}`)}
            control={control}
            disabled={disabled}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={fieldNames.region}
            label={t(`${prefix}.${fieldNames.region}`)}
            options={regionOptions}
            control={control}
            rules={{
              validate: {
                require: (value) => optional(value, fieldNames.region),
              },
            }}
            disabled={disabled}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={fieldNames.city}
            label={t(`${prefix}.${fieldNames.city}`)}
            options={filteredCities}
            control={control}
            rules={{
              validate: {
                require: (value) => optional(value, fieldNames.city),
              },
            }}
            disabled={disabled}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={fieldNames.commune}
            label={t(`${prefix}.${fieldNames.commune}`)}
            options={filteredCommunes}
            control={control}
            rules={{
              validate: {
                require: (value) => optional(value, fieldNames.commune),
              },
            }}
            disabled={disabled}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.phone}
            label={t(`${prefix}.${fieldNames.phone}`)}
            control={control}
            formatter="phone"
            rules={{ validate: validatePhone }}
            disabled={disabled}
            placeholder={t(`common.placeholder.phone`)}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.cellphone}
            label={t(`${prefix}.${fieldNames.cellphone}`)}
            control={control}
            formatter="phone"
            rules={{
              validate: {
                phone: validatePhone,
                require: (value) => optional(value, fieldNames.cellphone),
              },
            }}
            disabled={disabled}
            placeholder={t(`common.placeholder.cellphone`)}
          />
        </Col>
      </Row>
    </>
  );
};

export default ContactSection;
