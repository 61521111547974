import { addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CreatePostulantBody,
  createPostulant,
  putPostulants,
} from '../../../api/requests/postulants';
import { Student, StudentForm } from '../../../types/tuitionProcessOnSite';
import { showToast } from '../../../utils/toast';

import { GoToPostulation } from './useGoTo';

interface UseActionTuitionFormProps {
  student: Student;
  goToPostulation: ({
    fromStart,
    postulantId,
    postulantDetailId,
    addNewPostulationDetail,
  }: GoToPostulation) => void;
  setSubmitLoad: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useActionTuitionForm = ({
  student,
  goToPostulation,
  setSubmitLoad,
}: UseActionTuitionFormProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const createNewPostulant = useCallback(
    async (values: StudentForm) => {
      setLoading(true);
      const postulant: CreatePostulantBody = {
        type: student.rut && student.rut !== '' ? 'rut' : 'passport',
        names: values.names,
        rut: student.rut !== '' ? student.rut : undefined,
        passportNumber: student.passportNumber,
        passportCountry: values.country,
        birthday: values.birthdate
          ? new Date(values.birthdate).toISOString().split('T')[0]
          : '',
        paternalLastName: values.lastnamePather,
        maternalLastName: values.lastnameMother,
        paternalPreference: true,
        email: values.email,
        phone: values.phone,
      };

      const { data, error } = await createPostulant(postulant);
      setLoading(false);

      if (data) {
        showToast(false, t);
        goToPostulation({
          fromStart: false,
          postulantId: data.data.id,
        });
      }

      if (
        error &&
        Array.isArray(error.data.message) &&
        error.data.message.some((el: string) =>
          el.includes('Debes tener al menos'),
        )
      ) {
        return addToast({
          icon: 'error',
          color: 'danger',
          text: 'Para matricularte debes tener 17 años o más',
        });
      } else if (error) {
        showToast(true, t);
      }
    },
    [goToPostulation, student.passportNumber, student.rut, t],
  );

  const createNewPostulationDetail = useCallback(
    async (values: StudentForm, postulantId: number) => {
      setSubmitLoad(true);
      const { error } = await putPostulants(postulantId, {
        names: values.names,
        maternalLastName: values.lastnameMother,
        paternalLastName: values.lastnamePather,
        birthday: values.birthdate
          ? new Date(values.birthdate).toISOString().split('T')[0]
          : '',
        email: values.email,
        phone: values.phone,
      });
      setSubmitLoad(false);

      if (!error) {
        showToast(false, t);
        goToPostulation({
          fromStart: false,
          addNewPostulationDetail: true,
        });
        return;
      }

      showToast(true, t);
      return;
    },
    [goToPostulation, setSubmitLoad, t],
  );

  const updatePostulant = useCallback(
    async (values: StudentForm, postulantId: number, btnValue: string) => {
      const btnActions: { fromStart: boolean; postulanDetailId?: number } =
        JSON.parse(btnValue);

      setSubmitLoad(true);
      const { error } = await putPostulants(postulantId, {
        names: values.names,
        maternalLastName: values.lastnameMother,
        paternalLastName: values.lastnamePather,
        birthday: values.birthdate
          ? new Date(values.birthdate).toISOString().split('T')[0]
          : '',
        email: values.email,
        phone: values.phone,
      });
      setSubmitLoad(false);

      if (!error) {
        showToast(false, t);

        if (
          btnActions.hasOwnProperty('postulanDetailId') &&
          btnActions.postulanDetailId &&
          !Number.isNaN(Number(btnActions.postulanDetailId))
        ) {
          return goToPostulation({
            fromStart: btnActions.fromStart,
            postulantDetailId: btnActions.postulanDetailId,
          });
        } else {
          return goToPostulation({
            fromStart: btnActions.fromStart,
          });
        }
      }

      showToast(true, t);
      return;
    },
    [goToPostulation, setSubmitLoad, t],
  );

  return {
    createNewPostulant,
    createNewPostulationDetail,
    updatePostulant,
    loading,
  };
};
