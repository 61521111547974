import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addToast, Button, Select, TextInput } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import useInsterestRatesVariableConfig from './useInsterestRatesVariableConfig';
import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';
import { Col, Row } from 'reactstrap';
import {
  InterestsCalculatorVariant,
  TariffPaymentMethodSettingValue,
} from '../../../../types/tariffTypes';
import { formatToOnlyPositiveScaledIntegers } from '../../../../utils/currency';
import { useValidations } from '../../../../hooks/useValidations';
import { updateFinantialConfig } from '../../../../api/requests/settings';

type ConfigValues = Omit<
  TariffPaymentMethodSettingValue,
  'interestRate' | 'maxTariffDownPaymentRate'
> & {
  interestRate: string;
  maxTariffDownPaymentRate: string;
  hasInterests: boolean;
};

export default function InterestsRatesSection() {
  const { t } = useTranslation();
  const prefix = 'configurations.finantial.interestsRates';

  const { isAuthorizedTo } = useUserState();
  const { msgValidations } = useValidations();

  const { handleSubmit, control, watch, setValue, reset } =
    useForm<ConfigValues>({
      defaultValues: {
        hasInterests: true,
        hasTuition: false,
        chargeOnlineTuition: false,
        hasPaymentMethod: false,
        interestRate: formatToOnlyPositiveScaledIntegers(`${1.5 * 100}`),
        maxTariffDownPaymentRate: formatToOnlyPositiveScaledIntegers(
          `${0 * 100}`,
        ),
        maxInstallments: 1,
        noInterestsInstallments: 1,
        interestCalculatorVariant: InterestsCalculatorVariant.ProrateInterest,
      },
    });

  const [
    maxInstallments,
    noInterestsInstallments,
    interestCalculatorVariant,
    hasInterests,
    chargeOnlineTuition,
    // hasTuition,
    // hasPaymentMethod,
  ] = watch([
    'maxInstallments',
    'noInterestsInstallments',
    'interestCalculatorVariant',
    'hasInterests',
    'chargeOnlineTuition',
    'hasTuition',
    'hasPaymentMethod',
  ]);

  const {
    config: fetchedConfigs,
    isLoading,
    isRefetching,
  } = useInsterestRatesVariableConfig();

  const [isUpdating, setIsUpdating] = useState(false);

  // Cuando llegan las configs las cargamos a la data del formulario
  useEffect(() => {
    if (fetchedConfigs)
      reset({
        ...fetchedConfigs,
        hasInterests: !!fetchedConfigs?.interestRate,
        interestRate: formatToOnlyPositiveScaledIntegers(
          `${Number(fetchedConfigs?.interestRate ?? 0) * 10000 ?? '0,00'}`,
        ),
        maxTariffDownPaymentRate: formatToOnlyPositiveScaledIntegers(
          `${
            Number(fetchedConfigs?.maxTariffDownPaymentRate ?? 0) * 10000 ??
            '0,00'
          }`,
        ),
      });
  }, [fetchedConfigs, isRefetching, reset]);

  const saveConfigurations = async (values: ConfigValues) => {
    const nextTariffDownPaymentMax =
      Number(values?.maxTariffDownPaymentRate.replace(/\D/g, '').trim() ?? 0) /
      10000;

    if (nextTariffDownPaymentMax < 0) {
      addToast({
        icon: 'error',
        text: t(`${prefix}.minTariffDownPaymentError`),
        color: 'danger',
      });
      return;
    }
    if (nextTariffDownPaymentMax > 1) {
      addToast({
        icon: 'error',
        text: t(`${prefix}.maxTariffDownPaymentError`),
        color: 'danger',
      });
      return;
    }
    setIsUpdating(true);

    const { error } = await updateFinantialConfig({
      ...values,
      interestRate: values?.hasInterests
        ? Number(values?.interestRate.replace(/\D/g, '').trim() ?? 0) / 10000
        : 0,
      maxTariffDownPaymentRate: nextTariffDownPaymentMax,
      noInterestsInstallments: values?.hasInterests
        ? values?.noInterestsInstallments
        : 0,
    });
    setIsUpdating(false);

    if (error) {
      addToast({
        icon: 'error',
        text: t(`${prefix}.errorSavingChanges`),
        color: 'danger',
      });
    } else {
      // await refetchConfigs();
      addToast({
        icon: 'success',
        text: t(`${prefix}.changesSaved`),
        color: 'success',
      });
    }
  };

  const maxInstallmentsOptions = useMemo(() => {
    return Array.from({ length: 61 }, (_v, index) => ({
      value: index,
      label: `${index}`,
    }));
  }, []);

  const noInterestsInstallmentsOptions = useMemo(() => {
    return Array.from(
      { length: maxInstallments ? maxInstallments + 1 : 0 },
      (_v, index) => ({
        value: index,
        label: `${index}`,
      }),
    );
  }, [maxInstallments]);

  const interestCalculatorVariantOptions = useMemo(
    () => [
      {
        value: InterestsCalculatorVariant.ProrateInterest,
        label: t(`${prefix}.calculatorVariants.prorate`),
      },
      {
        value: InterestsCalculatorVariant.SkipInterest,
        label: t(`${prefix}.calculatorVariants.skip`),
      },
    ],
    [t],
  );

  const booleanOptions = useMemo(
    () => [
      {
        value: 'enabled',
        label: t(`${prefix}.booleanSelector.enabled`),
      },
      {
        value: 'disabled',
        label: t(`${prefix}.booleanSelector.disabled`),
      },
    ],
    [t],
  );

  const showSave = useMemo(() => {
    return (
      !isLoading &&
      !isUpdating &&
      !!isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
    );
  }, [isAuthorizedTo, isLoading, isUpdating]);

  return (
    <form onSubmit={handleSubmit(saveConfigurations)}>
      <h2 className="text-primary fs-18 fw-600 text-uppercase mb-3">
        {t(`${prefix}.title`)}
      </h2>
      <p className="fs-14 text-medium mb-3">{t(`${prefix}.description`)}</p>
      <Row>
        <Col sm={12} md={6} xl={4}>
          <Select
            name="maxInstallments"
            label={t(`${prefix}.inputs.maxInstallments`)}
            options={maxInstallmentsOptions}
            value={{
              label: `${maxInstallments ?? 0}`,
              value: maxInstallments ?? 0,
            }}
            disabled={
              isLoading ||
              isUpdating ||
              !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
            }
            onChange={(e) => {
              setValue('maxInstallments', e?.value);
              if (e?.value) {
                if (e?.value <= noInterestsInstallments) {
                  setValue('noInterestsInstallments', e?.value);
                }
              } else {
                setValue('noInterestsInstallments', 0);
              }
            }}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col sm={12} md={6} xl={4}>
          <Select
            name="chargeOnlineTuition"
            label={t(`${prefix}.inputs.chargeOnlineTuition`)}
            options={booleanOptions}
            value={
              booleanOptions?.find((e) =>
                chargeOnlineTuition
                  ? e?.value === 'enabled'
                  : e?.value === 'disabled',
              ) ?? undefined
            }
            disabled={
              isLoading ||
              isUpdating ||
              !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
            }
            onChange={(e) =>
              setValue('chargeOnlineTuition', e?.value === 'enabled')
            }
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col sm={12} md={6} xl={4}>
          <Select
            name="hasInterests"
            label={t(`${prefix}.inputs.hasInterests`)}
            options={booleanOptions}
            value={
              booleanOptions?.find((e) =>
                hasInterests ? e?.value === 'enabled' : e?.value === 'disabled',
              ) ?? undefined
            }
            disabled={
              isLoading ||
              isUpdating ||
              !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
            }
            onChange={(e) => {
              setValue('hasInterests', e?.value === 'enabled');
              if (e?.value !== 'enabled') {
                setValue('interestRate', '0,00');
                setValue('maxTariffDownPaymentRate', '0,00');
                setValue('noInterestsInstallments', 0);
              }
            }}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        {!!hasInterests && (
          <>
            <Col sm={12} md={6} xl={4}>
              <Select
                name="interestCalculatorVariant"
                label={t(`${prefix}.inputs.interestCalculatorVariant`)}
                options={interestCalculatorVariantOptions}
                value={
                  interestCalculatorVariantOptions?.find(
                    (e) => e?.value === interestCalculatorVariant,
                  ) ?? undefined
                }
                disabled={
                  isLoading ||
                  isUpdating ||
                  !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
                }
                onChange={(e) =>
                  setValue('interestCalculatorVariant', e?.value)
                }
                rules={{ required: msgValidations.required }}
              />
            </Col>
            <Col sm={12} md={6} xl={4}>
              <TextInput
                control={control}
                name="interestRate"
                label={t(`${prefix}.inputs.interestRate`)}
                disabled={
                  isLoading ||
                  isUpdating ||
                  !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
                }
                formatter={formatToOnlyPositiveScaledIntegers}
              />
            </Col>
            <Col sm={12} md={6} xl={4}>
              <Select
                name="noInterestsInstallments"
                label={t(`${prefix}.inputs.noInterestsInstallments`)}
                options={noInterestsInstallmentsOptions}
                value={
                  maxInstallments
                    ? {
                        label: `${noInterestsInstallments ?? 0}`,
                        value: noInterestsInstallments ?? 0,
                      }
                    : undefined
                }
                disabled={
                  isLoading ||
                  isUpdating ||
                  !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL]) ||
                  !maxInstallments
                }
                onChange={(e) => setValue('noInterestsInstallments', e?.value)}
              />
            </Col>
          </>
        )}
        <Col sm={12} md={6} xl={4}>
          <TextInput
            control={control}
            name="maxTariffDownPaymentRate"
            label={t(`${prefix}.inputs.maxTariffDownPaymentRate`)}
            disabled={
              isLoading ||
              isUpdating ||
              !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
            }
            formatter={formatToOnlyPositiveScaledIntegers}
          />
        </Col>
        {/* <Col sm={12} md={6} xl={4}>
          <Select
            name="hasTuition"
            label={t(`${prefix}.inputs.hasTuition`)}
            options={booleanOptions}
            value={
              booleanOptions?.find((e) =>
                hasTuition ? e?.value === 'enabled' : e?.value === 'disabled',
              ) ?? undefined
            }
            disabled={
              isLoading ||
              isUpdating ||
              !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
            }
            onChange={(e) => setValue('hasTuition', e?.value === 'enabled')}
            rules={{ required: msgValidations.required }}
          />
        </Col> */}
        {/* <Col sm={12} md={6} xl={4}>
          <Select
            name="hasPaymentMethod"
            label={t(`${prefix}.inputs.hasPaymentMethod`)}
            options={booleanOptions}
            value={
              booleanOptions?.find((e) =>
                hasPaymentMethod
                  ? e?.value === 'enabled'
                  : e?.value === 'disabled',
              ) ?? undefined
            }
            disabled={
              isLoading ||
              isUpdating ||
              !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
            }
            onChange={(e) =>
              setValue('hasPaymentMethod', e?.value === 'enabled')
            }
            rules={{ required: msgValidations.required }}
          />
        </Col> */}
      </Row>
      {showSave && (
        <Button
          type="submit"
          text={t(`${prefix}.saveConfig`)}
          size="md"
          className="float-right mt-5"
          disabled={
            isLoading ||
            isRefetching ||
            isUpdating ||
            !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL])
          }
        />
      )}
    </form>
  );
}
