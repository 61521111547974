import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useQuery from '../../hooks/useQuery';
import useUserState from '../../hooks/useUserState';
import { PermissionName } from '../../types/Auth';
import Academics from './academics/Academics';
import Finantial from './finantial/Finantial';

enum TabName {
  Demographics = 'demographics',
  General = 'general',
  Academics = 'academics',
  Finantial = 'finantial',
  HelpCenter = 'help-center',
}

const DEFAULT_TAB = TabName.Academics;

export default function Configurations() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = useQuery();
  const { isAuthorizedTo } = useUserState();

  const { t } = useTranslation();
  const tabsPrefix = `configurations.tabs`;

  const initialTab = useMemo(() => {
    const queryTab = params.get('tab');
    if (!queryTab) return DEFAULT_TAB;

    const isValidTab = Object.values(TabName).some((tn) => tn === queryTab);
    return isValidTab ? queryTab : DEFAULT_TAB;
  }, [params]);

  const handleTabSelection = (selectedTab: number | string) => {
    history.push({ pathname: path, search: `?tab=${selectedTab}` });
    return null;
  };

  return (
    <div className="mx-3">
      <Tabs defaultTab={initialTab} onSelection={handleTabSelection}>
        <TabsList>
          {/* <Tab id="demographics" disabled>
            {t(`${tabsPrefix}.demographics`)}
          </Tab> */}

          {isAuthorizedTo([
            PermissionName.UPDATE_SETTINGS_TIME_LESSON,
            PermissionName.CREATE_SETTINGS_GRADE_ENTRY,
          ]) ? (
            <Tab id={TabName.Academics}>{t(`${tabsPrefix}.academics`)}</Tab>
          ) : (
            <></>
          )}

          {/* <Tab id="general" disabled>
            {t(`${tabsPrefix}.general`)}
          </Tab> */}

          {isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL]) ? (
            <Tab id={TabName.Finantial}>{t(`${tabsPrefix}.finantial`)}</Tab>
          ) : (
            <></>
          )}

          {/* <Tab id="help-center" disabled>
            {t(`${tabsPrefix}.helpCenter`)}
          </Tab> */}
        </TabsList>
        <TabPanels>
          {/* <TabPanel id="demographics">Demograficos</TabPanel> */}

          {isAuthorizedTo([
            PermissionName.UPDATE_SETTINGS_TIME_LESSON,
            PermissionName.CREATE_SETTINGS_GRADE_ENTRY,
          ]) ? (
            <TabPanel id={TabName.Academics} children={<Academics />} />
          ) : (
            <></>
          )}

          {isAuthorizedTo([PermissionName.UPDATE_SETTINGS_FINANTIAL]) ? (
            <TabPanel id={TabName.Finantial} children={<Finantial />} />
          ) : (
            <></>
          )}
          {/* <TabPanel id="general">Generales</TabPanel> */}
          {/* <TabPanel id="finantial">Financieros</TabPanel> */}
          {/* <TabPanel id="help-center">Centro de ayuda</TabPanel> */}
        </TabPanels>
      </Tabs>
    </div>
  );
}
