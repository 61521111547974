import {
  OutlinedSelectOptionType,
  Select,
  TablePagination,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { Period } from '../../../api/requests/MantainerPortal';
import {
  getDocumentationReview,
  StudyPlansOfferDocumentationReview,
} from '../../../api/requests/studyPlansOffer';
import { Card as CustomCard } from '../../../components/article/Card';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { useLoadingState } from '../../../hooks/useLoadingState';

interface IDocumentsGridCardsContentProps {
  periods: Period[];
  currentPeriod?: Period;
}

const ITEMS_PER_PAGE_DEFAULT = 12;

export default function DocumentsGridCardsContent({
  periods,
  currentPeriod,
}: IDocumentsGridCardsContentProps) {
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedPeriod, setSelectedPeriod] = useState<Period | undefined>(
    currentPeriod,
  );
  const [documentationReview, setDocumentationReview] = useState<
    StudyPlansOfferDocumentationReview[]
  >([]);

  const options = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map(({ name, id }) => {
      return {
        label: name,
        value: id,
      };
    }) as OutlinedSelectOptionType[];
  }, [periods]);

  const selectedOption = useMemo(() => {
    const option = options.find(({ value }) => value === selectedPeriod?.id);
    if (option) {
      return option;
    }
    if (options.length > 0) {
      return options[0];
    }
    return undefined;
  }, [options, selectedPeriod]);

  const changePeriod = useCallback(
    (option: OutlinedSelectOptionType) => {
      const period = periods.find(({ id }) => option.value === id);
      setSelectedPeriod(period);
    },
    [periods],
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    let { data, error } = await getDocumentationReview(
      ITEMS_PER_PAGE_DEFAULT,
      currentPage - 1,
      Number(selectedOption?.value),
    );
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setDocumentationReview(data.data);
      setTotalItems(data.total);
      setTotalPages(data.total_pages);
    }
    setLoading(false);
  }, [
    currentPage,
    setLoading,
    setErrorLoading,
    setDocumentationReview,
    setTotalItems,
    setTotalPages,
    selectedOption?.value,
  ]);

  useEffect(() => {
    fetchData();
  }, [setLoading, currentPage, fetchData]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <Select
            name="selection-period"
            label={t('academicOffers.form.period')}
            value={selectedOption}
            options={options}
            isClearable={false}
            onChange={changePeriod}
          />
        </Col>
      </Row>

      {documentationReview.length === 0 && !loading && (
        <TableEmptyContent
          title={t(
            'academicOffers.form.StudyPlanAcademicOffersTableEmptyTitle',
          )}
          subtitle={''}
        />
      )}

      {loading && <Loading insideCard />}

      {!loading && (
        <Row className="pt-4">
          {documentationReview.map((documentation, key) => {
            const {
              Campus_name,
              Schedule_name,
              StudyPlan_name,
              enrolled,
              notReviewed,
              waiting,
              approved,
              approvedPercentage,
              AcademicOfferStudyPlanVersionOffers_id,
            } = documentation;

            return (
              <Col md={3} key={key} className="mb-4">
                <CustomCard
                  title={StudyPlan_name}
                  subtitle={`${Campus_name} - ${Schedule_name}`}
                  body={[
                    { label: t('documents.enrolled'), value: enrolled },
                    { label: t('documents.notReviewed'), value: notReviewed },
                    { label: t('documents.waiting'), value: waiting },
                    { label: t('documents.approved'), value: approved },
                  ]}
                  markedText={{
                    label: t('documents.approved'),
                    value: `${
                      approvedPercentage < 100
                        ? approvedPercentage.toFixed(1)
                        : Math.round(approvedPercentage)
                    }%`,
                  }}
                  to={`${PathsLayouts.documents}/${AcademicOfferStudyPlanVersionOffers_id}`}
                />
              </Col>
            );
          })}
        </Row>
      )}

      {totalPages > 1 && (
        <TablePagination
          pagination={{
            currentPage: currentPage,
            itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
            onChangePage: (page) => {
              setCurrentPage(page);
            },
            totalItems: totalItems,
            totalPages: totalPages,
          }}
        />
      )}
    </>
  );
}
