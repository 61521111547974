import {
  Modal,
  Button,
  TextInput,
  Select,
  SelectOptionType,
  addToast,
  TextField,
} from '@octano/global-ui';
import { Row, Form, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { useValidations } from '../../hooks/useValidations';
import { useLayoutState } from '../../hooks/useLayoutState';
import { useCallback, useEffect, useState } from 'react';
import { CampusBody } from '../../types/CampusTypes';
import { ScheduleItem } from '../../maintainers/schedule/ScheduleList';
import { VersionOffer } from '../interfaces/study-plan-item-custom.interface';

interface AcademicOffersModalProps {
  show: boolean;
  onCloseModal: () => void;
  title: string;
  campus: CampusBody[];
  schedules: ScheduleItem[];
  studyPlanName: string;
  onConfirm: (values: ConfirmModalData) => void;
  tableData: VersionOffer[];
}

export interface ConfirmModalData {
  campus: CampusBody;
  quota: number;
  schedule: ScheduleItem;
}

export interface FormModal {
  campus: SelectOptionType;
  quota: number | null;
  schedule: SelectOptionType;
}

const AcademicOffersAddModal = ({
  show,
  onCloseModal,
  title,
  onConfirm,
  studyPlanName,
  campus,
  tableData,
  schedules,
}: AcademicOffersModalProps) => {
  const [campusSelect, setCampusSelect] = useState<SelectOptionType[]>([]);
  const [schedulesSelect, setSchedulesSelect] = useState<SelectOptionType[]>(
    [],
  );

  const { t } = useTranslation();

  useEffect(() => {
    setCampusSelect(
      campus.map((e) => ({
        label: e.name,
        value: e.id ? e.id : 0,
      })),
    );
    setSchedulesSelect(
      schedules.map((e) => ({
        label: e.name,
        value: e.id,
      })),
    );
  }, [campus, schedules]);

  // validations
  const { msgValidations } = useValidations();

  // mostrar error al guardar
  const { showErrorModal } = useLayoutState();

  // valores predeterminados
  const defaultValues = Object.assign({
    campus: '',
    quota: '',
    schedule: '',
  });

  const methods = useForm<FormModal>({
    mode: 'onSubmit',
    defaultValues,
  });

  const { handleSubmit, control, reset } = methods;

  const resetModal = useCallback(() => {
    reset(defaultValues, {
      keepErrors: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    });
  }, [reset, defaultValues]);

  // usar valores del formulario
  const onSubmit = useCallback(
    async (formValues: FormModal) => {
      // obtener los campos que se van enviar
      const campusForm: CampusBody | null | undefined = campus.find(
        (value) => value.id === formValues.campus.value,
      );
      const scheduleForm: ScheduleItem | null | undefined = schedules.find(
        (value) => value.id === formValues.schedule.value,
      );
      const quotasForm: number = Number(formValues.quota);
      const hasUsedScheduleAndCampus = tableData.some(
        (current: VersionOffer) =>
          current.StudyPlanVersionOffer_campusId === campusForm?.id &&
          current.StudyPlanVersionOffer_scheduleId === scheduleForm?.id,
      );

      if (hasUsedScheduleAndCampus) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('academicOffers.form.offerExistsScheduleAndCampus'),
        });
      } else if (
        campusForm &&
        scheduleForm &&
        quotasForm &&
        !hasUsedScheduleAndCampus
      ) {
        resetModal();
        onConfirm({
          campus: campusForm,
          schedule: scheduleForm,
          quota: quotasForm,
        });
      } else {
        showErrorModal();
      }
    },
    [resetModal, showErrorModal, onConfirm, campus, schedules, tableData, t],
  );

  const closeModal = () => {
    resetModal();
    onCloseModal();
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        onCloseModal();
      }}
      size="lg"
      unmountOnClose
    >
      <FormProvider {...methods}>
        <Form
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleSubmit(onSubmit)(e);
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <div className="fw-600 text-black fs-22 text-center">{title}</div>
          </div>

          <Row className={'pt-5'}>
            <Col md={12}>
              <Row>
                <Col xs={12} md={6}>
                  <TextField placeholder={t(`academicOffers.form.studyPlan`)}>
                    {studyPlanName}
                  </TextField>
                </Col>
                <Col xs={12} md={6}>
                  <TextInput
                    name="quota"
                    control={control}
                    label={t(`academicOffers.form.quotas`)}
                    formatter={(value) => {
                      const newValue = value.replace(/\D/g, '');

                      if (Number(newValue) < 1) {
                        return '';
                      }
                      return newValue;
                    }}
                    rules={{
                      required: msgValidations.required,
                      maxLength: {
                        value: 255,
                        message: t('common.validations.maxLength', {
                          length: 255,
                        }),
                      },
                      pattern: {
                        value: /^\d*$/,
                        message: t('common.validations.invalidIntNumeric'),
                      },
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <Col xs={12} md={6}>
                  <Select
                    name="campus"
                    label={t('common.forms.campus')}
                    options={campusSelect}
                    control={control}
                    rules={{ required: msgValidations.required }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Select
                    name="schedule"
                    label={t('common.forms.schedule')}
                    options={schedulesSelect}
                    control={control}
                    rules={{ required: msgValidations.required }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="pt-5">
            <Col
              xs={{ order: 2, size: 12 }}
              md={{ order: 1, size: 6 }}
              className="ml-auto"
            >
              <div>
                <Button
                  type="button"
                  text={t(`common.actions.cancel`)}
                  outlined
                  onClick={() => closeModal()}
                  fullwidth
                />
              </div>
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 6 }}>
              <div className="pb-3 pb-md-0">
                <Button
                  type="submit"
                  text={t('common.actions.confirm')}
                  fullwidth
                />
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};
export default AcademicOffersAddModal;
