import {
  Button,
  OutlinedSelect,
  SearchBox,
  SelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { useFetch } from '../../../components/contexts/FetchContext';
import {
  CurriculumProgressStudents,
  CurriculumProgressStudentsQuery,
} from '../types';

export interface SearchFiltersType {
  studyPlanVersionId: SelectOptionType | null;
  status: SelectOptionType | null;
  searchText: string | null;
  periodId: SelectOptionType | null;
}

interface SearchFiltersProps {
  studyPlanVersions: { id: number; name: string }[];
  status: string[];
  periods: { id: number; name: string }[];
}

const SearchFilters = ({
  studyPlanVersions,
  status,
  periods,
}: SearchFiltersProps) => {
  const prefix = 'curriculumProgress.filters';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      status: t<string, Record<string, string>>(`studyPlanEnrollmentStatus`, {
        returnObjects: true,
      }),
      label: {
        studyPlanVersionId: t(`${prefix}.label.studyPlanVersionId`),
        status: t(`${prefix}.label.status`),
        searchText: t(`${prefix}.label.searchText`),
        periodId: t(`${prefix}.label.periodId`),
      },
      placeholder: {
        studyPlanVersionId: t(`${prefix}.placeholder.studyPlanVersionId`),
        status: t(`${prefix}.placeholder.status`),
        searchText: t(`${prefix}.placeholder.searchText`),
        periodId: t(`${prefix}.placeholder.periodId`),
      },
      action: {
        search: t(`${prefix}.action.search`),
        clean: t(`${prefix}.action.clean`),
      },
    }),
    [t],
  );

  const { updateQuery } = useFetch<
    CurriculumProgressStudentsQuery,
    CurriculumProgressStudents
  >();
  const { control, handleSubmit, setValue, reset } = useForm<SearchFiltersType>(
    {
      defaultValues: {
        studyPlanVersionId: null,
        status: null,
        searchText: null,
        periodId: null,
      },
    },
  );
  const isMobile = useMobile();

  const onSubmit = useCallback(
    (values: SearchFiltersType) => {
      updateQuery({
        search: {
          studyPlanVersionId:
            values.studyPlanVersionId &&
            typeof values.studyPlanVersionId.value === 'number'
              ? values.studyPlanVersionId.value
              : undefined,
          status: values.status ? String(values.status.value) : undefined,
          searchText: values.searchText ?? undefined,
          periodId:
            values.periodId && typeof values.periodId.value === 'number'
              ? values.periodId.value
              : undefined,
        },
        page: 1,
      });
    },
    [updateQuery],
  );

  const onClear = useCallback(() => {
    reset();
    updateQuery();
  }, [reset, updateQuery]);

  const studyPlanVersionsOptions = useMemo<SelectOptionType[]>(() => {
    return studyPlanVersions.map((s) => ({
      value: s.id,
      label: s.name,
    }));
  }, [studyPlanVersions]);

  const periodsOptions = useMemo<SelectOptionType[]>(() => {
    return periods.map((p) => {
      return {
        value: p.id,
        label: p.name,
      };
    });
  }, [periods]);

  const statusOptions = useMemo<SelectOptionType[]>(() => {
    return status.map((s) => ({
      value: s,
      label: texts.status[s],
    }));
  }, [status, texts]);

  const clearNameOrId = useCallback(
    () => setValue('searchText', ''),
    [setValue],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="studyPlanVersionId"
            options={studyPlanVersionsOptions}
            label={texts.label.studyPlanVersionId}
            placeholder={texts.placeholder.studyPlanVersionId}
            control={control}
            isClearable
          />
        </Col>
        <Col xs={12} md={6} lg={8}>
          <SearchBox
            name="searchText"
            label={texts.label.searchText}
            placeholder={texts.placeholder.searchText}
            control={control}
            clear={clearNameOrId}
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="status"
            options={statusOptions}
            label={texts.label.status}
            placeholder={texts.placeholder.status}
            control={control}
            isClearable
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="periodId"
            options={periodsOptions}
            label={texts.label.periodId}
            placeholder={texts.placeholder.periodId}
            control={control}
            isClearable
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 2 }}
          lg={{ size: 2 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={texts.action.search}
            size="md"
            fullwidth
          />
        </Col>
        <Col
          xs={12}
          md={{ size: 2 }}
          lg={{ size: 2 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            text={texts.action.clean}
            size="md"
            outlined
            onClick={onClear}
            fullwidth
          />
        </Col>
      </Row>
    </Form>
  );
};

export default SearchFilters;
