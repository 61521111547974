import { PaginationRequestType } from '../../types/paginationRequestType';
import { STUDY_PLANS_OFFER } from '../endpoints';
import request from '../request';

export type StudyPlanOfferItem = {
  Postulant_rut: string | null;
  Postulant_id: number;
  PostulantPassport_number: string | null;
  Postulant_names: string;
  Postulant_paternalLastName: string;
  Postulant_maternalLastName: string;
  Contact_cellPhone: string;
  Contact_email: string;
  Discount_discountValue: number | null;
  Details_enrolledDate: string | null;
  Details_retractedDate: string | null;
  Details_postulationId: number;
  Details_id: number;
  Discount_discountCode: string | null;
  Discount_discountName: string | null;
  Discount_id: number | null;
  PostulantPassport_countryId: string | null;
};

export type StudyPlanOffertResponse = PaginationRequestType<StudyPlanOfferItem>;

export type StudyPlanOffertOnlyOneResponse = {
  data: Omit<StudyPlanOfferItem, 'Postulant_id'>;
};

export type Discount = {
  postulationDetailId: number;
  discountCode: string;
  discountName: string;
  discountValue: number;
  id: number;
  createdAt: string;
  updatedAt: string;
};

export interface StudyPlansOfferDocumentationReview {
  StudyPlanVersionOffer_id: number;
  StudyPlanVersion_name: string;
  AcademicOfferStudyPlanVersionOffers_id: number;
  Admissions_id: number;
  Campus_name: string;
  Schedule_name: string;
  enrolled: number;
  notReviewed: number;
  waiting: number;
  approved: number;
  approvedPercentage: number;
  StudyPlan_name: string;
}

export interface AcademicOfferStudyPlansOfferDocumentationByStatus {
  Postulant_id: number;
  Postulant_rut?: string;
  Postulant_names: string;
  Postulant_paternalLastName: string;
  Postulant_maternalLastName: string;
  Postulant_paternalPreference: boolean;
  Postulant_birthday: string;
  Contact_cellPhone: string;
  Contact_email: string;
  PostulantPassport_number?: string;
  PostulantPassport_countryId?: string;
  Details_id: number;
}

export interface SearchStudyPlansOfferDocumentationByStatus
  extends AcademicOfferStudyPlansOfferDocumentationByStatus {
  tab: DocumentationReviewStatus;
}

export interface StudyPlansOfferCampusSchedule {
  StudyPlanVersionOffer_id: number;
  StudyPlanVersion_name: string;
  Campus_name: string;
  Schedule_name: string;
  StudyPlan_name: string;
}

/**
 * Busqueda de postulantes pendientes
 */
export const searchPostulantPending = (
  { search }: { search?: string },
  { page }: { page: number },
) => {
  const url = STUDY_PLANS_OFFER.SEARCH_POSTULANT_PENDING;

  const params: Record<string, string | number> = {
    items_per_page: 10,
    page: page - 1,
  };
  if (search) {
    params['search'] = search;
  }

  return request<StudyPlanOffertResponse>(url, {
    params,
  });
};

/**
 * obtiene la lista de admisión en espera
 */
export const getAdmissionStatusList = (
  academicOfferStudyPlanVersionOffersId: number,
  { search, status }: { search?: string; status: any },
  { page }: { page: number },
) => {
  const url = STUDY_PLANS_OFFER.STATUS_LIST(
    academicOfferStudyPlanVersionOffersId,
  );
  const params: Record<string, string | number> = { status, page: page - 1 };
  if (search) {
    params['search'] = search;
  }
  return request<StudyPlanOffertResponse>(url, { params });
};

/**
 * obtiene un postulante de admisión en espera
 */
export const getAdmissionStatusPostulant = (
  academicOfferStudyPlanVersionOffersId: number,
  { postulantId, status }: { postulantId: number; status: any },
) => {
  const url = STUDY_PLANS_OFFER.GET_POSTULANT(
    academicOfferStudyPlanVersionOffersId,
  );
  const params: Record<string, string | number> = { status, postulantId };

  return request<StudyPlanOffertOnlyOneResponse>(url, { params });
};

/**
 * guarda descuento a postulante
 */
export const saveDiscountPostulant = (
  detail_id: number,
  discountCode: string,
  discountName: string,
  discountValue: number,
  detailDiscountId: number | null,
) => {
  const url = `${STUDY_PLANS_OFFER.SET_DISCOUNT}/${detail_id}`;
  return request<Discount>(url, {
    method: 'post',
    data: {
      discountCode: discountCode,
      discountName: discountName,
      discountValue: discountValue,
      detailDiscountId: detailDiscountId,
    },
  });
};

/**
 * Eliminar postulante de lista pendientes
 */
export const deletePostulant = (id: number) => {
  const url = `${STUDY_PLANS_OFFER.DELETE_POSTULANT}/${id}`;
  return request<StudyPlanOfferItem>(url, { method: 'DELETE' });
};

/**
 * Cambiar postulante a desistido
 */
export const changeToDesist = (id: number) => {
  const url = `${STUDY_PLANS_OFFER.TO_STATUS_DESISTED}/${id}`;
  return request<StudyPlanOfferItem>(url, {
    method: 'PUT',
  });
};

export const changeToInProcess = (id: number) => {
  const url = `${STUDY_PLANS_OFFER.TO_IN_PROCESS}/${id}`;
  return request<StudyPlanOfferItem>(url, {
    method: 'PUT',
  });
};

export const getStatusStudyPlanChange = (detailId: number) => {
  return request<{
    status: string;
    studyPlanChangeDetail: {
      admissionId: number;
      amount: number;
      enrolledDate: string | null;
      id: number;
      isPaid: boolean;
      mustPay: boolean;
      position: number | null;
      postulationId: number;
      retractedDate: string | null;
      score: number | null;
      status: string;
    };
  }>(STUDY_PLANS_OFFER.GET_STATUS_STUDY_PLAN_CHANGE(detailId));
};

export const changeStatusToRetracted = (
  id: number,
  data: { showWarning: boolean },
) => {
  const url = `${STUDY_PLANS_OFFER.TO_STATUS_RETRACTED}/${id}`;
  return request<StudyPlanOffertResponse>(url, {
    method: 'PUT',
    data,
  });
};

export const getPostulansPendingRequest = () => {
  const url = STUDY_PLANS_OFFER.POSTULANTSPENDING;
  return request<{ data: number }>(url, {
    method: 'GET',
  });
};

export const getDocumentationReview = (
  items: number,
  page: number,
  periodId: number,
) => {
  const url = `${STUDY_PLANS_OFFER.DOCUMENTATION_REVIEW}`;
  return request<PaginationRequestType<StudyPlansOfferDocumentationReview>>(
    url,
    {
      method: 'GET',
      params: { items_per_page: items, page, periodId },
    },
  );
};

export enum DocumentationReviewStatus {
  NOT_REVIEWED = 'sin_revision',
  WAITING = 'en_espera',
  APPROVED = 'aprobado',
}

export const getDocumentationByStatus = (
  academicOfferStudyPlanVersionOffersId: number | string,
  {
    status,
    items,
    page,
    searchText,
  }: {
    status?: DocumentationReviewStatus;
    items: number;
    page: number;
    searchText?: string;
  },
) => {
  const url = `${STUDY_PLANS_OFFER.RAIZ}/${academicOfferStudyPlanVersionOffersId}`;
  return request<
    PaginationRequestType<AcademicOfferStudyPlansOfferDocumentationByStatus>
  >(url, {
    method: 'GET',
    params: {
      items_per_page: items,
      page,
      searchText,
      status,
    },
  });
};

export const getStudyPlanCampusSchedule = (id: number) => {
  const url = `${STUDY_PLANS_OFFER.RAIZ}/${id}/study-plan-campus-schedule`;
  return request<StudyPlansOfferCampusSchedule>(url, {
    method: 'GET',
  });
};

export const searchPostulantByRut = (id: number, rut: string) => {
  const url = STUDY_PLANS_OFFER.SEARCH_BY_RUT(id, rut);
  return request<SearchStudyPlansOfferDocumentationByStatus>(url, {
    method: 'GET',
  });
};

export const searchPostulantByPassport = (
  id: number,
  countryId: string,
  search: string,
) => {
  const url = STUDY_PLANS_OFFER.SEARCH_BY_PASSPORT(id, countryId, search);
  return request<SearchStudyPlansOfferDocumentationByStatus>(url, {
    method: 'GET',
  });
};
