import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadingState } from '../hooks/useLoadingState';

import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, Card, Container, Form } from 'reactstrap';
import { SectionTitle } from '../components/text';
import {
  TextInput,
  Button,
  Select,
  addToast,
  SelectOptionType,
} from '@octano/global-ui';
import AcademicOffersEditForm from './AcademicOffersEditForm';
import {
  getAcademicOfferAndSchools,
  editAcademicOffersRequest,
  validateQuotaRequest,
  deleteAcademicOfferRequest,
  confirmOfferAcademic,
} from '../api/requests/academicOffers';
import { SchoolsListItem } from '../api/requests/schools';
import { getPeriodsWithNoOfferRequest } from '../api/requests/periods';
import { FormProvider, useForm } from 'react-hook-form';
import { useValidations } from '../hooks/useValidations';
import AcademicOfferDeleteModal from './modals/AcademicOfferDeleteModal';
import { AcademicOffersType } from '../types/AcademicOffersType';
import Loading from '../components/info/Loading';
import DisplayError from '../components/info/DisplayError';
import AcademicOfferValidateQuotaModal from './modals/AcademicOfferValidateQuotaModal';
import AcademicOfferConfirmModal, {
  PlansNotUpdated,
} from './modals/AcademicOfferConfirmModal';
import { AxiosResultDefaultError } from '../api/request';
import {
  AcademicOffersValues,
  PeriodItem,
} from './interfaces/academic-offer.interface';
import { PermissionName } from '../types/Auth';
import useUserState from '../hooks/useUserState';
import { showToast } from '../utils/toast';
import GoBackButton from '../components/buttons/GoBackButton';

const showDeleteToast = (error: boolean, t: Function) => {
  addToast(
    error
      ? {
          icon: 'error',
          color: 'danger',
          text: t('academicOffers.list.deleteError'),
        }
      : {
          icon: 'success',
          color: 'success',
          text: t('academicOffers.list.deleteSuccess'),
        },
  );
};

const AcademicOffersEdit = () => {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();

  const [academicOfferData, setAcademicOfferData] =
    useState<AcademicOffersType | null>(null);
  const [periods, setPeriods] = useState<PeriodItem[]>([]);
  const methods = useForm<AcademicOffersValues>({
    mode: 'onSubmit',
    defaultValues: {
      period: null,
      places: null,
    },
  });

  const [modalConfirmOffer, setModalConfirmOffer] = useState<{
    open: boolean;
    plansNotUpdated?: Array<PlansNotUpdated>;
  }>({
    open: false,
    plansNotUpdated: [],
  });

  const { handleSubmit, control, setValue } = methods;
  const [schools, setSchools] = useState<SchoolsListItem[]>([]);
  const [, setSchoolSelected] = useState<SelectOptionType>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [validateQuotaCode, setValidateQuotaCode] = useState<number | null>(
    null,
  );
  const [buttonNextLoading, setButtonNextLoading] = useState<boolean>(false);
  const { id: academicOfferId } = useParams<{ id: string }>();
  const history = useHistory();
  const prefix = 'academicOffers.form';
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { msgValidations } = useValidations();

  const getPeriods = useCallback(async () => {
    const { data, error } = await getPeriodsWithNoOfferRequest();
    if (error) {
      setErrorLoading(error.code);
      return;
    }

    if (data) {
      setErrorLoading(undefined);
      setPeriods(data.data);
    }

    setLoading(false);
  }, [setErrorLoading, setLoading]);

  const deleteAcademicOffer = useCallback(async () => {
    const { error } = await deleteAcademicOfferRequest(academicOfferId);

    showDeleteToast(!!error, t);
    if (!error) {
      history.push('/academic-offers');
    }
  }, [t, history, academicOfferId]);

  useEffect(() => {
    getPeriods();
  }, [getPeriods]);

  const confirmOffer = useCallback(() => {
    history.push(
      `/academic-offers/info/${academicOfferId}?viewinform=true&confirm=true`,
    );
  }, [history, academicOfferId]);

  const showModalIfExistPlanesNotUpdated = useCallback(
    async (
      validateQuotaResponse: number,
      editResponseMsgError: AxiosResultDefaultError | boolean | undefined,
    ) => {
      let { data: plansNotUpdated = [], error: errorPlansNotUpdated } =
        await confirmOfferAcademic(Number(academicOfferId));
      if (errorPlansNotUpdated) {
        showToast(Boolean(errorPlansNotUpdated), t);
      } else {
        if (validateQuotaResponse === 0) {
          if (plansNotUpdated && plansNotUpdated.length !== 0) {
            setModalConfirmOffer({ open: true, plansNotUpdated });
          } else {
            confirmOffer();
          }
          showToast(Boolean(editResponseMsgError), t);
        } else {
          modalConfirmOffer.plansNotUpdated = plansNotUpdated || [];
          setModalConfirmOffer({ ...modalConfirmOffer });
          setValidateQuotaCode(validateQuotaResponse);
        }
      }
    },
    [academicOfferId, t, modalConfirmOffer, confirmOffer],
  );

  const onSubmit = useCallback(
    async (values) => {
      if (!isAuthorizedTo([PermissionName.EDIT_ACADEMIC_OFFER])) {
        history.push(
          `/academic-offers/info/${academicOfferId}?viewinform=true&confirm=true`,
        );
      }

      setButtonNextLoading(true);
      if (academicOfferId) {
        const [editResponse, validateQuotaResponse] = await Promise.all([
          editAcademicOffersRequest(
            {
              periodId: Number(values.period.value),
              quota: Number(values.places),
            },
            academicOfferId,
          ),
          validateQuotaRequest(academicOfferId),
        ]);
        if (!validateQuotaResponse.error) {
          await showModalIfExistPlanesNotUpdated(
            validateQuotaResponse.data,
            editResponse.error,
          );
        } else {
          showToast(Boolean(validateQuotaResponse.error), t);
        }
        setButtonNextLoading(false);
      }
    },
    [
      academicOfferId,
      showModalIfExistPlanesNotUpdated,
      t,
      history,
      isAuthorizedTo,
    ],
  );

  const loadViewData = useCallback(() => {
    getAcademicOfferAndSchools(Number(academicOfferId))
      .then((response) => {
        if (response.data) {
          const currentAcademicOfferData = response.data
            .data[0] as AcademicOffersType;
          const currentSchoolsData = response.data.data[1] as SchoolsListItem[];
          setSchools(currentSchoolsData);

          const {
            Period_id,
            Period_name,
            Period_startDate,
            Period_endDate,
            AcademicOffer_status,
            AcademicOffer_quota,
            enrolled,
            DuplicatedAcademicOffer_id,
            DuplicatedAcademicOffer_quota,
          } = currentAcademicOfferData;

          setAcademicOfferData({
            Period_id,
            Period_name,
            Period_startDate,
            Period_endDate,
            AcademicOffer_status,
            AcademicOffer_quota,
            enrolled,
            DuplicatedAcademicOffer_id,
            DuplicatedAcademicOffer_quota,
          });

          setLoading(false);
          if (Period_id && Period_name) {
            setValue('period', {
              value: String(Period_id),
              label: Period_name,
            });

            if (!periods.find((e: PeriodItem) => e.id === Period_id)) {
              setPeriods([...periods, { name: Period_name, id: Period_id }]);
            }
          }
          setValue('places', `${AcademicOffer_quota}`);
        }
      })
      .catch((error) => {
        setErrorLoading(error.code);
      })
      .finally(() => setLoading(false));
  }, [academicOfferId, setLoading, setErrorLoading, setValue, periods]);

  useEffect(() => {
    if (!academicOfferData) {
      setLoading(true);
      loadViewData();
    }
  }, [academicOfferData, loadViewData, setLoading]);

  const isDraft = academicOfferData?.AcademicOffer_status === 'Borrador';

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return (
      <Container>
        <Loading insideCard />
      </Container>
    );
  }
  return (
    <Container>
      <Card className="p-3 p-md-4">
        <div className="mb-4">
          <GoBackButton onClick={() => history.push('/academic-offers')} />
        </div>

        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12}>
                <Row className="mb-5">
                  <Col xs={12}>
                    <SectionTitle text={t(`${prefix}.step2Title`)} />
                  </Col>
                  <Col
                    xs={12}
                    md={
                      academicOfferData?.DuplicatedAcademicOffer_quota ? 3 : 6
                    }
                    className="pb-3"
                  >
                    <Select
                      name="period"
                      label={t(`${prefix}.selectPeriod`)}
                      options={periods.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))}
                      disabled={
                        !isDraft ||
                        (isDraft &&
                          !isAuthorizedTo([PermissionName.EDIT_ACADEMIC_OFFER]))
                      }
                      control={control}
                      rules={{ required: msgValidations.required }}
                    />
                  </Col>
                  {academicOfferData?.DuplicatedAcademicOffer_id && (
                    <>
                      <Col xs={12} md={3} className="pb-3">
                        <TextInput
                          name="quota"
                          label={t(`${prefix}.quotaBefore`)}
                          disabled
                          value={
                            academicOfferData?.DuplicatedAcademicOffer_quota
                          }
                        />
                      </Col>
                      <Col xs={12} md={3} className="pb-3 mb-3">
                        <TextInput
                          name="enrolled"
                          label={t('academicOffers.list.enrolled')}
                          disabled
                          value={academicOfferData?.enrolled}
                        />
                      </Col>
                    </>
                  )}
                  <Col
                    xs={12}
                    md={
                      academicOfferData?.DuplicatedAcademicOffer_quota ? 3 : 6
                    }
                    className="pb-3 mb-3"
                  >
                    <TextInput
                      name="places"
                      label={t(`${prefix}.newTotalPlaces`)}
                      disabled={
                        !isDraft ||
                        (isDraft &&
                          !isAuthorizedTo([PermissionName.EDIT_ACADEMIC_OFFER]))
                      }
                      control={control}
                      formatter={(value) => {
                        const newValue = value.replace(/\D/g, '');
                        if (Number(newValue) < 1) {
                          return '';
                        }
                        return newValue;
                      }}
                      rules={{
                        required: msgValidations.required,
                      }}
                    />
                  </Col>
                </Row>
                <AcademicOffersEditForm
                  hasDuplicatedPeriod={
                    !!academicOfferData?.DuplicatedAcademicOffer_id
                  }
                  schools={schools}
                  academicOfferId={Number(academicOfferId)}
                  setSchoolSelected={setSchoolSelected}
                  reloadViewData={loadViewData}
                />
              </Col>
            </Row>
            <Row className="pt-5">
              {isDraft && (
                <Col
                  xs={{ order: 3, size: 12 }}
                  md={{ order: 1, size: 4 }}
                  className="ml-auto"
                >
                  <div className="pb-3 pb-md-0">
                    <Button
                      type="button"
                      color="danger"
                      text={t('common.actions.delete')}
                      fullwidth
                      onClick={() => setShowDeleteModal(true)}
                      disabled={
                        !isAuthorizedTo([PermissionName.DELETE_ACADEMIC_OFFER])
                      }
                    />
                  </div>
                </Col>
              )}
              <Col
                xs={{ order: 2, size: 12 }}
                md={{ order: 2, size: 4 }}
                className="ml-auto"
              >
                <div className="pb-3 pb-md-0">
                  <Button
                    type="button"
                    text={t(`common.actions.cancel`)}
                    outlined
                    onClick={() => history.push('/academic-offers')}
                    fullwidth
                  />
                </div>
              </Col>
              <Col xs={{ order: 1, size: 12 }} md={{ order: 3, size: 4 }}>
                <div className="pb-3 pb-md-0">
                  <Button
                    type="submit"
                    text={t(`${prefix}.next`)}
                    fullwidth
                    loading={buttonNextLoading}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </Card>
      <AcademicOfferDeleteModal
        show={showDeleteModal}
        onCloseModal={() => setShowDeleteModal(false)}
        onConfirm={deleteAcademicOffer}
      />
      <AcademicOfferValidateQuotaModal
        show={!!validateQuotaCode}
        onCloseModal={() => setValidateQuotaCode(null)}
        onConfirm={() => {
          if (
            modalConfirmOffer.plansNotUpdated &&
            modalConfirmOffer.plansNotUpdated.length !== 0
          ) {
            setValidateQuotaCode(null);
            modalConfirmOffer.open = true;
            setModalConfirmOffer({ ...modalConfirmOffer });
          } else {
            setValidateQuotaCode(null);
            confirmOffer();
          }
        }}
        code={validateQuotaCode}
      />
      <AcademicOfferConfirmModal
        open={modalConfirmOffer.open}
        onClose={() => setModalConfirmOffer({ open: false })}
        onConfirm={confirmOffer}
        plansNotUpdated={modalConfirmOffer.plansNotUpdated}
      />
    </Container>
  );
};

export default AcademicOffersEdit;
