import { Select, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import SectionTitle from '../../../../components/text/SectionTitle';
import { useValidations } from '../../../../hooks/useValidations';
import {
  HAS_INSURANCE,
  HEALTH_CARE_OTHER,
  healthCareProviderOptions,
  healthCareSystemOptions,
  healthInsuranceOptions,
  ISAPRE,
} from '../../../../utils/selectOptions';

/**
 * Sección de Sistema de salud
 */
const HealthCareSection = () => {
  const { msgValidations } = useValidations();
  const { control, watch } = useFormContext();
  const prefix = 'tuitionProcess.onSite.studentFile.healtCareSection';
  const { t } = useTranslation();
  const { validateRut, validatePhone } = useValidations();

  const selectedHealthCareSystem = watch('healthCareSystem');
  const selectedHealthInsurance = watch('healthInsurance');

  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="healthCareSystem"
            label={t(`${prefix}.healthCareSystem`)}
            options={healthCareSystemOptions}
            isSearchable={false}
            control={control}
          />
        </Col>
        {selectedHealthCareSystem?.value === ISAPRE && (
          <Col className="pb-3" xs={12} lg={4}>
            <Select
              name="healthCareProvider"
              label={t(`${prefix}.healthCareProvider`)}
              options={healthCareProviderOptions}
              isSearchable={false}
              control={control}
              rules={{ required: msgValidations.required }}
              shouldUnregister
            />
          </Col>
        )}
        {selectedHealthCareSystem?.value === HEALTH_CARE_OTHER && (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="otherHealthCare"
              label={t(`${prefix}.otherHealthCare`)}
              control={control}
              shouldUnregister
              rules={{ required: msgValidations.required }}
            />
          </Col>
        )}
        {selectedHealthCareSystem?.value && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="healthCareContributorID"
                label={t(`${prefix}.healthCareContributorID`)}
                control={control}
                formatter="rut"
                shouldUnregister
                rules={{
                  required: msgValidations.required,
                  validate: {
                    rut: validateRut,
                  },
                }}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="healthcarePreferredCenter"
                label="Centro de salud preferencia"
                control={control}
                shouldUnregister
                rules={{
                  required: msgValidations.required,
                }}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="healthcareHistory"
                label="Antecedentes de salud"
                control={control}
                shouldUnregister
                rules={{
                  required: msgValidations.required,
                }}
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="healthInsurance"
            label={t(`${prefix}.healthInsurance`)}
            options={healthInsuranceOptions}
            isSearchable={false}
            rules={{
              validate: {
                require: (value) => {
                  if (
                    !value &&
                    (selectedHealthCareSystem || selectedHealthInsurance)
                  ) {
                    return msgValidations.required;
                  }
                  return undefined;
                },
              },
            }}
            control={control}
          />
        </Col>
        {selectedHealthInsurance?.value === HAS_INSURANCE && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="healthInsuranceContributorID"
                label={t(`${prefix}.healthInsuranceContributorID`)}
                control={control}
                formatter="rut"
                shouldUnregister
                rules={{
                  required: msgValidations.required,
                  validate: {
                    rut: validateRut,
                  },
                }}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="phoneNumberHealthCare"
                label={t(`${prefix}.phoneNumber`)}
                tooltip={t(`${prefix}.tooltipPhoneNumber`)}
                control={control}
                formatter="phone"
                shouldUnregister
                rules={{
                  validate: validatePhone,
                }}
                maxLength={15}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default HealthCareSection;
