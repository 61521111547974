import { ColumnTable, DisplayInfo, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { GraduateRow } from '../../../types/graduationProcessMassive';

const dictPrefix = 'graduatesList.graduatesTable';

interface Props {
  data: GraduateRow[];
  isLoadingResults: boolean;
  totalGraduates: number;
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  hasFilters?: boolean;
}

export function GraduatesTable({
  data,
  isLoadingResults,
  totalPages,
  totalGraduates,
  currentPage,
  onChangePage,
  hasFilters,
}: Props) {
  const ITEMS_PER_PAGE = 10;

  const { t } = useTranslation();

  const columns: ColumnTable<GraduateRow>[] = [
    {
      headerText: t(`${dictPrefix}.fullName`),
      columnName: 'fullName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.identificator`),
      columnName: 'run',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.faculty`),
      columnName: 'schoolName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.studyPlan`),
      columnName: 'studyPlanName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.cohort`),
      columnName: 'cohortPeriodName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      headerText: t(`${dictPrefix}.graduationPeriod`),
      columnName: 'graduationPeriodName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
  ];

  return (
    <Table
      isLoadingResults={isLoadingResults}
      data={data}
      columns={columns}
      pagination={{
        currentPage: currentPage + 1,
        itemsPerPage: ITEMS_PER_PAGE,
        totalItems: totalGraduates,
        totalPages: totalPages,
        onChangePage: (page) => onChangePage(page - 1),
      }}
      noResultsText={
        <div className="d-flex justify-content-center align-items-center py-5">
          <DisplayInfo
            iconName="information"
            textBody={t(
              `${dictPrefix}.${
                hasFilters ? 'noResultsFilter' : 'noResults'
              }.description`,
            )}
            maxWidth="650px"
          />
        </div>
      }
    />
  );
}
