import CoursesBySemester from './CoursesBySemester';
import { SemesterCourse } from './types';
import { getColor as getColorCore } from './utils';

export type Semester = {
  id: number | string;
  semesterIndex: number;
  semesterCourses: SemesterCourse[];
};

export interface CoursesBySemestersProps {
  semesters: Semester[];
  texts: { semester: string; credits: string };
  getColor?: (state?: string) => string | undefined;
  onClick?: (semesterCourse: SemesterCourse) => void;
}

export default function CoursesBySemesters({
  semesters,
  texts,
  onClick,
  getColor = getColorCore,
}: CoursesBySemestersProps) {
  return (
    <div
      className="d-flex w-100"
      style={{
        overflowX: 'auto',
        flexDirection: 'row',
        gap: 16,
        marginLeft: -16,
        marginRight: -16,
        paddingBottom: 16,
        paddingRight: 16,
        paddingLeft: 16,
        //paddingBottom: 16,
      }}
    >
      {semesters.map((semester) => {
        return (
          <CoursesBySemester
            key={semester.id}
            {...semester}
            getColor={getColor}
            title={`${texts.semester} ${semester.semesterIndex}`}
            texts={texts}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
}
