import { Button, Icon, Table } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sideType, statusDocument } from '../../../api/requests/postulants';
import {
  deleteDocumentOfPostulantProcess,
  deleteIdentityCardOfPostulantProcess,
} from '../../../api/requests/tuitionProcesses';
import Loading from '../../../components/info/Loading';
import { ModalConfirmation } from '../../../components/modals/Confirmation';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { showToast } from '../../../utils/toast';
import {
  PostulantionDocumentationReviewDocumentRow,
  PostulantionDocumentationReviewDocumentRowDocument,
  PostulationDocumentationReview,
} from '../types';
import { FormDataPostulantProccess } from './FormDataPostulantProccess';
import { ModalAddDocument } from './ModalAddDocument';
import { TableOtherDocument } from './TableOtherDocument';
import { useColumnsListDocumentByStatus } from './useColumnsListDocumentByStatus';

export interface ModalAddDocumentProps {
  open: boolean;
  isOtherDocument: boolean;
  title?: string;
  document?: {
    type: string;
    side?: sideType;
  };
  onConfirm?: () => void;
}

export function StudyDocuments(props: {
  postulantDocuments: PostulationDocumentationReview;
  onRefresh: () => Promise<void>;
}): JSX.Element {
  const { postulantDocuments, onRefresh } = props;
  const { t } = useTranslation();
  const { loading, setLoading } = useLoadingState(false);

  const [modalConfirm, setModalConfirm] = useState({
    title: '',
    subtitle: '',
    onConfirm: console.log,
    open: false,
  });

  const [modalAddDocument, setModalAddDocument] =
    useState<ModalAddDocumentProps>({
      open: false,
      isOtherDocument: false,
    });

  const [rows, setRows] = useState(() => {
    return postulantDocuments.documents.map<PostulantionDocumentationReviewDocumentRow>(
      (doc) => {
        let document:
          | PostulantionDocumentationReviewDocumentRowDocument
          | undefined = undefined;
        if (doc.document) {
          document = {
            id: doc.document.id,
            isIdentityCard: false,
            extension: doc.document.extension,
            postulationDetailId: doc.document.postulationDetailId,
            status: doc.document.status,
            type: doc.document.type,
            updatedAt: doc.document.updatedAt,
            createdAt: doc.document.createdAt,
            inCloud: doc.document.inCloud,
            filename: doc.document.filename,
          };
        } else if (doc.identityCard) {
          document = {
            id: doc.identityCard.id,
            isIdentityCard: true,
            side: doc.identityCard.side,
            extension: doc.identityCard.extension,
            postulationDetailId: doc.identityCard.postulationDetailId,
            status: doc.identityCard.status,
            type: doc.identityCard.type,
            updatedAt: doc.identityCard.updatedAt,
            createdAt: doc.identityCard.createdAt,
            inCloud: doc.identityCard.inCloud,
            filename: doc.identityCard.filename,
          };
        }
        return {
          code: doc.code,
          name: doc.name,
          side: doc.identityCard?.side,
          documentType: doc.documentType,
          document,
        };
      },
    );
  });

  const onChangeStateRow = useCallback(
    (
      row: PostulantionDocumentationReviewDocumentRow,
      status: statusDocument,
    ) => {
      if (!row.document) {
        return;
      }
      row.document.status = status;
      setRows([...rows]);
    },
    [rows],
  );

  const fetchPostulantDocumentDelete = useCallback(
    async (row: PostulantionDocumentationReviewDocumentRow) => {
      if (!row.document) {
        return;
      }
      setLoading(true);
      let resp;
      if (row.document.isIdentityCard) {
        resp = await deleteIdentityCardOfPostulantProcess(row.document.id);
      } else {
        resp = await deleteDocumentOfPostulantProcess(row.document.id);
      }
      let { error } = resp;
      if (error) {
        showToast(true, t);
      } else {
        showToast(false, t);
        onRefresh();
      }
      setLoading(false);
    },
    [onRefresh, t, setLoading],
  );

  const documentDelete = useCallback(
    (row: PostulantionDocumentationReviewDocumentRow) => {
      if (!row.document) {
        return;
      }
      modalConfirm.open = true;
      modalConfirm.title =
        row.document.status !== 'aprobado'
          ? t('documents.studyDocuments.modalConfirm.notAproved.title')
          : t('documents.studyDocuments.modalConfirm.aproved.title');
      modalConfirm.subtitle =
        row.document.status !== 'aprobado'
          ? t('documents.studyDocuments.modalConfirm.notAproved.subtitle')
          : t('documents.studyDocuments.modalConfirm.aproved.subtitle');
      modalConfirm.onConfirm = () => fetchPostulantDocumentDelete(row);
      setModalConfirm({ ...modalConfirm });
    },
    [fetchPostulantDocumentDelete, modalConfirm, setModalConfirm, t],
  );

  const uploadDocument = useCallback(
    (row: PostulantionDocumentationReviewDocumentRow) => {
      let title = row.name;
      setModalAddDocument({
        open: true,
        isOtherDocument: false,
        title,
        document: {
          type: row.code,
          side: row.side,
        },
        onConfirm: () => onRefresh(),
      });
    },
    [setModalAddDocument, onRefresh],
  );

  const columns = useColumnsListDocumentByStatus({
    onChangeStateRow,
    documentDelete,
    uploadDocument,
  });

  if (loading) {
    return <Loading insideCard />;
  }

  return (
    <>
      <FormDataPostulantProccess postulant={postulantDocuments.postulant} />
      <Table<PostulantionDocumentationReviewDocumentRow>
        columns={columns}
        data={rows}
        isLoadingResults={false}
        noResultsText={
          <TableEmptyContent
            title={t('documents.tableEmptyContent.notResult.title')}
            subtitle={t('documents.tableEmptyContent.notResult.subtitle')}
          />
        }
      />
      <footer
        className="p-4 mt-4"
        style={{ border: '1px solid var(--primary)', borderRadius: '4px' }}
      >
        <div className="d-flex align-items-center" style={{ gap: '6px' }}>
          <h6 className="d-inline-block text-primary fs-20 fw-600 y mb-0">
            {t('documents.studyDocuments.otherDocuments')}
          </h6>
          <Icon name="information"></Icon>
        </div>
        <br />
        <Button
          type="button"
          text={t('documents.studyDocuments.addOtherDocuments')}
          icon="plus"
          size="md"
          onClick={() => {
            setModalAddDocument({
              open: true,
              isOtherDocument: true,
              onConfirm: onRefresh,
            });
          }}
        />
        <TableOtherDocument
          otherDocuments={postulantDocuments.otherDocuments}
          loading={false}
          reloadData={onRefresh}
        />
      </footer>
      <ModalConfirmation
        open={modalConfirm.open}
        title={modalConfirm.title}
        subtitle={modalConfirm.subtitle}
        onClose={() => {
          modalConfirm.open = false;
          setModalConfirm({ ...modalConfirm });
        }}
        onConfirm={modalConfirm.onConfirm}
      />
      <ModalAddDocument
        details_id={postulantDocuments.postulant.Details_id}
        open={modalAddDocument.open}
        onClose={() =>
          setModalAddDocument({ open: false, isOtherDocument: false })
        }
        isOtherDocument={modalAddDocument.isOtherDocument}
        title={modalAddDocument.title}
        document={modalAddDocument.document}
        onConfirm={modalAddDocument.onConfirm}
      />
    </>
  );
}
